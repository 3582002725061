import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import './infrastructure/localization/i18n'
import './infrastructure/styles/index.css'
import { ApolloProvider } from '@apollo/client'
import client from './infrastructure/apollo/client'
import { LicenseManager } from 'ag-grid-enterprise'
import { store } from './infrastructure/redux/store'
import { Provider } from 'react-redux'

const licenseKey =
  'CompanyName=Sertec Farma SL,LicensedGroup=Sfera,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-013689,ExpiryDate=19_February_2022_[v2]_MTY0NTIyODgwMDAwMA==025b9e5a65c89c8187117ad3078f1915'
const cleanedLicenseKey =
  licenseKey && licenseKey.replace(/[\u200B-\u200D\uFEFF]/g, '')
LicenseManager.setLicenseKey(cleanedLicenseKey)

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
)
