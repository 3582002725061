import { SET_TOKEN_SUCCESS, SET_USER_SUCCESS , SET_FILTERS_AGGRID, GET_FILTERS_AGGRID} from '../constants/user'

/**
 * @author ehernandez
 * @param {object} user object to be saved
 * @returns {object}
 */
export const actionSetLoginSuccess = (user) => ({
  type: 'USER_LOGIN_SUCCESS',
  user,
})

/**
 * @author ehernandez
 * @param {object} user object to be saved
 * @returns {object}
 */
export const actionSetUserSuccess = (user) => ({
  type: SET_USER_SUCCESS,
  user,
})

/**
 * @author ehernandez
 * @returns {object}
 */
export const actionUserLogout = () => ({
  type: 'USER_LOGOUT',
})

/**
 * @author ehernandez
 * @param {string} token token user
 * @param {string} user id user
 * @param {string} isAdmin if user backoffice or is user sfera
 * @returns {object}
 */
export const actionSetTokenSuccess = (token, user, isAdmin, organizacion) => ({
  type: SET_TOKEN_SUCCESS,
  token,
  user,
  isAdmin,
  organizacion,
})

export const actionSetFiltersAgGrid = (filters) => ({
  type: SET_FILTERS_AGGRID,
  filters
})

export const actionGetFiltersAgGrid = () => ({
  type: 'GET_FILTERS_AGGRID',
})
