import React from 'react'
import LogoImg from './assets/img/logo.png'
import LogoImgWhite from './assets/img/logo-white.svg'
import PropTypes from 'prop-types'
/**
 * @author ehernandez
 * @returns {React.Component}
 */
const Logo = ({ mode, width }) => {
  return (
    <div className="logo" style={{ maxWidth: width }}>
      {mode !== 'white' ? (
        <img src={LogoImg} alt="logo" />
      ) : (
        <img src={LogoImgWhite} alt="logo" />
      )}
    </div>
  )
}

Logo.propTypes = {
  mode: PropTypes.string,
}

export default Logo
