import { gql } from '@apollo/client'

export const LOGIN_ADMIN = gql`
  mutation ($identificador: String, $password: String) {
    loginAdmin(identificador: $identificador, password: $password) {
      token
      usuario {
        id
        perfiles {
          id
          perfil
        }
      }
    }
  }
`
export const FORGOT_PASSWORD = gql`
  mutation ($email: String!) {
    sendPasswordResetLink(email: $email)
  }
`

export const RESET_PASSWORD = gql`
  mutation ($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      token
      usuario {
        id
      }
    }
  }
`
export const PHARMACY_LOGIN = gql`
  mutation ($identificador: String!, $password: String!) {
    login(identificador: $identificador, password: $password) {
      token
      usuario {
        id
        nombre
        email
        organizacion {
          id
        }
      }
    }
  }
`
