
import AgCustomBooleanRenderer from "./AgCustomBooleanRenderer/AgCustomBooleanRenderer"
import CustomBooleanFilterComponent from "./CustomBooleanFilterComponent/CustomBooleanFilterComponent"
// import AgCellEditorDate from "./AgCellEditorDate/AgCellEditorDate"

export const frameworkComponents = {
    agCustomBooleanRenderer: AgCustomBooleanRenderer,
    customBooleanFloatingFilter: CustomBooleanFilterComponent,
    // agCellDate: AgCellEditorDate

}