import React from 'react'
import PropTypes from 'prop-types'

import Paragraphs from '../../../../../../components/commons/Paragraphs/Paragraphs'
import Button from '../../../../../../components/commons/Button/Button'
import Header from '../../../../components/Header'

const HeaderPlanogram = ({ openCreateModal, title = 'Destacados' }) => (
  <div className="HeaderPlanogram">
    <Header>
      <div className="tw-flex tw-w-full tw-justify-between tw-items-center tw-pl-4">
        {/* <Logo width={150} /> */}
        <Paragraphs size="xl" weight="bold">
          {title}
        </Paragraphs>
        <Button
          onClick={openCreateModal}
          label="Nuevo"
          size="small"
          uppercase
        />
      </div>
    </Header>
  </div>
)

HeaderPlanogram.propTypes = {
  openCreateModal: PropTypes.func,
}

export default HeaderPlanogram
