import React, { lazy, Suspense, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router'
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay'
import useUser from '../hooks/useUser'

// Lazy components
const Layout = lazy(() => import('../components/Layout/Layout'))

const PrivateRoutes = () => {
  const { token, user, getUserInfo } = useUser()
  const isAdmin = useSelector((state) => state?.userReducer?.isAdmin)
  const rolUser = isAdmin ? isAdmin : false

  useEffect(() => {
    if (!token) return
    getUserInfo()
  }, [token])

  if (!token || !user) {
    return <Navigate to="/login" />
  }

  /**
   * Control who and which index page will be shown
   * Dependending on the user role
   */
  if (window.location.pathname === '/') {
    return (
      <Navigate to={rolUser ? '/planograma/planograma-manager' : '/home'} />
    )
  }

  return (
    <Suspense fallback={<LoadingOverlay />}>
      <Layout>
        <Outlet context={{ isAdmin }} />
      </Layout>
    </Suspense>
  )
}

export default PrivateRoutes
