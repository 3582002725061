import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../styles'

const IcDelete = ({ size, color }) => {
  return (
    <Icon
      className="IcDelete material-icons tw-items-center tw-justify-center"
      size={size}
      color={color}
    >
      delete
    </Icon>
  )
}

IcDelete.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
}

export default IcDelete
