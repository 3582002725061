import React from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import LoadingOverlay from '../../../components/LoadingOverlay/LoadingOverlay'
import usePlanogram from '../../../modules/Planograma/hooks/usePlanograma'
import { DimensionsExtraProvider } from '../../context/ContextExtra.js/ContextExtra'

const PlanogramaRoutes = () => {
  const organizacion = useSelector((state) => state?.userReducer?.organizacion)
  const isAdmin = useSelector((state) => state?.userReducer?.isAdmin)
  const rolUser = isAdmin ? isAdmin : false

  const { getPlanogramasLineales } = usePlanogram(organizacion?.id, rolUser)

  if (!getPlanogramasLineales) {
    return <LoadingOverlay />
  }

  return (
    <DimensionsExtraProvider>
      <div className="estanterias tw-h-full tw-w-full">
        <Outlet />
      </div>
    </DimensionsExtraProvider>
  )
}

export default PlanogramaRoutes
