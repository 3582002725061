// Querys
import { GET_GROUPS_LIST, GET_GROUP } from '../../graphQL/querys'
import { CREATE_GROUP, UPDATE_GROUP, DELETE_GROUP } from '../../graphQL/admin'

import { handleCommonErrors } from '../userManagement/utils/utils'

import { useLazyQuery, useMutation } from '@apollo/client'

export const useGroups = () => {
  // QUERYS
  const [getGroupsList] = useLazyQuery(GET_GROUPS_LIST, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })

  const [getGroupQuery] = useLazyQuery(GET_GROUP, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })

  // MUTATIONS
  const [createGroupMutation] = useMutation(CREATE_GROUP, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })
  const [deleteGroupMutation] = useMutation(DELETE_GROUP)

  const [updateGroupMutation] = useMutation(UPDATE_GROUP, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })

  /**
   *  FUNCTIONS
   *  =========
   */

  /**
   * Get groups list
   *
   *  @param {Object} variables
   *    @param {String} variables.page
   *    @param {String} variables.first
   *    @param {String} variables.nombre
   *    @param {String} variables.orderBy
   *
   *  @returns
   *  @property {Array} data group list
   *  @property {Object} paginatorInfo informacion acerca la paginación
   */
  const getGroups = async (variables) => {
    const { data } = await getGroupsList({ variables })
    if (data) {
      const { groups } = data
      return groups
    }
    return []
  }

  /**
   * Get group
   * @params {id} id
   */
  const getGroup = async (id) => {
    const { data } = await getGroupQuery({ variables: { id } })
    if (data) {
      const { group } = data
      return group
    }
    return null
  }

  /**
   * Create a group
   * @param {GroupInput} variables
   * @param {Pharmacies} organizaciones
   * @returns {Object} new group
   *
   * @typedef {Object} GroupInput
   * @property {String} name
   * @property {[ID]} user_admins
   * @property {[ID]} organizations
   * @property {[ID]} lineals
   */
  const createGroup = async (variables) => {
    const input = {
      ...variables,
    }
    const { data, errors } = await createGroupMutation({
      variables: { input },
    })
    if (data) {
      return { success: data.createGroup }
    }

    return { success: false, errors: handleCommonErrors(errors) }
  }

  /**
   * Delete group
   * @param {ID} id
   */
  const deleteGroup = async (id) => {
    const { data, errors } = await deleteGroupMutation({
      variables: { id },
    })

    if (data) {
      return { success: data.deleteGroup }
    }
    return { success: false, errors: handleCommonErrors(errors) }
  }

  /**
   * Edit group
   * @param {GroupInput} variables
   * @param {Pharmacies} organizaciones
   * @returns {Object} new group
   *
   * @property {id} id
   * @property {GroupInput} input
   * @typedef {Object} GroupInput
   *  @property {[ID]} user_admins
   *  @property {[ID]} organizations
   *  @property {[ID]} lineals
   */

  const updateGroup = async (id, variables) => {
    const input = {
      ...variables,
    }
    const { data, errors } = await updateGroupMutation({
      variables: { input, id },
    })
    if (data) {
      return { success: data.updateGroup }
    }
    return { success: false, errors: handleCommonErrors(errors) }
  }

  return {
    getGroups,
    getGroup,
    createGroup,
    deleteGroup,
    updateGroup,
  }
}
