import {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useRef,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default forwardRef((props, ref) => {
  const refInput = useRef(null)
  const [filterText, setFilterText] = useState(null)
  const filters = useSelector((state) => state?.userReducer?.filters)
  const colDef = props.column.getColDef()

  useImperativeHandle(ref, () => {
    return {
      getModel() {
        return { value: text }
      },
      setModel(model) {
        setFilterText(model ? model.value : '')
      },
      isFilterActive() {
        return filterText !== null
      },
    }
  })

  const onInputBoxChanged = (event) => {
    setFilterText(event.target.value)
  }

  useEffect(() => {
    if (filterText === null) return
    if (filterText !== '') {
      let filterModel = {
        ...filters,
        ...JSON.parse(localStorage.getItem('filters')),
        [colDef.field]: {
          type: 'equals',
          filter: filterText,
          filterType: 'text',
        },
      }
      localStorage.setItem('filters', JSON.stringify(filterModel))
    } else {
      let filterModel = JSON.parse(localStorage.getItem('filters'))
      delete filterModel[colDef.field]
      localStorage.setItem('filters', JSON.stringify(filterModel))
    }
    setTimeout(() => {
      props.api.onFilterChanged()
    }, 500)
  }, [filterText])

  useEffect(() => {
    let filterModel = JSON.parse(localStorage.getItem('filters'))
    if (filterModel && filterModel[colDef.field]) {
      setFilterText(filterModel[colDef.field].filter)
    }
  }, [])

  return (
    <div className=" tw-h-full tw-flex tw-items-center tw-w-full">
      <select
        ref={refInput}
        onChange={onInputBoxChanged}
        style={{
          height: 24,
          borderColor: '#babfc7',
        }}
        className="tw-w-full ag-input-field-input ag-text-field-input tw-border tw-border-solid tw-rounded"
      >
        <option></option>
        {colDef?.filterParams.values.map((value) => (
          <option value={value} key={value} selected={filterText === value}>
            {value === 'true' ? 'Si' : value === 'false' ? 'No' : value}
          </option>
        ))}
      </select>
    </div>
  )
})
