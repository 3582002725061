import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { AgGridReact } from 'ag-grid-react'
import { useEffect, useMemo, useState } from 'react'
import { columnTypes } from './columnTypes'
import { frameworkComponents } from './frameworkComponent'
import { useTranslation } from 'react-i18next'
import './index.css'

export const columnLast = {
  flex: 1,
  field: 'last',
  colId: 'last',
  headerName: '',
  suppressMovable: true,
  suppressMenu: true,
  enableRowGroup: false,
  suppressColumnsToolPanel: true,
  sortable: false,
  menuTabs: false,
  floatingFilter: false,
  cellRendererParams: {
    supressCount: true,
  },
  columnsMenuParams: {
    suppressColumnSelectAll: true,
    suppressColumnFilter: true,
    suppressSyncLayoutWithGrid: true,
    suppressColumnExpandAll: true,
  },
}

const Aggrid = ({
  onGridReady,
  cacheBlockSize,
  cacheOverflowSize,
  infiniteInitialRowCount,
  type,
  onFirstDataRendered,
  pagination,
  customFrameworkComponents,
  rowSelection,
  rowModelType,
  columns,
  reference,
  paginationAutoPageSize,
  onRowSelected,
  id,
  getContextMenuItems,
  onCellDoubleClicked,
  gridParams,
  apiToCall,
  onBodyScroll,
  setDataLoaded,
  onCellClicked,
  onPaginationChanged,
  onRowDoubleClicked,
  onFilterChanged,
  serverSideInfiniteScroll,
  maxBlocksInCache,
  maxConcurrentDatasourceRequests,
  perPage,
  isRowSelectable,
  suppressRowClickSelection,
  suppressAggFuncInHeader,
  serverSideFilterAllLevels,
  onCellMouseOver,
  onCellMouseOut,
}) => {
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])
  const perPageNum = perPage || 14

  const columnDefs = useMemo(() => {
    const cols = [...columns, columnLast]
    return cols
  }, [])

  const renderFrameworkComponent = useMemo(() => {
    if (customFrameworkComponents) {
      return {
        ...frameworkComponents,
        ...customFrameworkComponents,
      }
    }
    return frameworkComponents
  }, [])

  const handlePaginationChanged = (params) => {
    if (onPaginationChanged) {
      onPaginationChanged(params)
    } else {
      let currentPage = params.api.paginationGetCurrentPage()
      setPage(currentPage + 1)
    }
  }

  const handleOnGridReady = async (params) => {
    if (onGridReady && type !== 'scroll') {
      const data = await onGridReady(params)
      if (data) {
        setDataLoaded && setDataLoaded(data)
        return data
      } else {
        params.api.showNoRowsOverlay()
      }
      params.api.hideOverlay()
      return []
    }
    params.api.hideOverlay()
    return params
  }

  // pagination ssr
  useEffect(() => {
    if (gridParams && pagination) {
      const dataSource = {
        getRows: (params) => {
          gridParams.api.showLoadingOverlay()
          apiToCall(perPageNum, page)
            .then((res) => {
              if (!res.success) {
                gridParams.api.showNoRowsOverlay()
              } else {
                gridParams.api.hideOverlay()
              }
              params.successCallback(
                res.data.data || res.response.data,
                res.data.paginatorInfo.total || 20
              )
            })
            .catch((err) => {
              console.error(err)
              gridParams.api.showNoRowsOverlay()
              params.failCallback()
            })
        },
      }
      gridParams.api?.setServerSideDatasource(dataSource)
    }
  }, [gridParams])

  return (
    <div
      style={gridStyle}
      className="ag-theme-alpine tw-relative tw-h-full tw-w-full tw-pb-4"
    >
      <AgGridReact
        id={id}
        onBodyScroll={onBodyScroll}
        infiniteInitialRowCount={infiniteInitialRowCount}
        maxBlocksInCache={maxBlocksInCache}
        maxConcurrentDatasourceRequests={maxConcurrentDatasourceRequests}
        serverSideInfiniteScroll={serverSideInfiniteScroll}
        onFirstDataRendered={onFirstDataRendered}
        onCellClicked={onCellClicked}
        onCellMouseOver={onCellMouseOver}
        onCellMouseOut={onCellMouseOut}
        onPaginationChanged={handlePaginationChanged}
        paginationPageSize={perPageNum}
        cacheBlockSize={cacheBlockSize ? cacheBlockSize : 50}
        cacheOverflowSize={cacheOverflowSize}
        ref={reference}
        onRowDoubleClicked={onRowDoubleClicked}
        onCellDoubleClicked={onCellDoubleClicked}
        columnTypes={columnTypes}
        onFilterChanged={onFilterChanged}
        floatingFiltersHeight={36}
        rowHeight={32}
        onRowSelected={onRowSelected}
        headerHeight={32}
        overlayNoRowsTemplate={`<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">${t(
          'messages.No se encontraron resultados'
        )}</span>`}
        getContextMenuItems={getContextMenuItems}
        onGridReady={type === 'scroll' ? onGridReady : handleOnGridReady}
        paginationAutoPageSize={paginationAutoPageSize}
        pagination={pagination}
        hasFilter={true}
        rowSelection={rowSelection}
        frameworkComponents={renderFrameworkComponent}
        rowModelType={rowModelType}
        columnDefs={columnDefs}
        serverSideStoreType={'partial'}
        animateRows={true}
        isRowSelectable={isRowSelectable}
        suppressRowClickSelection={suppressRowClickSelection}
        suppressAggFuncInHeader={suppressAggFuncInHeader}
        serverSideFilterAllLevels={serverSideFilterAllLevels}
      />
    </div>
  )
}

export default Aggrid
