import React from 'react'
import PropTypes from 'prop-types'
import { columns } from '../../aggrid/columns'
import Aggrid from '../../../../infrastructure/components/Aggrid/Aggrid'

const GroupManagement = (props) => {
  const {
    userRol,
    setGridApi,
    handleEditOrCreateGroup,
    setOpenConfirmDelete,
    onRowDoubleClicked,
    getGroupsList,
    totalRows,
  } = props

  const onGridReady = async (params) => {
    setGridApi(params.api)
    const updateData = () => {
      const datasource = {
        async getRows(rowParams) {
          const { filterModel } = rowParams.request
          let filteredName = null
          if (filterModel['name']) {
            filteredName = `%${filterModel['name'].filter}%`
          }
          let orderBy = null
          if (rowParams.request?.sortModel?.length > 0) {
            orderBy = rowParams.request.sortModel.map((sort) => {
              return {
                column: sort.colId,
                order: sort.sort.toUpperCase(),
              }
            })
          }
          let page = params.api.paginationGetCurrentPage() + 1
          await getGroupsList(rowParams, page, orderBy, filteredName)
        },
      }
      params.api.setServerSideDatasource(datasource)
    }

    updateData(params.api)
  }

  const actionContextMenu = (action, row) => {
    if (!row) return null

    const { node } = row
    if (node !== null) {
      const { data } = node

      if (!data?.id) {
        return null
      }

      switch (action) {
        case 'DELETE':
          return setOpenConfirmDelete(data)

        case 'EDIT':
          return handleEditOrCreateGroup(data)

        default: {
          return null
        }
      }
    }
  }

  const getContextMenu = (row) => {
    const items = []
    if (userRol === 'ADMIN') {
      items.push({
        id: 2,
        name: 'Editar',
        action: () => actionContextMenu('EDIT', row),
      })
    }
    if (userRol !== 'ADMIN' && row?.node?.data?.organizacion?.id) {
      items.push({
        id: 2,
        name: 'Editar',
        action: () => actionContextMenu('EDIT', row),
      })
    }
    if (userRol === 'ADMIN') {
      items.push({
        id: 1,
        name: 'Eliminar',
        action: () => actionContextMenu('DELETE', row),
        cssClasses: ['tw-text-alert'],
      })
    }
    if (userRol !== 'ADMIN' && row?.node?.data?.organizacion?.id) {
      items.push({
        id: 1,
        name: 'Eliminar',
        action: () => actionContextMenu('DELETE', row),
        cssClasses: ['tw-text-alert'],
      })
    }

    return items
  }

  return (
    <div id="aggrid-groups-management" className="tw-relative tw-h-full">
      <Aggrid
        id="table-groups-management"
        columns={columns.groups}
        onGridReady={onGridReady}
        rowModelType={'serverSide'}
        type="scroll"
        onRowDoubleClicked={onRowDoubleClicked}
        getContextMenuItems={(row) => getContextMenu(row)}
        cacheBlockSize={totalRows}
        paginationAutoPageSize={false}
        serverSideInfiniteScroll={true}
      />
    </div>
  )
}

GroupManagement.propTypes = {
  userRol: PropTypes.string,
  setGridApi: PropTypes.func,
  groupList: PropTypes.array,
  handleEditOrCreateGroup: PropTypes.func,
  setOpenConfirmDelete: PropTypes.func,
}

GroupManagement.defaultProps = {
  userRol: '',
  setGridApi: () => {},
  groupList: [],
  handleEditOrCreateGroup: () => {},
  setOpenConfirmDelete: () => {},
}

export default GroupManagement
