import styled from 'styled-components'

export const ToggleSwitch = styled.div`
  position: relative;
  width: 32px;
  top: 0px;
`

export const InputSwitch = styled.input`
  display: none;
  &:checked + .label .inner {
    margin-left: 0;
  }
  &:checked + .label .switch {
    right: 0px;
  }
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const LabelSwitch = styled.label`
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
`

export const ContainerSwitch = styled.div`
  text-align: center;

  & .inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }
  & .inner:before,
  & .inner:after {
    float: left;
    width: 50%;
    height: 16px;
    padding: 0;
    line-height: 36px;
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
  }
  & .inner:before {
    content: '';
    padding-left: 10px;
    background-color: #3fcbd9;
    color: #fff;
  }
  & .inner:after {
    content: '';
    padding-right: 10px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
  }
  & .switch {
    display: block;
    width: 12px;
    margin: 2px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 16px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
`
