import React from 'react'
import Paragraphs from '../../../../components/commons/Paragraphs/Paragraphs'
import Button from '../../../../components/commons/Button/Button'
import { useTranslation, Trans } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import greenTick from '../../../../infrastructure/assets/svg/circle_confirm.svg'

const ConfirmationEmail = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const goToLogin = () => {
    navigate('/')
  }

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-flex-1 tw-h-full">
      <div className="tw-p-6">
        <div className="tw-p-4 tw-text-center">
          <Paragraphs weight="bold" size="2xl">
            {t('login.Revisa tu correo electronico')}
          </Paragraphs>
        </div>
        <div className="tw-w-96 tw-text-center">
          <Paragraphs>
            <Trans
              t={t}
              i18nKey={
                'Hemos enviado un correo electrónico a tu email para que cambies tu contraseña'
              }
            >
              Hemos enviado un correo electrónico a tu{' '}
              <b>{{ email: location.state.email }}</b> para que cambies tu
              contraseña
            </Trans>
          </Paragraphs>
        </div>
        <div className="tw-justify-center tw-w-96 tw-flex tw-py-3 tw-mt-3">
          <img src={greenTick} />
        </div>
      </div>
      <Button label={t('actions.Acceder')} uppercase onClick={goToLogin} />
    </div>
  )
}

export default ConfirmationEmail
