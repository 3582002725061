import Paragraphs from '../commons/Paragraphs/Paragraphs'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const PharmacyTitle = ({ user }) => {
  const { t } = useTranslation()
  return (
    <div className="tw-block tw-w-full tw-h-16 tw-mb-4">
      <Paragraphs className="tw-text-3xl">
        {`${t('titles.Farmacia')}: ${user?.organizacion?.nombre}`}
      </Paragraphs>
    </div>
  )
}

PharmacyTitle.propTypes = {
  user: PropTypes.object.isRequired,
}

export default PharmacyTitle
