import styled from 'styled-components'
export const FilterArrowDown = styled.span`
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-bottom: 3px;
  cursor: pointer;
`
export const FilterArrowUp = styled.span`
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  margin-bottom: 1px;
  cursor: pointer;
`

export const InputContainer = styled.div`
  min-height: 35px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e6e6e6;
`
