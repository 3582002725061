import React from 'react'
import PropTypes from 'prop-types'
import { Container } from './styles'
import Paragraphs from '../../../Paragraphs/Paragraphs'
import IcMinimize from '../../../Icons/IcMinimize'
import IcClose from '../../../Icons/IcClose'
import { useTranslation } from 'react-i18next'

const Header = ({
  title,
  customTitle,
  onMaximizeModal,
  minimized,
  onCloseModal,
  textRight,
}) => {
  const { t } = useTranslation()
  return (
    <Container className="Modal-header tw-flex tw-justify-between tw-items-center tw-px-4 tw-bg-primary tw-cursor-pointer">
      <div className="tw-flex tw-gap-1 tw-flex-1">
        <Paragraphs className="tw-text-white" uppercase size="xs" weight="bold">
          {t(`titles.${title}`)}
        </Paragraphs>
        {customTitle && (
          <Paragraphs
            className="tw-text-white"
            uppercase
            size="xs"
            weight="bold"
          >
            {customTitle}
          </Paragraphs>
        )}
      </div>
      <div className="tw-flex tw-items-center">
        {textRight && (
          <Paragraphs className="tw-text-white" size="xs">
            {textRight}
          </Paragraphs>
        )}
        {onMaximizeModal && (
          <div onClick={onMaximizeModal}>
            <IcMinimize color="white" size={20} minimized={minimized} />
          </div>
        )}
        {onCloseModal && (
          <div className="tw-pl-2" onClick={onCloseModal}>
            <IcClose color="white" size={12} />
          </div>
        )}
      </div>
    </Container>
  )
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  customTitle: PropTypes.string,
  onMaximizeModal: PropTypes.func,
  onCloseModal: PropTypes.func,
  minimized: PropTypes.bool,
  translate: PropTypes.bool,
  textRight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

Header.defaultProps = {
  onMaximizeModal: null,
  minimized: false,
  onCloseModal: null,
  translate: true,
}

export default Header
