/**
 * Handle common response errors
 * @param {Object} errors
 *
 * @returns {Array} react hook form errors
 */
export const handleCommonErrors = (errors) => {
  let decodeErrors = []
  if (errors.length > 0) {
    const error = errors[0]

    if (error.extensions) {
      let extensions = error.extensions
      let validations =
        'validations' in extensions
          ? extensions.validations
          : 'validation' in extensions
          ? extensions.validation
          : null
      let reason = error.extensions.reason
      validations &&
        Object.keys(validations).forEach((key) => {
          //replace any word before first dot if it is "input"
          let field = key.replace(/^input\./, '')
          let message = error.extensions.validation[key]
          if (Array.isArray(message)) {
            message = message[0].replace(/.input\./, ' ')
          }
          decodeErrors.push({ [field]: message })
        })

      if (reason) {
        decodeErrors.push({ reason })
      }
    }
  }
  return decodeErrors
}
