import Cookies from 'universal-cookie'
import { actionUserLogout } from '../redux/actions/user'
import store from './getStore'

/**
 * logout user
 * @author ehernandez
 */
export const userLogout = () => {
  store.dispatch(actionUserLogout())
}
