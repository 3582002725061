/**
 * @description function to build reponse object
 * @ehernandez
 * @returns {object}
 */
export const useBaseResponse = () => {
  /**
   * when the response fails
   * @ehernandez
   * @param {object} error - error object
   * @returns {object}
   */
  const responseFail = (error) => {
    return {
      success: false,
      error,
    }
  }
  /**
   * when the response is successful
   * @ehernandez
   * @param {object} data - data to be returned
   * @returns {object}
   */
  const responseSuccess = (data) => ({
    success: true,
    data,
  })

  return {
    responseFail,
    responseSuccess,
  }
}
