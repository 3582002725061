import Paragraphs from '../../../../../../components/commons/Paragraphs/Paragraphs'
import Logo from '../../../../components/Logo'
import { ProductTable } from '../ProductTable/ProductTable'

const ProductGrid = (props) => {
  const {
    products,
    t,
    onDoubleClickProduct,
    onProductFilterChange,
    loadingMore,
    search,
    onRightClickProduct,
    rolUser,
  } = props

  return (
    <div className="tw-block tw-w-full tw-relative tw-w-full">
      {/* TABLE HEADER */}
      <div className="tw-sticky tw-top-0 tw-bg-white tw-mt-8 tw-border-b-2 tw-border-l-2 tw-border-r-2 tw-z-10">
        <div className="tw-flex tw-items-center">
          <div className="tw-text-left tw-mx-2 tw-w-24">
            {t('labels.Imagen')}
          </div>
          <div className="tw-text-left tw-mx-2 tw-w-32">
            {t('labels.Código')}
          </div>
          <div className="tw-text-left tw-mx-2 tw-w-80">
            {t('labels.Nombre del producto')}
          </div>
          <div className="tw-text-left tw-mx-2 tw-w-80">
            {t('labels.Laboratorio')}
          </div>
          <div className="tw-text-left tw-mx-2 tw-w-80">
            {t('labels.Familia')}
          </div>
          <div className="tw-text-left tw-mx-2 tw-w-80">
            {t('labels.subfamilia')}
          </div>
        </div>
        {/* INPUTS */}
        <div className="tw-flex tw-justify-around tw-bg-gray-200 tw-py-2">
          <div className="tw-text-left tw-mr-2 tw-w-28">
            <input type="text" className="tw-invisible tw-w-full" />
          </div>
          <div className="tw-text-left tw-mr-2 tw-w-28 tw-px-1 tw-relative">
            <input
              type="search"
              className="tw-w-full tw-pt-1 tw-pb-1 tw-pl-2 tw-pr-1"
              name="giff"
              onChange={(e) => onProductFilterChange(e)}
              value={typeof search === 'object' ? search.giff : ''}
            />
          </div>
          <div className="tw-text-left tw-mx-2 tw-w-80 tw-px-1">
            <input
              type="search"
              className="tw-w-full tw-pt-1 tw-pb-1 tw-pl-2 tw-pr-1"
              name="nombre"
              onChange={(e) => onProductFilterChange(e)}
              value={typeof search === 'object' ? search.nombre : ''}
            />
          </div>
          <div className="tw-text-left tw-mx-2 tw-w-80 tw-px-1">
            <input
              type="search"
              className="tw-w-full tw-pt-1 tw-pb-1 tw-pl-2 tw-pr-1"
              name="laboratorio"
              onChange={(e) => onProductFilterChange(e)}
              value={typeof search === 'object' ? search.laboratorio : ''}
            />
          </div>
          <div className="tw-text-left tw-mx-2 tw-w-80 tw-px-1">
            {/* PD: CATEGORIA ES FAMILIA */}
            <input
              type="search"
              className="tw-w-full tw-pt-1 tw-pb-1 tw-pl-2 tw-pr-1"
              name="categoria"
              onChange={(e) => onProductFilterChange(e)}
              value={typeof search === 'object' ? search.categoria : ''}
            />
          </div>
          <div className="tw-text-left tw-mx-2 tw-w-80 tw-px-1">
            {/* PD: SUBFAMILIA ES SUBCATEGORIA */}
            <input
              type="search"
              className="tw-w-full tw-pt-1 tw-pb-1 tw-pl-2 tw-pr-1"
              name="subcategoria"
              onChange={(e) => onProductFilterChange(e)}
              value={typeof search === 'object' ? search.subcategoria : ''}
            />
          </div>
        </div>
      </div>
      {products && products.length > 0 ? (
        products.map((product, i) => (
          <ProductTable
            key={i + '_' + product.nombre}
            {...product}
            onDoubleClickProduct={onDoubleClickProduct}
            onRightClickProduct={onRightClickProduct}
            rolUser={rolUser}
          />
        ))
      ) : (
        <div className="tw-flex tw-justify-center tw-items-top tw-h-full tw-mt-8">
          <div className="tw-text-center tw-text-2xl tw-text-gray-600">
            {t('labels.No se encontraron productos')}
          </div>
        </div>
      )}

      {Boolean(loadingMore) && (
        <div className="tw-flex tw-justify-center tw-items-top tw-h-42 tw-mt-8">
          <div className="tw-text-center tw-text-2xl tw-text-gray-600">
            <Logo width={100} imgClass="tw-m-auto" />
            <Paragraphs className="tw-text-center">
              {t('labels.Cargando')}...
            </Paragraphs>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProductGrid
