import React, { useState, useRef, useEffect } from 'react'
import ReactCrop from 'react-image-crop'

import Button from '../../../../../components/commons/Button/Button'
import { canvasPreview, centerAspectCrop } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'
import 'react-image-crop/dist/ReactCrop.css'
import { TopModalCropImage } from './style'
import { resizeFile } from '../../../hooks/util'
import Paragraphs from '../../../../../components/commons/Paragraphs/Paragraphs'
import { useTranslation } from 'react-i18next'

const ModalCropImages = ({ onToggle, uploadedImage, handleUploadImage }) => {
  const { t } = useTranslation()
  const previewCanvasRef = useRef(null)
  const imgRef = useRef(null)
  const [imgSrc, setImgSrc] = useState('')
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  let scale = 1
  let rotate = 0
  let aspect = 16 / 9

  useEffect(() => {
    if (uploadedImage) {
      onSelectFile(uploadedImage)
    }
  }, [uploadedImage])

  function onSelectFile(file) {
    if (file) {
      setCrop(undefined)
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        let img = new Image()
        img.src = reader.result
        img.onload = () => {
          if (img.width > 800) {
            resizeFile(file, 800, 800).then((res) => {
              let reader = new FileReader()
              reader.readAsDataURL(res)
              reader.onloadend = () => {
                setImgSrc(reader.result)
              }
            })
          } else {
            setImgSrc(reader.result)
          }
        }
      })
      reader.readAsDataURL(file)
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        )
      }
    },
    100,
    [completedCrop, scale, rotate]
  )

  async function onSaveCroppedImage() {
    let canvasImage = previewCanvasRef.current
    if (canvasImage) {
      let ctx = canvasImage.getContext('2d')
      let dataURL = canvasImage.toDataURL('image/jpeg')
      let blob = await fetch(dataURL).then((r) => r.blob())
      let file = new File([blob], 'image.jpg', { type: 'image/jpeg' })
      let event = {
        target: {
          files: [file],
        },
      }
      handleUploadImage(event)
    }
    onToggle()
  }

  return (
    <TopModalCropImage>
      <div className="tw-block tw-absolute tw-top-10 tw-left-10">
        <Paragraphs weight="bold" className="tw-mb-2" size={'lg'}>
          {t('titles.Recorta la imagen')}
        </Paragraphs>
      </div>
      {Boolean(imgSrc) && (
        <div
          className="tw-w-3/6 tw-flex tw-flex-col tw-items-center tw-mt-10"
          style={{
            maxHeight: 'calc(100vh - 200px)',
          }}
        >
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={0}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              onLoad={onImageLoad}
              style={{
                width: '500px',
              }}
            />
          </ReactCrop>
        </div>
      )}
      <div className="tw-w-3/6">
        {Boolean(completedCrop) && (
          <canvas
            ref={previewCanvasRef}
            style={{
              border: '1px solid black',
              objectFit: 'contain',
              width: completedCrop.width,
              height: completedCrop.height,
            }}
          />
        )}
      </div>
      {/* button save image */}
      <div className="tw-absolute tw-bottom-10 tw-right-10 tw-flex">
        <div className="tw-flex-1 tw-mr-8">
          <Button onClick={onToggle} label="Cancelar" size="medium" uppercase />
        </div>
        <div className="tw-flex-1">
          <Button
            onClick={onSaveCroppedImage}
            label="Guardar"
            size="medium"
            uppercase
          />
        </div>
      </div>
    </TopModalCropImage>
  )
}

export default ModalCropImages
