import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../../../../components/commons/Paragraphs/Paragraphs'
import Icons from '../../../../components/commons/Icons'
import useListenerClose from '../../../../infrastructure/hooks/useListenerClose/useListenerClose'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

const ContextMenuProduct = ({
  onDelete,
  onEdit,
  left,
  top,
  setContextMenu,
  onRotate,
  deleteDisabled,
}) => {
  const [ref] = useListenerClose(() => setContextMenu(null), true)
  const { t } = useTranslation()

  return (
    <div
      ref={ref}
      className="context-menu tw-bg-white tw-shadow-lg tw-bg-white tw-fixed"
      style={{
        left,
        top,
        zIndex: 99,
      }}
    >
      {onEdit ? (
        <div
          aria-hidden
          onClick={() => {
            setContextMenu(null)
            onEdit()
          }}
          className="tw-flex tw-items-center tw-p-2 tw-cursor-pointer hover:tw-bg-gray-100"
        >
          <Icons.IcEdit size={15} />
          <Paragraphs className="tw-mt-1">{t('labels.Editar')}</Paragraphs>
        </div>
      ) : null}
      {onRotate ? (
        <Fragment>
          <div
            aria-hidden
            onClick={() => {
              setContextMenu(null)
              onRotate(-90)
            }}
            className="tw-flex tw-items-center tw-p-2 tw-cursor-pointer hover:tw-bg-gray-100"
          >
            <Icons.IcRotateLeft size={15} color="#000" />
            <Paragraphs className="tw-mt-1">
              {t('labels.Rotar a la izquierda')}
            </Paragraphs>
          </div>
          <div
            aria-hidden
            onClick={() => {
              setContextMenu(null)
              onRotate(90)
            }}
            className="tw-flex tw-items-center tw-p-2 tw-cursor-pointer hover:tw-bg-gray-100"
          >
            <Icons.IcRotateRight size={15} />
            <Paragraphs className="tw-mt-1">
              {t('labels.Rotar a la derecha')}
            </Paragraphs>
          </div>
        </Fragment>
      ) : null}

      {!deleteDisabled ? (
        <div
          aria-hidden
          onClick={onDelete}
          className="tw-text-alert tw-flex tw-items-center tw-p-2 tw-cursor-pointer tw-border-t tw-border-gray-200 hover:tw-bg-gray-100"
        >
          <Icons.IcDelete size={15} />
          <Paragraphs weight="bold" className="tw-mt-1">
            {t('labels.Eliminar')}
          </Paragraphs>
        </div>
      ) : (
        <div
          aria-hidden
          className="tw-flex tw-items-center tw-p-2 tw-cursor-default tw-border-t tw-border-gray-200 tw-text-gray-400 hover:tw-bg-gray-100"
          style={{ cursor: 'not-allowed' }}
        >
          <Icons.IcDelete size={15} />
          <Paragraphs weight="bold" className="tw-mt-1">
            {t('labels.Eliminar')}
          </Paragraphs>
        </div>
      )}
    </div>
  )
}

ContextMenuProduct.propTypes = {
  onDelete: PropTypes.func,
  onRotate: PropTypes.func,
}

export default ContextMenuProduct
