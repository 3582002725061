/* eslint-disable require-jsdoc */
import React from 'react'

const ModalNav = ({ title }) => {
  return (
    <>
      <div className="TopNav tw-flex tw-flex-row tw-flex-1 tw-border-b tw-border-sky-500 tw-border-l-0 tw-border-r-0 tw-w-full">
        <section className="Features tw-flex-row tw-w-full tw-pt-4 tw-p-4 tw-text-lg">
          {title}
        </section>
      </div>
    </>
  )
}

export default ModalNav
