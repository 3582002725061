import { useLazyQuery, useMutation } from '@apollo/client'

// Querys
import { GET_USERS_LIST } from '../../graphQL/querys'
import {
  CREATE_USER_ADMIN,
  DELETE_USER_ADMIN,
  EDIT_USER_ADMIN,
} from '../../graphQL/admin'
import { handleCommonErrors } from './utils/utils'

export const useUserManagement = (pharmacyId, userRol) => {
  /**
   *  QUERYS AND MUTATIONS
   *  ====================
   */

  // QUERYS
  const [getUsersList] = useLazyQuery(GET_USERS_LIST, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })

  // MUTATIONS
  const [createUserAdminMutation] = useMutation(CREATE_USER_ADMIN, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })

  const [deteleUserAdminMutation] = useMutation(DELETE_USER_ADMIN)

  const [handleEditUser] = useMutation(EDIT_USER_ADMIN, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })

  /**
   *  FUNCTIONS
   *  =========
   */

  /**
   * Get users list
   *
   *  @param {Object} variables
   *    @param {String} variables.page
   *    @param {String} variables.first
   *  @return {listUsersAdmin} list of users
   *
   *  @typedef {Object} listUsersAdmin
   *  @property {Array} data users list
   *  @property {Object} paginatorInfo informacion acerca de la pagina en la paginacion
   */
  const getUsersListing = async (variables) => {
    const { data } = await getUsersList({ variables })
    if (data) {
      const { listUsersAdmin } = data
      return listUsersAdmin
    }
    return []
  }

  /**
   * Create an Admin user
   * @param {UsuarioAdminInput} variables
   * @param {Pharmacies} organizaciones
   * @returns {Object} new user
   *
   * @typedef {Object} UsuarioAdminInput
   * @property {String} nombre
   * @property {String} apellidos
   * @property {String} email
   * @property {String} nickname
   * @property {number} perfil
   */
  const createUserAdmin = async (variables) => {
    const input = {
      ...variables,
    }
    const { data, errors } = await createUserAdminMutation({
      variables: { input },
    })
    if (data) {
      return { success: data.createUserAdmin }
    }

    return { success: false, errors: handleCommonErrors(errors) }
  }

  /**
   * Delete user
   * @param {ID} id
   */
  const deleteUserAdmin = async (id) => {
    const { data, errors } = await deteleUserAdminMutation({
      variables: { id },
    })

    if (data) {
      return { success: data.deleteUserAdmin }
    }
    return { success: false, errors: handleCommonErrors(errors) }
  }

  /**
   * Edit user
   * @params {UsuarioAdminInput} variables
   * @returns {Object} edited users
   *
   * @typedef {Object} UsuarioAdminInput
   * @property {String} nombre
   * @property {String} apellidos
   * @property {String} email
   * @property {String} nickname
   * @property {Perfiles} Perfiles
   *
   * @typedef {Object} Perfiles
   * @property {String} id
   * @property {String} perfil
   *
   */

  const editUserAdmin = async (id, variables, farmacias) => {
    const input = {
      ...variables,
    }
    const { data, errors } = await handleEditUser({ variables: { input, id } })
    if (data) {
      return { success: data.updateUserAdmin }
    }
    return { success: false, errors: handleCommonErrors(errors) }
  }

  return { getUsersListing, createUserAdmin, deleteUserAdmin, editUserAdmin }
}
