module.exports = {
  gray: { 100: '#E8F4F5', 200: '#8C8C8C' },
  coins: {
    euro: '#E2E2E2',
    cent: '#F5D016',
    cent2: '#C66E1D',
  },
  grey: {
    100: '#EEECEC',
    300: '#F8F8FB',
    400: '#6B6B6B',
    600: '#2C2C2C',
    700: '#E8F4F5',
    800: '#BCDFE1',
    900: '#A2A2A2',
  },
  green: {
    200: '#f6e8f1',
    300: '#d38ebc',
    600: '#22949B',
    800: '#961b6d',
    900: '#7D185C',
  },
  red: {
    200: '#FECACA',
    300: '#FCA5A5',
    400: '#d8004d',
    600: '#DC2626',
    800: '#7F1D1D',
    900: '#7F1D1D',
  },
  blue: {
    200: '#BFDBFE',
    300: '#93C5FD',
    600: '#2563EB',
    800: '#1E3A8A',
    900: '#1E3A8A',
  },
  purple: {
    900: '#6A51C7',
    800: '#6A51C7',
  },
  alert: '#D8004D',
  primary: '#7D185C',
  secondary: '#A71E7A',
  tertiary: '#7D185C',
  success: '#71D875',
  warning: '#FFB500',
}
