import styled from 'styled-components'

const ItemImage = styled.img`
  max-height: 100%;
  max-width: 100%;
  transform: ${(props) => (props.rotate ? `rotate(${props.rotate}deg)` : '')};
`

const BackgroundItemImage = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 350px;
  margin: 0 auto;
  background-color: #c4c4c4a3;
  overflow: hidden;
`

const ItemsBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer;
`

const RowTop = styled.div`
  width: 50%;
  height: 50%;
  margin: 0 auto;
  &:hover {
    background-color: rgb(255 255 255 / 75%);
  }
`

const RowBottom = styled.div`
  width: 50%;
  height: 50%;
  margin: 0 auto;
  &:hover {
    background-color: rgb(255 255 255 / 75%);
  }
`

const ImageModalFullScreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  background-color: #000000a3;
  z-index: 100000;
  img {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 80vh;
    max-width: 100%;
  }
`

export {
  ItemImage,
  BackgroundItemImage,
  ItemsBox,
  RowTop,
  RowBottom,
  Item,
  ImageModalFullScreen,
}
