import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'

import Paragraphs from '../../../../../components/commons/Paragraphs/Paragraphs'
import Modal from '../../../../../components/commons/Modal'
import SwitchInput from '../../../../../components/commons/SwitchInput'
import TextInput from '../../../../../components/commons/TextInput/TextInput'

import ModalNav from '../../ModalConfiguracion/components/ModalNav/ModalNav'
import './styles.css'
import { columns } from '../../../aggrid/columns'
import { useTranslation } from 'react-i18next'
import Aggrid from '../../../../../infrastructure/components/Aggrid/Aggrid'

const selectOptions = [
  {
    value: 2,
    label: 'Administrador',
  },
  {
    value: 3,
    label: 'Planograma Retail Manager',
  },
]

const UserModal = ({
  getFarmaciasListing,
  toggle,
  edit,
  onAccept,
  formErrors,
  getGroupsList,
  totalRows,
}) => {
  const { t } = useTranslation()
  const gridRef = useRef()
  const gridGroupRef = useRef()
  const [selectedRole, setSelectedRole] = useState(0)
  const [selectAll, setSelectAll] = useState(false)
  const [apiGrid, setGridApi] = useState(null)
  const [groupApiGrid, setGroupApiGrid] = useState(null)
  const [farmaciasSeleccionadas, setFarmaciasSeleccionadas] = useState(
    edit?.organizaciones || []
  )
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm({
    defaultValues: {
      ...edit,
    },
    shouldFocusError: true,
  })

  useEffect(() => {
    if (edit) {
      setSelectedRole(
        selectOptions.find(({ label }) => label === edit.perfiles[0])?.value
      )

      if (edit.organizaciones_todas) {
        setSelectAll(true)
        setFarmaciasSeleccionadas([])
      }

      if (edit.organizaciones.length > 0) {
        setFarmaciasSeleccionadas(edit.organizaciones)
      }
    }
    if (formErrors) {
      handleErrors(formErrors)
    }
  }, [edit, formErrors])

  useEffect(() => {
    document
      .getElementById('modal-edit-user')
      ?.classList.add('tw-overflow-y-auto')
  }, [toggle])

  // Handle submit

  const submitToggle = async (values) => {
    if (!selectedRole) {
      document
        .getElementById('user-rol')
        .classList.add('tw-border-red-500', 'tw-border')
      return
    }

    let validationBox =
      document.getElementById('user-farmacias') &&
      document.getElementById('user-farmacias')

    if (selectedRole == 3) {
      if (!selectAll && !farmaciasSeleccionadas.length) {
        validationBox &&
          validationBox.classList.add('tw-border-red-500', 'tw-border')
        validationBox && validationBox.classList.remove('tw-hidden')
        return
      }
    } else {
      delete values.organizaciones_todas
    }

    values.perfil = parseInt(selectedRole)
    values.nickname = values.email

    if (values.perfil === 3) {
      if (selectAll) {
        values.organizaciones = []
        values.organizaciones_todas = true
      } else if (farmaciasSeleccionadas.length > 0) {
        values.organizaciones_todas = false
        values.organizaciones = farmaciasSeleccionadas.map(
          (farmacia) => farmacia.id
        )
      }
    } else {
      delete values.organizaciones
    }

    delete values.perfiles

    let groups = []
    if (groupApiGrid) {
      groupApiGrid.forEachNode((node) => {
        if (node.data.selected) groups.push(node.data.id)
      })
    }

    values.groups = groups

    onAccept(values)
  }

  // Handle errors
  const handleErrors = (errors) => {
    if (errors.length > 0) {
      errors.forEach((error) => {
        Object.keys(error).forEach((key) => {
          setError(
            key,
            { type: 'focus', message: error[key] },
            { shouldFocus: true }
          )
        })
      })
    }
  }

  const onGridReady = useCallback((params) => {
    return getFarmaciasListing().then(({ data }) => {
      setGridApi(params.api)
      params.api.setRowData(data.data)
      return data.data
    })
  }, [])

  const onSelectChange = () => {
    setSelectAll(!selectAll)
  }

  useEffect(() => {
    apiGrid?.addEventListener('firstDataRendered', ({ api }) => {
      if (edit && edit.organizaciones?.length > 0) {
        api.forEachNode(function (node) {
          farmaciasSeleccionadas.forEach((farmacia) => {
            if (node.data.id === farmacia.id) {
              node.setSelected(true)
            }
          })
        })
      }
    })
  }, [apiGrid])

  function onSelectionChanged(e) {
    const selectedNodes = e.api.getSelectedNodes()
    const selectedData = selectedNodes.map((node) => node.data)
    setFarmaciasSeleccionadas(selectedData)
  }

  function handleOnAccept(values) {
    clearErrors()
    handleSubmit(submitToggle)(values)
  }

  const onGridReadyGrups = useCallback((params) => {
    setGroupApiGrid(params.api)
    const updateData = () => {
      const datasource = {
        async getRows(rowParams) {
          const { filterModel } = rowParams.request
          let filteredName = null
          if (filterModel['name']) {
            filteredName = `%${filterModel['name'].filter}%`
          }
          let orderBy = null
          if (rowParams.request?.sortModel?.length > 0) {
            orderBy = rowParams.request.sortModel.map((sort) => {
              return {
                column: sort.colId,
                order: sort.sort.toUpperCase(),
              }
            })
          }
          let page = params.api.paginationGetCurrentPage() + 1

          await getGroupsList(rowParams, page, orderBy, filteredName)
        },
      }
      params.api.setServerSideDatasource(datasource)
    }

    updateData(params.api)
  }, [])

  const CheckboxSelection = (params) => {
    return (
      <div className="checkbox-selection tw-h-full tw-flex tw-items-center">
        <input
          type="checkbox"
          onClick={(e) => handleSelectAll(params, e)}
          style={{
            width: '20px',
            height: '20px',
            marginRight: '10px',
            position: 'relative',
            clipPath: 'none',
          }}
        />
        <Paragraphs size="sm" className="tw-text-white">
          {params.displayName}
        </Paragraphs>
      </div>
    )
  }

  const handleSelectAll = (params, e) => {
    if (e.target.checked) {
      params.api.forEachNode(function (node) {
        node.data.selected = true
      })
    } else {
      params.api.forEachNode(function (node) {
        node.data.selected = false
      })
    }
    params.api?.refreshCells({ force: true })
  }

  const onCellClicked = (params) => {
    if (params) {
      params.data.selected = !params.data.selected
      params.api?.refreshCells({ force: true })
    }
  }

  return (
    <Modal
      onAccept={handleOnAccept}
      onCancel={toggle}
      className="ModalConfiguracion"
      btnTextAccept={edit?.id ? t('labels.Editar') : t('labels.Crear')}
      maximizable={false}
      widthContentFull={false}
      verticalAlign={'middle'}
      id="modal-edit-user"
    >
      <form className="tw-z-50">
        <section className="ModalNavSection tw-flex tw-flex-1">
          <ModalNav
            title={
              edit?.id ? t('labels.Editar usuario') : t('labels.Nuevo usuario')
            }
          />
        </section>
        <div className="tw-flex tw-flex-col tw-gap-3 tw-p-3">
          <div className="userManagementModal tw-flex">
            <section className="LeftColumn tw-flex-col tw-flex-1">
              <div className="FirstRow tw-flex tw-flex-row">
                <article className="tw-p-1 tw-mr-2 tw-w-full">
                  <TextInput
                    id="nombre"
                    name="nombre"
                    error={errors?.nombre?.message}
                    label={t('Nombre')}
                    register={register('nombre', {
                      required: {
                        message: t('messages.Campo obligatorio'),
                      },
                    })}
                  />
                </article>
                <article className="tw-p-1 tw-mr-2 tw-w-full">
                  <TextInput
                    id="apellidos"
                    name="apellidos"
                    error={errors?.apellidos?.message}
                    label={t('Apellidos')}
                    register={register('apellidos', {
                      required: {
                        message: t('messages.Campo obligatorio'),
                      },
                    })}
                  />
                </article>
              </div>

              <div className="SecondRow tw-flex tw-flex-col">
                <div className="TextInputs tw-flex tw-flex-row">
                  <article className="tw-p-1 tw-mr-2 tw-w-full">
                    <TextInput
                      id="email"
                      name="email"
                      error={errors?.email?.message}
                      label={t('Correo electrónico')}
                      register={register('email', {
                        required: {
                          value: true,
                          message: t('messages.Campo obligatorio'),
                        },
                      })}
                    />
                  </article>
                  <article className="tw-p-1 tw-mr-2 tw-w-full"></article>
                </div>
              </div>

              <div className="ThirdRow tw-flex tw-flex-col">
                <div className="TextInputs tw-flex tw-flex-row">
                  <article className="tw-p-1 tw-mr-2 tw-w-full">
                    <Paragraphs weight="bold">Rol</Paragraphs>
                    <select
                      id="user-rol"
                      className="tw-bg-gray-100 tw-outline-none tw-h-full tw-flex-1 tw-px-2 tw-bg-transparent tw-w-full tw-font-normal tw-text-left text-sm"
                      onChange={(e) => {
                        e.target.classList.remove(
                          'tw-border-red-500',
                          'tw-border'
                        )
                        setSelectedRole(e.target.value)
                      }}
                    >
                      <>
                        {edit?.perfiles.lenght > 0 ? (
                          <option value={edit?.perfiles[0]}>
                            {edit?.perfiles[0]}
                          </option>
                        ) : (
                          <option value="">{t('labels.Seleccionar...')}</option>
                        )}
                        {selectOptions.map((option) => (
                          <option
                            value={option.value}
                            key={option.value}
                            selected={edit?.perfiles[0] === option.label}
                          >
                            {option.label}
                          </option>
                        ))}
                      </>
                    </select>
                  </article>
                  <article className="tw-p-1 tw-mr-2 tw-w-full"></article>
                </div>
              </div>
            </section>
          </div>

          <div className="tw-flex tw-flex-col tw-gap-3 tw-my-5 tw-p-1">
            {selectedRole == 3 ? (
              <>
                <SwitchInput
                  label={t('labels.Asignar a todas las farmacias')}
                  name={'switch-planograma-publico'}
                  id={'switch-planograma-publico'}
                  row
                  register={register('organizaciones_todas')}
                  checked={selectAll}
                  onChage={onSelectChange}
                />
                {Boolean(!selectAll) &&
                  Boolean(farmaciasSeleccionadas.length === 0) && (
                    <div
                      className="tw-flex tw-flex-col tw-gap-3 tw-p-3"
                      id="user-farmacias"
                    >
                      <Paragraphs size={'xs'}>
                        {t('messages.Selecciona almenos una farmacia')}
                      </Paragraphs>
                    </div>
                  )}
                {Boolean(!selectAll) && (
                  <>
                    <div className="tw-flex tw-flex-col tw-my-1 tw-p-1">
                      <div className="tw-flex tw-justify-between">
                        <div className="tw-h-60 tw-border tw-border-gray tw-w-full">
                          <div className="tw-h-60 tw-border tw-border-gray tw-w-full">
                            <Aggrid
                              ref={gridRef}
                              id="user-modal-edit-create"
                              columns={columns.usersPharmacys}
                              rowModelType="clientSide"
                              onGridReady={onGridReady}
                              height={'100%'}
                              hasFilter={false}
                              rowSelection="multiple"
                              suppressRowClickSelection={true}
                              onRowSelected={onSelectionChanged}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tw-flex tw-flex-col tw-my-1 tw-p-1">
                      <div className="tw-flex tw-justify-between">
                        <div className="tw-h-60 tw-border tw-border-gray tw-w-full">
                          <Aggrid
                            onCellClicked={onCellClicked}
                            ref={gridGroupRef}
                            id="table-groups-management"
                            columns={columns.usersGroup}
                            onGridReady={onGridReadyGrups}
                            rowModelType={'serverSide'}
                            type="scroll"
                            cacheBlockSize={totalRows}
                            paginationAutoPageSize={false}
                            serverSideInfiniteScroll={true}
                            rowSelection="multiple"
                            customFrameworkComponents={{
                              checkboxSelection: CheckboxSelection,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : null}
          </div>
        </div>
      </form>
    </Modal>
  )
}

UserModal.propTypes = {
  edit: PropTypes.object,
  toggle: PropTypes.func.isRequired,
  getFarmaciasListing: PropTypes.func.isRequired,
  onAccept: PropTypes.func,
  formErrors: PropTypes.array,
  getGroupsList: PropTypes.func.isRequired,
}

export default UserModal
