import { gql } from '@apollo/client'

export const PLANOGRAMA_CREAR_LINEAL_BACKOFFICE = gql`
  mutation ($input: PlanogramaLinealWizardInput) {
    planogramaLinealWizardBackoffice(input: $input) {
      id
      nombre
    }
  }
`

export const PLANOGRAMA_ACTUALIZAR_NOMBRE = gql`
  mutation ($id: ID!, $input: PlanogramaLinealInput) {
    planogramaUpdateName(id: $id, input: $input) {
      id
      nombre
    }
  }
`

export const PLANOGRAMA_ACTUALIZAR_SOPORTE_BACKOFFICE = gql`
  mutation ($id: ID!, $input: PlanogramaLinealInput) {
    planogramaActualizarLinealBackoffice(id: $id, input: $input) {
      id
      nombre
    }
  }
`

export const PLANOGRAMA_ACTUALIZAR_LINEAL_BACKOFFICE = gql`
  mutation ($id: ID!, $input: PlanogramaLinealInput) {
    planogramaActualizarLinealBackoffice(id: $id, input: $input) {
      id
      altura
      anchura
      estado
      profundo
      categories {
        id
        nombre
        nivel
        nivel1 {
          id
          nombre
        }
        nivel2 {
          id
          nombre
        }
        nivel3 {
          id
          nombre
        }
        nivel4 {
          id
          nombre
        }
        subcategorias {
          id
          nombre
        }
      }
      borde_derecho
      borde_inferior
      borde_izquierdo
      borde_superior
      divisiones {
        id
        x
        espesor_division
        anchura
        soportes {
          altura
          anchura
          x
          y
          modo
          espesor_soporte
          profundo
          alineacion
          id
          nombre
          articulos {
            id
            nombre
            imagen
            ean
            giff
            anchura
            altura
          }
        }
      }
    }
  }
`

export const PLANOGRAMA_BORRAR_LINEAL_BACKOFFICE = gql`
  mutation ($id: ID!) {
    planogramaBorrarLinealBackoffice(id: $id) {
      id
    }
  }
`

export const UPDATE_FULL_PLANOGRAM = gql`
  mutation ($id: ID!, $input: PlanogramaLinealInput!) {
    updateFullPlanogramaLineal(id: $id, input: $input) {
      nombre
      id
      categories {
        id
        nombre
        nivel
        nivel1 {
          id
          nombre
        }
        nivel2 {
          id
          nombre
        }
        nivel3 {
          id
          nombre
        }
        nivel4 {
          id
          nombre
        }
        subcategorias {
          id
          nombre
        }
      }
      estado
      blocked
      publico
      anchura
      profundo
      organizacion {
        id
      }
      altura
      profundo
      borde_derecho
      borde_superior
      borde_inferior
      borde_izquierdo
      updated_at
      divisiones {
        id
        nombre
        espesor_division
        anchura
        x
        posicion
        soportes {
          id
          modo
          anchura
          altura
          alineacion
          espesor_soporte
          profundo
          x
          y
          separacion_gancho_x
          separacion_gancho_y
          inicio_gancho_x
          inicio_gancho_y
          articulos {
            id
            nombre
            imagen
            giff
            ean
            altura
            rotacion
            anchura
            laboratorio {
              nombre
            }
            soporte {
              id
            }
            profundo
            x
            y
          }
        }
      }
    }
  }
`

export const PLANOGRAMA_CLONE_LINEAL_BACKOFFICE = gql`
  mutation ($id: String!) {
    clonarLinealDivisionBackoffice(id: $id) {
      id
      nombre
      anchura
      divisiones {
        id
        nombre
        espesor_division
        x
        anchura
        posicion
        soportes {
          id
          x
          y
          alineacion
          altura
          anchura
          espesor_soporte
          modo
          profundo
          separacion_gancho_x
          separacion_gancho_y
          inicio_gancho_x
          inicio_gancho_y
        }
      }
    }
  }
`

export const PLANOGRAMA_CREAR_LINEAL_SOPORTE_BACKOFFICE = gql`
  mutation ($id: String!, $input: PlanogramaLinealSoporteInput) {
    planogramaCrearLinealSoporteBackoffice(id: $id, input: $input) {
      id
    }
  }
`

export const PLANOGRAMA_BORRAR_SOPORTE_BACKOFFICE = gql`
  mutation ($id: String!) {
    planogramaBorrarLinealSoporteBackoffice(id: $id) {
      id
    }
  }
`

export const PLANOGRAMA_BORRAR_ARTICULOS_LINEAL_BACKOFFICE = gql`
  mutation ($id: [String!], $lineal_id: ID) {
    planogramaBorrarArticulosLinealBackoffice(id: $id, lineal_id: $lineal_id) {
      id
    }
  }
`

export const PLANOGRAMA_ACTUALIZAR_LINEAL_SOPORTE_BACKOFFICE = gql`
  mutation ($id: String!, $input: PlanogramaActualizarLinealSoporteInput) {
    planogramaActualizarLinealSoporteBackoffice(id: $id, input: $input) {
      nombre
      id
      anchura
      altura
      profundo
      destacado
      image {
        url
      }
      borde_derecho
      borde_superior
      borde_inferior
      borde_izquierdo
      divisiones {
        id
        nombre
        espesor_division
        anchura
        x
        soportes {
          id
          modo
          anchura
          altura
          espesor_soporte
          profundo
          x
          y
        }
      }
    }
  }
`

export const PLANOGRAMA_LINEALES_BACKOFFICE = gql`
  mutation (
    $first: Int
    $page: Int
    $nombre: String
    $publico: Boolean
    $estado: String
    $organizacion: String
    $categoria_id: Int
    $updated_at: String
    $orderBy: [OrderByClause!]
  ) {
    planogramaLinealesBackoffice(
      first: $first
      page: $page
      nombre: $nombre
      publico: $publico
      estado: $estado
      organizacion: $organizacion
      categoria_id: $categoria_id
      updated_at: $updated_at
      orderBy: $orderBy
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        nombre
        id
        anchura
        altura
        profundo
        destacado
        groups {
          id
          name
          lineals {
            id
            nombre
          }
          organizations {
            id
            nombre
          }
        }
        image {
          url
        }
        categories {
          id
          nombre
          nivel
          nivel1 {
            id
            nombre
          }
          nivel2 {
            id
            nombre
          }
          nivel3 {
            id
            nombre
          }
          nivel4 {
            id
            nombre
          }
          subcategorias {
            id
            nombre
          }
        }
        organizacion {
          id
          nombre
        }
        organizaciones {
          id
          nombre
        }
        estado
        blocked
        publico
        updated_at
      }
    }
  }
`

export const PLANOGRAMA_ARTICULOS_BACKOFFICE = gql`
  mutation ($page: Int, $first: Int, $busqueda: String) {
    planogramaArticulosBackoffice(
      first: $first
      page: $page
      busqueda: $busqueda
    ) {
      paginatorInfo {
        count
        currentPage
        hasMorePages
        total
      }
      data {
        id
        giff
        nombre
        anchura
        altura
        profundo
        ean
        imagen
        rotacion
        laboratorio {
          nombre
          id
        }
      }
    }
  }
`

export const PLANOGRAMA_LINEAL_BACKOFFICE = gql`
  mutation ($id: ID!) {
    planogramaLinealBackoffice(id: $id) {
      nombre
      id
      categories {
        id
        nombre
        nivel
        nivel1 {
          id
          nombre
        }
        nivel2 {
          id
          nombre
        }
        nivel3 {
          id
          nombre
        }
        nivel4 {
          id
          nombre
        }
        subcategorias {
          id
          nombre
        }
      }
      estado
      blocked
      publico
      anchura
      profundo
      organizacion {
        id
      }
      altura
      profundo
      borde_derecho
      borde_superior
      borde_inferior
      borde_izquierdo
      updated_at
      divisiones {
        id
        nombre
        espesor_division
        anchura
        x
        posicion
        soportes {
          id
          modo
          anchura
          altura
          alineacion
          espesor_soporte
          profundo
          x
          y
          separacion_gancho_x
          separacion_gancho_y
          inicio_gancho_x
          inicio_gancho_y
          articulos {
            id
            nombre
            imagen
            giff
            ean
            altura
            rotacion
            anchura
            laboratorio {
              nombre
            }
            soporte {
              id
            }
            profundo
            x
            y
            categoria {
              id
              nombre
              nivel
            }
            active
            product {
              extra {
                id
                amount_pharmacy
                benefit_pharmacy
                fee_pharmacy
                stock_pharmacy
                share_market
                product_ean
                product_code
                pvp_pharmacy
                pvp_optimal
                sales_last_month
                units_sold_last_month
                optimal_sales
                sales_improvement
              }
              id
              product_image {
                type
              }
            }
          }
        }
      }
    }
  }
`

export const PLANOGRAMA_CATEGORIAS_LINEAL_BACKOFFICE = gql`
  mutation {
    planogramaCategoriasLinealBackoffice {
      data {
        nombre
        id
      }
    }
  }
`

export const PLANOGRAMA_CATEGORIAS = gql`
  mutation ($parents_id: [ID!]) {
    planogramaCategorias(parents_id: $parents_id) {
      data {
        nombre
        id
      }
    }
  }
`

export const PLANOGRAMA_BORRAR_LINEAL_DIVISION_BACKOFFICE = gql`
  mutation ($id: String!) {
    planogramaBorrarLinealDivisionBackoffice(id: $id) {
      nombre
      id
      categories {
        id
        nombre
        nivel
        nivel1 {
          id
          nombre
        }
        nivel2 {
          id
          nombre
        }
        nivel3 {
          id
          nombre
        }
        nivel4 {
          id
          nombre
        }
        subcategorias {
          id
          nombre
        }
      }
      estado
      blocked
      publico
      anchura
      profundo
      organizacion {
        id
      }
      altura
      profundo
      borde_derecho
      borde_superior
      borde_inferior
      borde_izquierdo
      updated_at
      divisiones {
        id
        nombre
        espesor_division
        anchura
        x
        posicion
        soportes {
          id
          modo
          anchura
          altura
          alineacion
          espesor_soporte
          profundo
          x
          y
          separacion_gancho_x
          separacion_gancho_y
          inicio_gancho_x
          inicio_gancho_y
          articulos {
            id
            nombre
            imagen
            giff
            ean
            altura
            rotacion
            anchura
            laboratorio {
              nombre
            }
            soporte {
              id
            }
            profundo
            x
            y
          }
        }
      }
    }
  }
`

export const PLANOGRAMA_CLONAR_LINEAL_PUBLICO_A_PUBLICO_BACKOFFICE = gql`
  mutation ($id: ID!) {
    planogramaClonarLinealPublicoAPublicoBackoffice(id: $id) {
      id
    }
  }
`

export const PLANOGRAMA_INSERTAR_ARTICULO_SOPORTE_BACKOFFICE = gql`
  mutation ($id: String!, $input: PlanogramaLinealSoporteArticuloInput) {
    planogramaInsertarArticuloSoporteBackoffice(id: $id, input: $input) {
      id
    }
  }
`

export const PLANOGRAMA_MOVER_ARTICULO_SOPORTE_BACKOFFICE = gql`
  mutation ($id: String!, $input: PlanogramaLinealSoporteArticuloInput) {
    planogramaMoverArticuloSoporteBackoffice(id: $id, input: $input) {
      id
    }
  }
`

export const PLANOGRAMA_ACTUALIZAR_ARTICULO_SOPORTE_BACKOFFICE = gql`
  mutation ($id: String!, $input: PlanogramaLinealSoporteArticuloInput) {
    planogramaActualizarArticuloSoporteBackoffice(id: $id, input: $input) {
      id
    }
  }
`

export const PLANOGRAMA_BORRAR_ARTICULO_SOPORTE_BACKOFFICE = gql`
  mutation ($id: String!) {
    planogramaBorrarArticuloSoporteBackoffice(id: $id) {
      id
    }
  }
`

export const FILTRAR_FARMACIAS_PAGINADO = gql`
  mutation ($first: Int, $page: Int, $input: FiltrarOrganizacionesInput) {
    filtrarOrganizacionesPaginado(page: $page, first: $first, input: $input) {
      paginatorInfo {
        lastItem
        lastPage
        total
        count
        hasMorePages
        perPage
        firstItem
        currentPage
      }
      data {
        id
        nombre
        id_sertec
      }
    }
  }
`

export const PLANOGRAMA_ACTUALIZAR_DATOS_ARTICULO_BACKOFICE = gql`
  mutation ($id: ID!, $input: PlanogramaArticuloInput) {
    planogramaActualizarDatosArticuloBackoffice(id: $id, input: $input) {
      id
    }
  }
`

export const PLANOGRAMA_CLONAR_LINEAL_RETAIL_BACKOFFICE = gql`
  mutation ($id: ID!) {
    planogramaClonarLinealRetailBackoffice(id: $id) {
      id
    }
  }
`

export const PLANOGRAMA_CLONAR_LINEAL_FARMACIA = gql`
  mutation ($id: ID!, $farmacia_id: ID) {
    planogramaClonarLinealOrganizacion(id: $id, farmacia_id: $farmacia_id) {
      id
    }
  }
`

export const ORGANIZACIONES = gql`
  mutation (
    $first: Int
    $page: Int
    $nombre: String
    $orderBy: [OrderByClause!]
  ) {
    organizaciones(
      first: $first
      page: $page
      nombre: $nombre
      orderBy: $orderBy
    ) {
      data {
        id
        nombre
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`

/**
 *  ### USERS ###
 *  =============
 */

export const CREATE_USER_ADMIN = gql`
  mutation ($input: UsuarioAdminInput!) {
    createUserAdmin(input: $input) {
      id
      nombre
      apellidos
      email
      organizaciones {
        id
        nombre
      }
      perfiles {
        id
        perfil
      }
      groups {
        id
        name
      }
    }
  }
`

export const DELETE_USER_ADMIN = gql`
  mutation ($id: ID!) {
    deleteUserAdmin(id: $id)
  }
`

export const EDIT_USER_ADMIN = gql`
  mutation ($id: ID!, $input: UpdateAdminInput!) {
    updateUserAdmin(id: $id, input: $input) {
      id
      nombre
      apellidos
      email
      perfiles {
        perfil
      }
      organizaciones {
        id
        nombre
      }
    }
  }
`

export const UNBLOCK_PLANOGRAM = gql`
  mutation ($id: ID!) {
    planogramaLinealDesbloquear(id: $id)
  }
`

//•    SUPERFAMILIA = FAM0 = Nivel 1
//•    FAMILIA = FAM1 = Nivel 2 (categoria_id)
//•    SUB FAMILIA = FAM2 = Nivel 3 (sub_familia_id)
//•    CATEGORÍA = FAM3 = Nivel 4 (categoria_final_id)

export const CATEGORIES_TREE = gql`
  mutation ($level: Int, $search: String) {
    categoriasPorNivel(nivel: $level, nombre: $search) {
      id
      nombre
      nivel1 {
        id
        nombre
      }
      nivel2 {
        id
        nombre
      }
      nivel3 {
        id
        nombre
      }
      nivel4 {
        id
        nombre
      }
      subcategorias {
        id
        nombre
      }
    }
  }
`

export const GET_LABORATORIES_LIST = gql`
  mutation ($search: String) {
    planogramaLaboratorios(nombre: $search) {
      data {
        id
        nombre
      }
    }
  }
`
export const CATEGORIES_FROM_FAMILIES = gql`
  mutation ($parents_id: [ID!], $first: Int, $page: Int) {
    planogramaSubFamilias(parents_id: $parents_id, first: $first, page: $page) {
      data {
        id
        nombre
        subcategorias {
          id
          nombre
        }
      }
      paginatorInfo {
        lastItem
        lastPage
        total
        count
        hasMorePages
        perPage
        firstItem
        currentPage
      }
    }
  }
`

export const FILTER_PRODUCTS = gql`
  mutation (
    $withStock: Boolean
    $busqueda: String
    $categoria_id: ID
    $laboratorio_id: ID
    $orderBy: [OrderByClause!]
    $first: Int
    $page: Int
  ) {
    planogramaArticulos(
      withStock: $withStock
      busqueda: $busqueda
      categoria_id: $categoria_id
      laboratorio_id: $laboratorio_id
      orderBy: $orderBy
      first: $first
      page: $page
    ) {
      data {
        id
        ean
        giff
        nombre
        anchura
        altura
        profundo
        width_original
        height_original
        depth_original
        rotacion
        laboratorio {
          id
          nombre
        }
        categoria {
          id
          nivel
          nombre
        }
        subcategoria {
          id
          nivel
          nombre
        }
        imagen
      }
      paginatorInfo {
        lastItem
        lastPage
        total
        count
        hasMorePages
        perPage
        firstItem
        currentPage
      }
    }
  }
`

export const PLANOGRAMA_ARTICULOS = gql`
  mutation (
    $families_id: [ID]
    $categories_id: [ID]
    $laboratories_id: [ID]
    $subfamilies_id: [ID]
    $is_fedefarma: Boolean
    $is_my_pharmacy: Boolean
    $first: Int
    $page: Int
    $showMore: String
    $numToShow: Int
    $filters: ProductFilterInput
  ) {
    planogramaArticulosSearch(
      families_id: $families_id
      categories_id: $categories_id
      laboratories_id: $laboratories_id
      subfamilies_id: $subfamilies_id
      is_fedefarma: $is_fedefarma
      is_my_pharmacy: $is_my_pharmacy
      first: $first
      page: $page
      show_more: $showMore
      num_to_show: $numToShow
      filters: $filters
    ) {
      data {
        id
        giff
        nombre
        is_fedefarma
        is_my_pharmacy
        laboratorio {
          id
          nombre
        }
        categoria {
          id
          nombre
        }
        subcategoria {
          id
          nombre
        }
        categoria_final {
          id
          nombre
        }
        imagen
      }
      sidebar {
        laboratories {
          id
          nombre
        }
        categories {
          id
          nombre
        }
        families {
          id
          nombre
        }
        subfamilies {
          id
          nombre
        }
      }
      paginatorInfo {
        currentPage
        total
      }
    }
  }
`
export const GENERATEQRCODE_UPLOAD_PRODUCT_IMAGE = gql`
  mutation {
    createProductQR {
      url
      signature
      qr
    }
  }
`
export const PLANOGRAMA_CREATE_PRODUCT = gql`
  mutation ($input: PlanogramaArticuloInput!) {
    planogramaArticuloCreate(input: $input) {
      id
      nombre
      ean
      laboratorio {
        id
        nombre
      }
      categoria {
        id
        nombre
      }
      categoria_final {
        id
        nombre
      }
      subcategoria {
        id
        nombre
      }
      imagen
      anchura
      altura
      profundo
      giff
      is_fedefarma
      is_my_pharmacy
    }
  }
`

export const PLANOGRAMA_UPDATE_PRODUCT = gql`
  mutation ($id: ID!, $input: PlanogramaArticuloInput!) {
    planogramaActualizarDatosArticuloBackoffice(id: $id, input: $input) {
      id
      nombre
      ean
      laboratorio {
        id
        nombre
      }
      categoria {
        id
        nombre
      }
      subcategoria {
        id
        nombre
      }
      categoria_final {
        id
        nombre
      }
      imagen
      anchura
      altura
      profundo
      giff
      is_fedefarma
      is_my_pharmacy
    }
  }
`

export const CHECK_IF_GIFF_EXISTS = gql`
  mutation ($giff: String) {
    checkCNExists(giff: $giff)
  }
`

export const GET_LABORATORIES = gql`
  mutation (
    $first: Int
    $page: Int
    $orderBy: [OrderByClause!]
    $nombre: String
    $productName: String
    $categories_id: [ID]
    $families_id: [ID]
    $subfamilies_id: [ID]
  ) {
    planogramaLaboratorios(
      first: $first
      page: $page
      orderBy: $orderBy
      nombre: $nombre
      productName: $productName
      categories_id: $categories_id
      families_id: $families_id
      subfamilies_id: $subfamilies_id
    ) {
      paginatorInfo {
        currentPage
        hasMorePages
      }
      data {
        id
        nombre
      }
    }
  }
`

export const PLANOGRAMA_DELETE_PRODUCT = gql`
  mutation ($id: ID!) {
    deleteProduct(id: $id) {
      id
    }
  }
`

/**
 * ## GROUPS
 */

export const CREATE_GROUP = gql`
  mutation ($input: GroupInput!) {
    createGroup(input: $input) {
      id
      name
    }
  }
`

export const UPDATE_GROUP = gql`
  mutation ($id: ID!, $input: GroupInput!) {
    updateGroup(id: $id, input: $input) {
      id
      name
    }
  }
`

export const DELETE_GROUP = gql`
  mutation ($id: ID!) {
    deleteGroup(id: $id) {
      id
    }
  }
`
export const GET_CATEGORY_STATS = gql`
  query ($id: [ID!]!, $organization_id: ID!) {
    getCategoriesStats(id: $id, organization_id: $organization_id) {
      id
      pharmacy_growth
      category_growth_level2
      category_growth_level3
      market_growth
    }
  }
`
export const POST_PHARMACY_CONFIG = gql`
  mutation ($input: PharmacyDimensionInput!) {
    storePharmacyDimension(input: $input) {
      id
      shelves
      modules
      shelf_width
      deep
      modules_height
    }
  }
`
