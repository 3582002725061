import { Fragment, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { QRCodeCanvas } from 'qrcode.react'
import { useTranslation } from 'react-i18next'

import Modal from '../../../../../components/commons/Modal'
import Paragraphs from '../../../../../components/commons/Paragraphs/Paragraphs'
import Label from '../../../../../components/commons/Label'

import {
  BackgroundItemImage,
  ItemsBox,
  Item,
  RowTop,
  RowBottom,
  ImageModalFullScreen,
} from './styles'

import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import './styles.css'

import Select from 'react-select'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  CATEGORIES_FROM_FAMILIES,
  CATEGORIES_TREE,
  CHECK_IF_GIFF_EXISTS,
  GET_LABORATORIES,
  PLANOGRAMA_CATEGORIAS,
} from '../../../graphQL/admin'
import ModalCropImages from '../ModalCropImage/ModalCropImage'
import Spinner from '../../../../../components/commons/Spinner/Spinner'
import {
  GET_ARTICULO_BY_ID,
  GET_NEXT_FREE_GIFF_CODE,
} from '../../../graphQL/querys'
import Logo from '../../Logo'

const customStyles = {
  control: (_, { selectProps: { width } }) => ({
    border: 'none',
    display: 'flex',
  }),
  menu: (provided, state) => ({
    ...provided,
    width: '100%',
    left: 0,
  }),
  menuList: (provided, state) => ({
    ...provided,
    '::-webkit-scrollbar': {
      width: '6px',
      height: '2px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '0px',
  }),
}

const ModalItem = ({
  onToggle,
  onCreateProduct,
  handleUploadImage,
  uploadedImage,
  generateQrCode,
  qrCode,
  setQrCode,
  id,
  isRetailer,
  donwloadImageFromUrl,
  downloadingQrImage,
}) => {
  const formSchema = Yup.object().shape({
    nombre: Yup.string().required((e) =>
      setBorders({ ...e, path: 'nombre_producto' })
    ),
    giff: Yup.string().required((e) => setBorders({ ...e, path: 'giff' })),
    ean: Yup.string().required((e) =>
      setBorders({ ...e, path: 'ean_producto' })
    ),
    anchura: Yup.string().required((e) =>
      setBorders({ ...e, path: 'anchura', value: parseFloat(e.value) })
    ),
    altura: Yup.string().required((e) =>
      setBorders({ ...e, path: 'altura', value: parseFloat(e.value) })
    ),
    profundo: Yup.string().required((e) =>
      setBorders({ ...e, path: 'profundo', value: parseFloat(e.value) })
    ),
    laboratorio_id: Yup.string().required((e) =>
      setBorders({ ...e, path: 'laboratorio_id' })
    ),
    categoria_id: Yup.string().required((e) =>
      setBorders({ ...e, path: 'categoria_id' })
    ),
    subcategoria_id: Yup.string().required((e) =>
      setBorders({ ...e, path: 'subcategoria_id' })
    ),
    categoria_final_id: Yup.string().required((e) =>
      setBorders({ ...e, path: 'categoria_final_id' })
    ),
  })

  const { handleSubmit, register, setValue } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onSubmit',
  })

  const { t } = useTranslation()
  const fileInputRef = useRef(null)
  const imageRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const [fullScreenImage, setFullScreenImage] = useState(false)
  const [errors, setErrors] = useState(false)
  const [categories, setCategories] = useState([])
  const [laboratories, setLaboratories] = useState([])
  const [subFamilies, setSubFamilies] = useState([])
  const [families, setFamilies] = useState([])
  const [isHover, setIsHover] = useState(false)
  const [openCropModal, setOpenCropModal] = useState(false)
  const [data, setData] = useState(null)
  const [selectedFamilie, setSelectedFamilie] = useState(null)
  const [selectedSubFamilie, setSelectedSubFamilie] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)

  const [checkGiff] = useMutation(CHECK_IF_GIFF_EXISTS)
  const [getCategoriesList] = useMutation(CATEGORIES_TREE)
  const [getCategoriesFromFamilies] = useMutation(CATEGORIES_FROM_FAMILIES)
  const [getLaboratoriesList] = useMutation(GET_LABORATORIES)
  const [planogramaCategorias] = useMutation(PLANOGRAMA_CATEGORIAS)
  const [getArticleById] = useMutation(GET_ARTICULO_BY_ID, {
    onCompleted: (data) => {
      setData(data.planogramaArticulo)
      setSelectedFamilie({
        value: data.planogramaArticulo.categoria_final?.id,
        label: data.planogramaArticulo.categoria_final?.nombre,
      })
      if (data.planogramaArticulo.categoria_final !== null)
        getCategoryFinal(data.planogramaArticulo.categoria_final.id)

      setSelectedSubFamilie({
        value: data.planogramaArticulo.subcategoria?.id,
        label: data.planogramaArticulo.subcategoria?.nombre,
      })
      setSelectedCategory({
        value: data.planogramaArticulo.categoria?.id,
        label: data.planogramaArticulo.categoria?.nombre,
      })

      if (data.planogramaArticulo.subcategoria !== null)
        getCategory(data.planogramaArticulo.subcategoria.id)

      setValue('subcategoria_id', data.planogramaArticulo?.subcategoria?.id)
      setValue('categoria_id', data.planogramaArticulo?.categoria?.id)
      setValue(
        'categoria_final_id',
        data.planogramaArticulo?.categoria_final?.id
      )
      setValue('laboratorio_id', data.planogramaArticulo?.laboratorio?.id)
      setLoading(false)
    },
  })
  const [getNextFreeGiffCodeApi] = useLazyQuery(GET_NEXT_FREE_GIFF_CODE)

  useEffect(() => {
    if (id) {
      getArticleById({
        variables: {
          id: id,
        },
      })
      getSubFamilies()
    } else {
      setLoading(false)
    }
    getFamilies()
    getLaboratories()
  }, [])

  useEffect(() => {
    if (data && data.ean) {
      setValue('ean', data.ean)
      data.giff && setValue('giff', data.giff)
      setValue('nombre', data.nombre)
      setValue('anchura', data.anchura)
      setValue('altura', data.altura)
      setValue('profundo', data.profundo)
      const { subcategoria, laboratorio_id, categoria_id, categoria_final_id } =
        data
      laboratorio_id && setValue('laboratorio_id', parseInt(laboratorio_id))
      categoria_id && setValue('categoria_id', parseInt(categoria_id))
      subcategoria && setValue('subcategoria_id', subcategoria.id)
      categoria_final_id &&
        setValue('categoria_final_id', parseInt(categoria_final_id))
      setValue('update', true)
      setValue('imagen', data.imagen)
      categoria_final_id && getCategories(categoria_final_id)
    } else {
      setIsHover(true)
    }
  }, [data])

  async function checkProductGiff(value) {
    const { data } = await checkGiff({
      variables: { giff: String(value) },
    })
    return data.checkCNExists
  }

  function setBorders(e) {
    if (typeof e === 'object') {
      document
        .getElementById(e.path)
        ?.setAttribute('style', 'border:2px solid #B42B00;')
      setErrors({
        ...errors,
        [e.path]: true,
      })
    }
  }

  const submitToggle = async (values) => {
    if (typeof errors === 'object' && Object.keys(errors).length > 0) return

    Object.keys(values).forEach((key) => {
      if (values[key] === '') {
        setBorders(key)
        setErrors({ ...errors, [key]: true })
      }
      if (key === 'ean') {
        if (values[key].length !== 13) {
          setBorders({ path: 'ean_producto' })
          setErrors({ ...errors, [key]: true })
        }
      }
    })

    if (!data && !uploadedImage) {
      setBorders({ path: 'image_box' })
      setLoading(false)
      setErrors({ ...errors, image_box: true })
      return
    } else {
      setErrors(false)
    }

    const response = await onCreateProduct(values)
    if (response?.backErrors) {
      Object.keys(backErrors).forEach((key) => {
        setBorders({ path: key })
      })

      setErrors(backErrors)
      setLoading(false)
    }
  }

  const zoomInImage = () => {
    if (uploadedImage || imageRef.current?.src || (data && data.imagen)) {
      if (
        imageRef.current.style.transform === 'scale(1)' ||
        imageRef.current.style.transform === ''
      ) {
        imageRef.current.style.transform = 'scale(1.5)'
      }
    }
  }
  const zoomOutImage = () => {
    if (uploadedImage || imageRef.current?.src || (data && data.imagen)) {
      if (imageRef.current.style.transform === 'scale(1.5)') {
        imageRef.current.style.transform = 'scale(1)'
      } else {
        imageRef.current.style.transform = 'scale(1)'
      }
    }
  }

  const zoomFullScreen = () => {
    if (uploadedImage || (data && data.imagen)) {
      setFullScreenImage(!fullScreenImage)
    }
  }

  const randomNumber = (min, max) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  const generateGiff = async () => {
    if (data && data.giff) return

    const ramdonNumber = randomNumber(150000, 999999)

    let giffExists = await checkProductGiff(ramdonNumber)

    if (giffExists) {
      setBorders('giff')
    } else {
      document.getElementById('giff').removeAttribute('style')
    }
    return setValue('giff', ramdonNumber)
  }

  const getFamilies = async () => {
    const { data } = await getCategoriesList({
      variables: { level: 2, search: null, first: 999 },
    })
    let categoriasPorNivel = null
    if (data) {
      categoriasPorNivel = data.categoriasPorNivel
    }

    if (!categoriasPorNivel || !categoriasPorNivel.length) {
      return []
    }
    const families = categoriasPorNivel.map((i) => {
      return {
        value: i.id,
        label: i.nombre,
        subFamilies: i.subcategorias,
      }
    })
    return setFamilies(families)
  }

  const getSubFamilies = async () => {
    const { data } = await getCategoriesList({
      variables: { level: 3, search: null },
    })
    let categoriasPorNivel = null
    if (data) {
      categoriasPorNivel = data.categoriasPorNivel
    }

    if (!categoriasPorNivel || !categoriasPorNivel.length) {
      return []
    }
    const subFamilies = categoriasPorNivel.map((i) => {
      return {
        value: i.id,
        label: i.nombre,
      }
    })
    return setSubFamilies(subFamilies)
  }

  const getCategories = async (parent_id) => {
    if (!parent_id) return
    const { data } = await getCategoriesFromFamilies({
      variables: { first: 1000, parents_id: [parent_id] },
    })
    if (data?.planogramaSubFamilias) {
      const { planogramaSubFamilias } = data

      const categories = planogramaSubFamilias.data.map((i) => {
        return {
          value: i.id,
          label: i.nombre,
        }
      })
      return setCategories(categories)
    }
  }

  const getLaboratories = async () => {
    const { data } = await getLaboratoriesList({ variables: { first: 1000 } })
    if (data?.planogramaLaboratorios?.data) {
      let laboratories = data.planogramaLaboratorios.data.map((laboratory) => {
        return {
          value: laboratory.id,
          label: laboratory.nombre,
        }
      })
      return setLaboratories(laboratories)
    }
  }

  useEffect(() => {
    if (uploadedImage && qrCode) {
      setOpenCropModal(true)
      setQrCode(false)
    }
  }, [uploadedImage])

  const getCategoryFinal = (id) => {
    if (!id) return
    let subFamilia = families?.find((f) => f.value === id)?.subFamilies
    setSubFamilies(
      subFamilia?.map((i) => ({ value: i.id, label: i.nombre })) || []
    )
  }

  const getCategory = async (id) => {
    if (!id) return
    const { data } = await planogramaCategorias({
      variables: { parents_id: [id] },
    })
    if (data?.planogramaCategorias) {
      const { planogramaCategorias } = data

      const categories = planogramaCategorias.data.map((i) => {
        return {
          value: i.id,
          label: i.nombre,
        }
      })
      return setCategories(categories)
    }
  }

  /**
   * @description Función que obtiene el siguiente código de giff disponible
   * @returns {Promise<void>}
   * @async
   */
  const getNextFreeGiffCode = async () => {
    const { data } = await getNextFreeGiffCodeApi()
    if (data?.getNextFreeGiffCode) {
      return setValue('giff', data.getNextFreeGiffCode.giff)
    }
  }

  return (
    <Fragment>
      {loading ? (
        <div className="tw-flex tw-flex-col tw-absolute tw-top-0 tw-left-0 tw-bg-white tw-bg-opacity-80 tw-h-full tw-w-full tw-z-10 tw-items-center tw-justify-center">
          <Logo width={100} />
          <Paragraphs>{t('labels.Cargando')}...</Paragraphs>
        </div>
      ) : (
        <Modal
          onAccept={handleSubmit(submitToggle)}
          onCancel={onToggle}
          btnTextAccept={t('labels.Guardar')}
          loading={loading}
          id={'create-product'}
        >
          <form className="create-product-form">
            <Paragraphs weight="bold" className="tw-mb-2" size={'lg'}>
              {t('titles.Ficha de artículo')}
            </Paragraphs>
            <div className="tw-flex">
              <div
                className="tw-w-3/6 tw-mr-16"
                style={{
                  maxWidth: '350px',
                }}
              >
                <BackgroundItemImage>
                  {downloadingQrImage ? (
                    <Spinner />
                  ) : (
                    qrCode && (
                      <QRCodeCanvas
                        value={qrCode.url}
                        renderas="canvas"
                        size={160}
                      />
                    )
                  )}
                  {!qrCode && data && data.imagen && !uploadedImage && (
                    <img
                      src={data.imagen}
                      alt="Imágen del producto"
                      className="tw-h-full tw-p-2"
                      style={{ objectFit: 'contain' }}
                      ref={imageRef}
                    />
                  )}
                  {!qrCode && uploadedImage && (
                    <img
                      src={
                        qrCode
                          ? uploadedImage
                          : URL.createObjectURL(uploadedImage)
                      }
                      alt={'Imágen del producto'}
                      className={`tw-h-full`}
                      ref={imageRef}
                    />
                  )}
                  {!uploadedImage && !data?.imagen && (
                    <ItemsBox
                      id="image_box"
                      onMouseEnter={() => setIsHover(true)}
                      className={`tw-h-full ${!isHover ? 'tw-hidden' : ''}`}
                    >
                      <RowTop>
                        <Item
                          onClick={() => {
                            fileInputRef.current?.click()
                          }}
                        >
                          <span
                            className="material-icons"
                            style={{ fontSize: '3em', color: '#656565' }}
                          >
                            cloud_upload
                          </span>
                          <div className="tw-row tw-mt-4">
                            <Paragraphs size={'sm'}>
                              {t('labels.Subir imagen desde tu disco')}
                            </Paragraphs>
                          </div>
                        </Item>
                      </RowTop>
                      <RowBottom>
                        <Item
                          onClick={() => {
                            generateQrCode()
                          }}
                        >
                          {!qrCode ? (
                            <>
                              <span
                                className="material-icons"
                                style={{ fontSize: '3em', color: '#656565' }}
                              >
                                qr_code
                              </span>
                              <div className="tw-row tw-mt-4">
                                <Paragraphs size={'sm'}>
                                  {t('labels.Escanea con tu smartphone')}
                                </Paragraphs>
                              </div>
                            </>
                          ) : null}
                        </Item>
                      </RowBottom>
                    </ItemsBox>
                  )}
                  <input
                    type="file"
                    name="imagen"
                    id="imagen"
                    className="tw-hidden"
                    {...register('imagen')}
                    onChange={(e) => {
                      handleUploadImage(e)
                      setOpenCropModal(true)
                      setErrors(false)
                      setQrCode(false)
                      e.target.removeAttribute('style')
                    }}
                    ref={fileInputRef}
                    required
                    accept="image/png, image/jpeg, image/jpg"
                  />
                </BackgroundItemImage>
                <div className="tw-flex tw-justify-between tw-mt-2">
                  <div className="tw-row tw-content-start tw-items-center">
                    <span
                      className="material-icons tw-mr-2 tw-cursor-pointer"
                      onClick={() => zoomInImage()}
                    >
                      zoom_in
                    </span>
                    <span
                      className="material-icons tw-mr-2 tw-cursor-pointer"
                      onClick={() => zoomOutImage()}
                    >
                      zoom_out
                    </span>
                    <span
                      className="material-icons tw-mr-2 tw-cursor-pointer"
                      onClick={() => zoomFullScreen()}
                    >
                      zoom_out_map
                    </span>
                  </div>
                  <div className="tw-row tw-content-end tw-items-center">
                    {(!!uploadedImage || data?.imagen) && (
                      <span
                        className="material-icons tw-cursor-pointer tw-mr-2"
                        onClick={(e) => {
                          e.stopPropagation()
                          if (uploadedImage) {
                            return setOpenCropModal(true)
                          } else if (data && data.imagen) {
                            donwloadImageFromUrl(data.imagen)
                          }
                          setOpenCropModal(true)
                        }}
                      >
                        edit
                      </span>
                    )}
                    <span
                      className="material-icons tw-mr-2 tw-cursor-pointer"
                      onClick={() => {
                        fileInputRef.current?.click()
                      }}
                    >
                      cloud_upload
                    </span>
                    <span
                      className="material-icons tw-cursor-pointer"
                      style={
                        downloadingQrImage
                          ? { cursor: 'not-allowed', pointerEvents: 'none' }
                          : {}
                      }
                      onClick={() => {
                        generateQrCode()
                      }}
                    >
                      qr_code
                    </span>
                  </div>
                </div>
              </div>
              <div className="tw-w-full">
                <div className="tw-grid tw-grid-cols-2">
                  <div className="tw-flex tw-justify-start tw-items-center tw-w-full tw-m-0 tw-p-0">
                    <div className="tw-w-2/5">
                      <Label label="Código N" size="base" />
                    </div>
                    <div className="tw-w-2/5 tw-relative tw-overflow-auto">
                      <input
                        type="number"
                        className="tw-outline-none tw-h-10 tw-flex-1 tw-px-2 tw-bg-transparent tw-w-full tw-border-2 tw-bg-white"
                        min="000000"
                        max="999999"
                        name="giff"
                        id="giff"
                        {...register('giff', {
                          onChange: (e) => {
                            e.target.removeAttribute('style')
                          },
                        })}
                        onKeyDown={(e) => {
                          delete errors['giff']
                          setErrors({ ...errors })
                          if (e.target.value.length <= 5) {
                            setValue('giff', e.target.value)
                          }
                          setValue('giff', e.target.value.slice(0, 6))
                        }}
                        required={isRetailer}
                      />
                      <span
                        className="material-icons tw-absolute tw-top-1.5 tw-right-1.5 tw-z-10 tw-bg-white tw-cursor-pointer"
                        onClick={() => {
                          if (isRetailer) {
                            generateGiff()
                          } else {
                            getNextFreeGiffCode()
                          }
                        }}
                      >
                        autorenew
                      </span>
                      <span className="tw-text-xxs tw-text-red-500">
                        {errors && errors['giff'] && errors['giff']}
                      </span>
                    </div>
                  </div>
                  <div className="tw-flex tw-justify-center tw-items-center tw-p-0">
                    <div className="tw-w-2/5 tw-mx-2">
                      <Label
                        label="ean"
                        size="base"
                        className="tw-text-right tw-w-full"
                      />
                    </div>
                    <div className="tw-w-4/5 tw-mx-8 tw-my-2">
                      <input
                        name="ean_producto"
                        id="ean_producto"
                        {...register('ean', {
                          onChange: (e) => {
                            e.target.removeAttribute('style')
                          },
                        })}
                        onKeyDown={(e) => {
                          //exact 13 characters
                          setErrors(false)
                          if (e.target.value.length <= 12) {
                            setValue('ean', e.target.value)
                          } else {
                            setValue('ean', e.target.value.slice(0, 13))
                          }
                        }}
                        onBlur={(e) => {
                          if (e.target.value.length !== 13) {
                            setBorders({ ...e, path: 'ean_producto' })
                          }
                        }}
                        required
                        className="tw-outline-none tw-h-10 tw-flex-1 tw-px-2 tw-bg-transparent tw-w-full tw-border-2 tw-bg-white"
                        style={
                          errors && errors['ean']
                            ? { border: '2px solid #B42B00' }
                            : {}
                        }
                      />
                      <span className="tw-text-xxs tw-text-red-500">
                        {errors &&
                          errors['ean'] &&
                          'El código EAN debe tener 13 caracteres'}
                      </span>
                    </div>
                  </div>
                  <div className="tw-flex tw-justify-center tw-items-center tw-p-0 tw-col-span-2">
                    <div className="tw-w-1/5 tw-mr-2">
                      <Label label="Nombre" size="base" />
                    </div>
                    <div className="tw-w-4/5 tw-mr-8">
                      <input
                        name="nombre_producto"
                        id="nombre_producto"
                        {...register('nombre', {
                          onChange: (e) => {
                            e.target.removeAttribute('style')
                          },
                        })}
                        required
                        className="tw-outline-none tw-h-10 tw-flex-1 tw-px-2 tw-bg-transparent tw-w-full tw-border-2 tw-bg-white"
                      />
                    </div>
                  </div>
                  <div className="tw-flex tw-justify-center tw-items-center tw-p-0 tw-col-span-2">
                    <div className="tw-w-1/5 tw-mr-2">
                      <Label label="Laboratorio" size="base" />
                    </div>
                    <div className="tw-w-4/5 tw-mr-8 tw-my-2">
                      <Select
                        placeholder="Seleccionar..."
                        noOptionsMessage={() => t('labels.No hay opciones')}
                        styles={customStyles}
                        options={laboratories}
                        defaultValue={{
                          value: data?.laboratorio?.id,
                          label: data?.laboratorio?.nombre,
                        }}
                        className="create-product tw-outline-none tw-h-10 tw-flex-1 tw-px-1 tw-bg-transparent tw-w-full tw-border-2 tw-bg-white"
                        name="laboratorio_id"
                        id="laboratorio_id"
                        onChange={(e) => {
                          setValue('laboratorio_id', e.value)
                          document
                            .getElementById('laboratorio_id')
                            .removeAttribute('style')
                        }}
                      />
                    </div>
                  </div>
                  <div className="tw-flex tw-items-center tw-w-full tw-m-0 tw-p-0">
                    <div className="tw-w-2/5">
                      <Label label="Anchura" size="base" />
                    </div>
                    <div className="tw-w-1/5 tw-relative">
                      <input
                        className="tw-outline-none tw-h-10 tw-flex-1 tw-px-2 tw-bg-transparent tw-w-full tw-border-2 tw-bg-white "
                        name="anchura"
                        id="anchura"
                        {...register('anchura', {
                          onChange: (e) => e.target.removeAttribute('style'),
                        })}
                        required
                        type="number"
                        min="0"
                        step=".01"
                      />
                      <span className="tw-absolute tw-top-1.5 tw-right-1 tw-bg-white">
                        cm
                      </span>
                    </div>
                  </div>
                  <div className="tw-flex tw-items-center tw-w-full tw-m-0 tw-p-0">
                    <div className="tw-w-2/5 tw-mx-4">
                      <Label label="Familia" size="base" />
                    </div>
                    <div className="tw-w-4/5 tw-mr-8 tw-my-2">
                      <Select
                        noOptionsMessage={() => t('labels.No hay opciones')}
                        placeholder="Seleccionar..."
                        styles={customStyles}
                        options={families}
                        value={
                          selectedFamilie
                            ? {
                                ...selectedFamilie,
                              }
                            : null
                        }
                        className="create-product tw-outline-none tw-h-10 tw-flex-1 tw-px-1 tw-bg-transparent tw-w-full tw-border-2 tw-bg-white"
                        name="categoria_final_id"
                        id="categoria_final_id"
                        onChange={(e) => {
                          setSelectedFamilie(e)
                          if (e.value !== selectedFamilie?.value) {
                            setSelectedSubFamilie(null)
                          }
                          if (e.value !== selectedFamilie?.value) {
                            setSelectedCategory(null)
                          }
                          setValue('categoria_final_id', e.value)
                          document
                            .getElementById('categoria_final_id')
                            .removeAttribute('style')
                          getCategoryFinal(e.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className="tw-flex tw-items-center tw-w-full tw-m-0 tw-p-0">
                    <div className="tw-w-2/5">
                      <Label label="Altura" size="base" />
                    </div>
                    <div className="tw-w-1/5 tw-relative">
                      <input
                        className="tw-outline-none tw-h-10 tw-flex-1 tw-px-2 tw-bg-transparent tw-w-full tw-border-2 tw-bg-white "
                        name="altura"
                        id="altura"
                        {...register('altura', {
                          onChange: (e) => {
                            e.target.removeAttribute('style')
                          },
                        })}
                        required
                        type="number"
                        min="0"
                        step=".01"
                      />
                      <span className="tw-absolute tw-top-1.5 tw-right-1 tw-bg-white">
                        cm
                      </span>
                    </div>
                  </div>
                  <div className="tw-flex tw-items-center tw-w-full tw-m-0 tw-p-0">
                    <div className="tw-w-2/5 tw-mx-4">
                      <Label label="subfamilia" size="base" />
                    </div>
                    <div className="tw-w-4/5 tw-mr-8 tw-my-2">
                      <Select
                        noOptionsMessage={() => t('labels.No hay opciones')}
                        placeholder="Seleccionar..."
                        styles={customStyles}
                        options={subFamilies}
                        value={
                          selectedSubFamilie
                            ? {
                                ...selectedSubFamilie,
                              }
                            : null
                        }
                        isLoading={subFamilies.length === 0}
                        className="create-product tw-outline-none tw-h-10 tw-flex-1 tw-px-1 tw-bg-transparent tw-w-full tw-border-2 tw-bg-white"
                        name="subcategoria_id"
                        id="subcategoria_id"
                        onChange={(e) => {
                          setSelectedSubFamilie(e)
                          if (e.value !== selectedCategory?.value) {
                            setSelectedCategory(null)
                          }
                          getCategory(e.value)
                          setValue('subcategoria_id', e.value)
                          document
                            .getElementById('subcategoria_id')
                            .removeAttribute('style')
                        }}
                      />
                    </div>
                  </div>
                  <div className="tw-flex tw-items-center tw-w-full tw-m-0 tw-p-0">
                    <div className="tw-w-2/5">
                      <Label label="Profundidad" size="base" />
                    </div>
                    <div className="tw-w-1/5 tw-relative">
                      <input
                        name="profundo"
                        id="profundo"
                        {...register('profundo', {
                          onChange: (e) => e.target.removeAttribute('style'),
                        })}
                        required
                        type="number"
                        className="tw-outline-none tw-h-10 tw-flex-1 tw-px-2 tw-bg-transparent tw-w-full tw-border-2 tw-bg-white"
                        min="0"
                        step=".01"
                      />
                      <span className="tw-absolute tw-top-1.5 tw-right-1 tw-bg-white">
                        cm
                      </span>
                    </div>
                  </div>
                  <div className="tw-flex tw-items-center tw-w-full tw-m-0 tw-p-0">
                    <div className="tw-w-2/5 tw-mx-4">
                      <Label label="Categoría" size="base" />
                    </div>
                    <div className="tw-w-4/5 tw-mr-8 tw-my-2">
                      <Select
                        noOptionsMessage={() => t('labels.No hay opciones')}
                        placeholder="Seleccionar..."
                        styles={customStyles}
                        options={categories}
                        loading={categories.length === 0}
                        value={
                          selectedCategory
                            ? {
                                ...selectedCategory,
                              }
                            : null
                        }
                        className="create-product tw-outline-none tw-h-10 tw-flex-1 tw-px-1 tw-bg-transparent tw-w-full tw-border-2 tw-bg-white"
                        name="categoria_id"
                        id="categoria_id"
                        onChange={(e) => {
                          setSelectedCategory(e)
                          setValue('categoria_id', e.value)
                          document
                            .getElementById('categoria_id')
                            .removeAttribute('style')
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {Object.keys(errors).length > 0 && (
              <div
                className="tw-float-right tw-mr-4"
                style={{ color: '#B10000' }}
              >
                <Paragraphs weight={'bold'}>
                  {t('messages.*Los campos marcados en rojo son obligatorios')}
                </Paragraphs>
              </div>
            )}
          </form>
        </Modal>
      )}
      {fullScreenImage && uploadedImage && (
        <div className="tw-relative tw-h-full tw-w-full">
          <ImageModalFullScreen>
            <div className="tw-flex tw-justify-center tw-items-center tw-w-full tw-h-full">
              {/* close buttom */}
              <span
                className="material-icons tw-cursor-pointer tw-text-white tw-text-2xl tw-absolute tw-top-10 tw-right-10"
                style={{ fontSize: '2rem' }}
                onClick={() => setFullScreenImage(false)}
              >
                close
              </span>
              <img src={URL.createObjectURL(uploadedImage)} alt="image" />
            </div>
          </ImageModalFullScreen>
        </div>
      )}
      {fullScreenImage && !uploadedImage && data && data.imagen ? (
        <ImageModalFullScreen>
          <div className="tw-flex tw-justify-center tw-items-center tw-w-full tw-h-full">
            {/* close buttom */}
            <span
              className="material-icons tw-cursor-pointer tw-text-white tw-text-2xl tw-absolute tw-top-10 tw-right-10"
              style={{ fontSize: '2rem' }}
              onClick={() => setFullScreenImage(false)}
            >
              close
            </span>
            <img src={data.imagen} alt="image" />
          </div>
        </ImageModalFullScreen>
      ) : null}
      {openCropModal && uploadedImage && (
        <ModalCropImages
          onToggle={() => setOpenCropModal(false)}
          uploadedImage={uploadedImage}
          handleUploadImage={handleUploadImage}
        />
      )}
    </Fragment>
  )
}

export default ModalItem
