import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import Logo from '../../components/Logo'
import ModalItem from '../../components/Modals/ModalItem/ModalItem'
import NavBar from '../../components/ProductManeger/NavBar'
import { SidebarFilters } from './components/Sidebar/SidebarFilters'
import {
  CATEGORIES_TREE,
  GENERATEQRCODE_UPLOAD_PRODUCT_IMAGE,
  PLANOGRAMA_ARTICULOS,
  PLANOGRAMA_CREATE_PRODUCT,
  PLANOGRAMA_UPDATE_PRODUCT,
  PLANOGRAMA_DELETE_PRODUCT,
} from '../../graphQL/admin'
import ProductGrid from './components/ProductGrid/ProductGrid'
import { convertToBase64, resizeFile } from '../../hooks/util'
import Paragraphs from '../../../../components/commons/Paragraphs/Paragraphs'
import ContextMenu from '../../components/ContextMenu'
import { handleCommonErrors } from '../../hooks/userManagement/utils/utils'
import axios from 'axios'
const b64toBlob = require('b64-to-blob')

const ProductManager = ({ rolUser }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState([])
  const [productsCopy, setProductsCopy] = useState([])
  const [sidebar, setSidebar] = useState(null)
  const [search, setSearch] = useState('')
  const [filters, setFilters] = useState({})
  const [data, setData] = useState(null)
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [paginator, setPaginator] = useState()
  const [showMore, setShowMore] = useState(false)
  const [uploadedImage, setUploadedImage] = useState(null)
  const [qrCode, setQrCode] = useState(null)
  const [loadMoreSidebar, setLoadMoreSidebar] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [reset, setReset] = useState(false)
  const [selectedFamilies, setSelectedFamilies] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedLaboratories, setSelectedLaboratories] = useState([])
  const [contextMenu, setContextMenu] = useState(null)
  const [cancelSignal, setCancelSignal] = useState(null)
  const [downloadingQrImage, setDownloadingQrImage] = useState(false)

  // Mutations
  const [getData] = useMutation(PLANOGRAMA_ARTICULOS)
  const [generateQrImageUpload] = useMutation(
    GENERATEQRCODE_UPLOAD_PRODUCT_IMAGE
  )
  const [createProduct] = useMutation(PLANOGRAMA_CREATE_PRODUCT)
  const [updateProduct] = useMutation(PLANOGRAMA_UPDATE_PRODUCT)
  const [getCategories] = useMutation(CATEGORIES_TREE)
  const [deleteProduct] = useMutation(PLANOGRAMA_DELETE_PRODUCT)

  const onDoubleClickProduct = (articulo) => {
    setOpenCreateModal(true)
    articulo && setData(articulo)
    contextMenu && setData(contextMenu)
  }

  const openContextMenu = (e, articulo) => {
    if (!e) return

    //check if right click
    if (e.button === 2) {
      setContextMenu({
        left: e.clientX,
        top: e.clientY,
        ...articulo,
      })
    } else return
  }

  const openContextMenuSelection = () => {
    if (contextMenu) {
      return true
    }
    return false
  }

  const onDeleteProduct = async (e) => {
    if (!e) return
    const { data } = await deleteProduct({
      variables: {
        id: contextMenu.id,
      },
    })

    if (data) {
      const { deleteProduct } = data
      if (deleteProduct) {
        setProducts(products.filter((p) => p.id !== deleteProduct.id))
        setProductsCopy(productsCopy.filter((p) => p.id !== deleteProduct.id))
        return setContextMenu(null)
      }
    } else {
      return toast.error(
        t('errors.No se ha podido borrar el producto, intentelo más tarde'),
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        }
      )
    }
  }

  let calling = false
  const onScrollList = async (e) => {
    //wheel event, check if scrolling up return
    if (e.deltaY < 0) return
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
    if (scrollTop + clientHeight + 100 >= scrollHeight) {
      if (paginator && products.length < paginator.total) {
        setLoadingMore(true)
        if (!calling) {
          calling = true
          await getProductsSearch(25, paginator.currentPage + 1, null, true)
          calling = false
        }
        setReset(false)
      }
    }
  }

  const onCreateProduct = async (values) => {
    let image = null
    if (uploadedImage) {
      image = await convertToBase64(uploadedImage)
    }

    let input = {
      ...values,
      altura: values.altura ? parseFloat(values.altura) : null,
      anchura: values.anchura ? parseFloat(values.anchura) : null,
      profundo: values.profundo ? parseFloat(values.profundo) : null,
      ean: values.ean ? String(values.ean) : null,
      is_fedefarma: rolUser ? true : false,
      giff: values.giff ? String(values.giff) : null,
    }

    if (values.update) {
      const response = await onUpdateProduct(values)
      return response
    }

    const response = await createProduct({
      variables: {
        input: { ...input, imagen: image },
      },
    })
    if (response.data) {
      const { planogramaArticuloCreate } = response.data
      if (planogramaArticuloCreate) {
        let temp = [...products]

        //add new product to the beginning of the list
        temp.unshift({
          ...planogramaArticuloCreate,
          laboratorio: planogramaArticuloCreate.laboratorio
            ? planogramaArticuloCreate.laboratorio.nombre
            : null,
          categoria: planogramaArticuloCreate.categoria
            ? planogramaArticuloCreate.categoria.nombre
            : null,
          categoria_final: planogramaArticuloCreate.categoria_final
            ? planogramaArticuloCreate.categoria_final.nombre
            : null,
          laboratorio_id: planogramaArticuloCreate.laboratorio
            ? planogramaArticuloCreate.laboratorio.id
            : null,
          categoria_id: planogramaArticuloCreate.categoria
            ? planogramaArticuloCreate.categoria.id
            : null,
          categoria_final_id: planogramaArticuloCreate.categoria_final
            ? planogramaArticuloCreate.categoria_final.id
            : null,
          subcategoria_nombre: planogramaArticuloCreate.subcategoria
            ? planogramaArticuloCreate.subcategoria.nombre
            : null,
        })

        setProducts(temp)
        onCloseModal()
        return { success: true }
      }
    }
    if (response.errors) {
      const errors = handleCommonErrors(response.errors)
      if (errors) {
        let errorObjects = {}
        errors.forEach((error) => {
          Object.keys(error)?.forEach((key) => {
            errorObjects[key] = error[key]
          })
        })
        return { success: false, errors: errorObjects }
      } else {
        toast.error(
          t('errors.No se ha podido guardar el producto, intentelo más tarde'),
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          }
        )
      }
    }
  }

  const onUpdateProduct = async (values) => {
    delete values.update
    let image = undefined
    if (uploadedImage) {
      image = await convertToBase64(uploadedImage)
    }
    return await updateProduct({
      variables: {
        id: data.id,
        input: {
          ...values,
          imagen: image,
          altura: values.altura ? parseFloat(values.altura) : null,
          anchura: values.anchura ? parseFloat(values.anchura) : null,
          profundo: values.profundo ? parseFloat(values.profundo) : null,
          ean: values.ean ? String(values.ean) : null,
          ...(values.giff ? { giff: String(values.giff) } : {}),
        },
      },
    }).then((response) => {
      if (response.data) {
        const { planogramaActualizarDatosArticuloBackoffice } = response.data
        if (planogramaActualizarDatosArticuloBackoffice) {
          setProducts(
            products.map((product) => {
              if (product.id === data.id) {
                return {
                  ...planogramaActualizarDatosArticuloBackoffice,
                  laboratorio:
                    planogramaActualizarDatosArticuloBackoffice.laboratorio
                      ? planogramaActualizarDatosArticuloBackoffice.laboratorio
                          .nombre
                      : null,
                  categoria:
                    planogramaActualizarDatosArticuloBackoffice.categoria
                      ? planogramaActualizarDatosArticuloBackoffice.categoria
                          .nombre
                      : null,
                  categoria_final:
                    planogramaActualizarDatosArticuloBackoffice.categoria_final
                      ? planogramaActualizarDatosArticuloBackoffice
                          .categoria_final.nombre
                      : null,
                  laboratorio_id:
                    planogramaActualizarDatosArticuloBackoffice.laboratorio
                      ? planogramaActualizarDatosArticuloBackoffice.laboratorio
                          .id
                      : null,
                  categoria_id:
                    planogramaActualizarDatosArticuloBackoffice.categoria
                      ? planogramaActualizarDatosArticuloBackoffice.categoria.id
                      : null,
                  categoria_final_id:
                    planogramaActualizarDatosArticuloBackoffice.categoria_final
                      ? planogramaActualizarDatosArticuloBackoffice
                          .categoria_final.id
                      : null,
                  subcategoria_nombre:
                    planogramaActualizarDatosArticuloBackoffice.subcategoria
                      ? planogramaActualizarDatosArticuloBackoffice.subcategoria
                          .nombre
                      : null,
                }
              }
              return product
            })
          )
          setProductsCopy(
            productsCopy.map((product) => {
              if (product.id === data.id) {
                return {
                  ...planogramaActualizarDatosArticuloBackoffice,
                  laboratorio:
                    planogramaActualizarDatosArticuloBackoffice.laboratorio
                      ? planogramaActualizarDatosArticuloBackoffice.laboratorio
                          .nombre
                      : null,
                  categoria:
                    planogramaActualizarDatosArticuloBackoffice.categoria
                      ? planogramaActualizarDatosArticuloBackoffice.categoria
                          .nombre
                      : null,
                  categoria_final:
                    planogramaActualizarDatosArticuloBackoffice.categoria_final
                      ? planogramaActualizarDatosArticuloBackoffice
                          .categoria_final.nombre
                      : null,
                  laboratorio_id:
                    planogramaActualizarDatosArticuloBackoffice.laboratorio
                      ? planogramaActualizarDatosArticuloBackoffice.laboratorio
                          .id
                      : null,
                  categoria_id:
                    planogramaActualizarDatosArticuloBackoffice.categoria
                      ? planogramaActualizarDatosArticuloBackoffice.categoria.id
                      : null,
                  categoria_final_id:
                    planogramaActualizarDatosArticuloBackoffice.categoria_final
                      ? planogramaActualizarDatosArticuloBackoffice
                          .categoria_final.id
                      : null,
                  subcategoria_nombre:
                    planogramaActualizarDatosArticuloBackoffice.subcategoria
                      ? planogramaActualizarDatosArticuloBackoffice.subcategoria
                          .nombre
                      : null,
                }
              }
              return product
            })
          )

          return onCloseModal()
        }
        return { success: true }
      }
      if (response.errors) {
        const errors = handleCommonErrors(response.errors)
        if (errors) {
          let errorObjects = {}
          errors.forEach((error) => {
            Object.keys(error)?.forEach((key) => {
              errorObjects[key] = error[key]
            })
          })
          return { success: false, errors: errorObjects }
        } else {
          toast.error(
            t(
              'errors.No se ha podido guardar el producto, intentelo más tarde'
            ),
            {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            }
          )
        }

        return { success: false }
      }
    })
  }

  const getProductsSearch = async (
    first = 25,
    page,
    reset = null,
    scrolling = false
  ) => {
    const { data } = await getData({
      variables: {
        first,
        page,
        families_id: !reset
          ? selectedFamilies
              .filter((fam) => fam.checked)
              .map((fam) => fam.value)
          : [],
        subfamilies_id: !reset
          ? selectedCategories
              .filter((cat) => cat.checked)
              .map((cat) => cat.value)
          : [],
        laboratories_id: !reset
          ? selectedLaboratories
              .filter((lab) => lab.checked)
              .map((lab) => lab.value)
          : [],

        ...(typeof filters?.is_fedefarma === 'boolean' &&
          !reset && {
            is_fedefarma: filters.is_fedefarma === true ? true : null,
          }),

        ...(search &&
          reset && {
            filters: { ...search },
          }),

        ...(filters?.is_my_pharmacy &&
          !reset && {
            is_my_pharmacy: filters?.is_my_pharmacy,
          }),
      },
    })

    if (data) {
      const { planogramaArticulosSearch } = data
      const productsData = planogramaArticulosSearch.data
      const paginatorInfo = planogramaArticulosSearch.paginatorInfo
      const sidebarFromBack = planogramaArticulosSearch.sidebar

      if (!sidebar || (reset && sidebar)) {
        setSidebar(sidebarFromBack)
      }

      if (scrolling) {
        setProducts([
          ...products,
          ...productsData.map((product) => ({
            ...product,
            laboratorio: product.laboratorio
              ? product.laboratorio.nombre
              : null,
            categoria: product.categoria ? product.categoria.nombre : null,
            categoria_final: product.categoria_final
              ? product.categoria_final.nombre
              : null,
            laboratorio_id: product.laboratorio ? product.laboratorio.id : null,
            categoria_id: product.categoria ? product.categoria.id : null,
            categoria_final_id: product.categoria_final
              ? product.categoria_final.id
              : null,
            subcategoria_nombre: product.subcategoria
              ? product.subcategoria.nombre
              : null,
          })),
        ])
        setProductsCopy([
          ...productsCopy,
          ...productsData.map((product) => ({
            ...product,
            laboratorio: product.laboratorio
              ? product.laboratorio.nombre
              : null,
            categoria: product.categoria ? product.categoria.nombre : null,
            categoria_final: product.categoria_final
              ? product.categoria_final.nombre
              : null,
            laboratorio_id: product.laboratorio ? product.laboratorio.id : null,
            categoria_id: product.categoria ? product.categoria.id : null,
            categoria_final_id: product.categoria_final
              ? product.categoria_final.id
              : null,
            subcategoria_nombre: product.subcategoria
              ? product.subcategoria.nombre
              : null,
          })),
        ])
      } else {
        setProducts(
          productsData.map((product) => ({
            ...product,
            laboratorio: product.laboratorio
              ? product.laboratorio.nombre
              : null,
            categoria: product.categoria ? product.categoria.nombre : null,
            categoria_final: product.categoria_final
              ? product.categoria_final.nombre
              : null,
            laboratorio_id: product.laboratorio ? product.laboratorio.id : null,
            categoria_id: product.categoria ? product.categoria.id : null,
            categoria_final_id: product.categoria_final
              ? product.categoria_final.id
              : null,
            subcategoria_nombre: product.subcategoria
              ? product.subcategoria.nombre
              : null,
          }))
        )
        setProductsCopy(
          productsData.map((product) => ({
            ...product,
            laboratorio: product.laboratorio
              ? product.laboratorio.nombre
              : null,
            categoria: product.categoria ? product.categoria.nombre : null,
            categoria_final: product.categoria_final
              ? product.categoria_final.nombre
              : null,
            laboratorio_id: product.laboratorio ? product.laboratorio.id : null,
            categoria_id: product.categoria ? product.categoria.id : null,
            categoria_final_id: product.categoria_final
              ? product.categoria_final.id
              : null,
            subcategoria_nombre: product.subcategoria
              ? product.subcategoria.nombre
              : null,
          }))
        )
      }
      setPaginator(paginatorInfo)
    }
    setLoading(false)
    setLoadingMore(false)
  }

  let timeout = null

  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      getProductsSearch(25, 1, true)
    }, 500)

    return () => {
      clearTimeout(timeout)
    }
  }, [search])

  const onProductFilterChange = (e) => {
    if (!e?.target) return
    setLoadingMore(true)

    const { name, value } = e.target
    let searched = { ...search, [name]: value }

    if (Object.keys(searched).length === 0) {
      delete searched[name]
    }

    setSearch(searched)
  }

  const handleUploadImage = async (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = (e) => {
      const img = new Image()
      img.src = e.target.result
    }
    reader.readAsDataURL(file)
    setUploadedImage(file)
  }

  const onCloseModal = () => {
    setOpenCreateModal(!openCreateModal)
    setUploadedImage(null)
    setQrCode(null)
    setData(null)
  }

  useEffect(() => {
    if (qrCode) {
      getImageFromQrCode(qrCode)
    }
  }, [qrCode])

  let counter = 0
  const getImageFromQrCode = async (qrCode) => {
    let signal = cancelSignal.signal
    counter = counter + 1
    await fetch(
      process.env.REACT_APP_URL_ENVIRONMENT +
        '/products/upload-image-check?signature=' +
        qrCode.signature,
      { signal }
    )
      .then((response) => {
        if (response.status === 200) {
          setDownloadingQrImage(true)
          return response.json()
        } else {
          return null
        }
      })
      .then((data) => {
        if (!data && counter < 30) {
          return setTimeout(() => {
            getImageFromQrCode(qrCode)
          }, 5000)
        } else if (counter >= 30) {
          setDownloadingQrImage(false)
          setQrCode(null)
          return toast.error(
            t(
              'errors.No se ha podido recuperar la imagen del codigo qr, intentelo más tarde'
            ),
            {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            }
          )
        }

        if (data && data.image) {
          let image = b64toBlob(data.image, 'image/jpeg')
          let file = new File([image], 'image/jpeg', {
            type: 'image/jpeg',
          })
          resizeFile(file, 800, 800).then((resizedFile) => {
            setUploadedImage(resizedFile)
            setDownloadingQrImage(false)
          })
        }
      })
  }

  const donwloadImageFromUrl = async (url) => {
    if (!url) return
    try {
      let newUrl = url
      if (url.includes('planograma_bucket')) {
        const [domain, _, api, bucket, ...rest] = url.split('/')
        newUrl = `${domain}//${bucket}.${api}/${rest.join('/')}`
      }
      const response = await fetch(newUrl)
      const blob = await response.blob()
      const file = new File([blob], 'image.jpeg', {
        type: 'image/jpeg',
      })
      resizeFile(file, 800, 800).then((resizedFile) => {
        setUploadedImage(resizedFile)
      })
    } catch (e) {
      toast.error(t('errors.No se ha podido recuperar la imagen'), {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      })
    }
  }

  const generateQrCode = async () => {
    if (cancelSignal) {
      cancelSignal.abort()
      setQrCode(null)
    }
    setLoading(true)
    setCancelSignal(new AbortController())
    if (qrCode) return
    generateQrImageUpload()
      .then((data) => {
        return setQrCode(data.data.createProductQR)
      })
      .catch((_) => {
        toast.error(
          t(
            'errors.No se ha podido recuperar el código qr, intentelo más tarde'
          ),
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            progress: undefined,
          }
        )
      })
      .finally(() => {
        return setLoading(false)
      })
    setLoading(false)
  }

  const filterProducts = async () => {
    setLoading(true)
    getProductsSearch(25, 1, false, false)
    setReset(true)
  }

  const resetFilters = () => {
    setFilters({})
    setShowMore(false)
    setSearch('')
    setLoading(true)
    setSelectedCategories([])
    setSelectedFamilies([])
    setSelectedLaboratories([])
    getProductsSearch(25, 1, true)
    document.querySelectorAll('input[type="search"]').forEach((input) => {
      input.value = ''
    })
    document.querySelectorAll('input[type="checkbox"]').forEach((input) => {
      input.checked = false
    })
  }

  return (
    <div
      className="tw-flex tw-flex-col tw-w-full tw-h-full tw-relative"
      style={{ minHeight: '94vh' }}
    >
      {Boolean(loading) && (
        <div
          className="tw-absolute tw-flex tw-flex-col tw-items-center tw-justify-center tw-top-0 tw-left-0 tw-bottom-100 tw-bg-opacity-70 tw-bg-white tw-z-20 tw-h-full tw-w-full"
          style={{ height: '130%' }}
        >
          <Logo width={100} />
          <Paragraphs>{t('labels.Cargando')}...</Paragraphs>
        </div>
      )}

      <NavBar openCreateModal={() => setOpenCreateModal(!openCreateModal)} />

      <div className="md:tw-h-full md:tw-grid md:tw-grid-cols-6">
        <section className="sidebar tw-relative md:tw-col-span-1 tw-px-4 tw-mt-4 tw-border-r-2 tw-mr-2">
          <SidebarFilters
            t={t}
            setShowMore={setShowMore}
            sidebar={sidebar}
            setFilters={setFilters}
            filters={filters}
            filterProducts={filterProducts}
            showMore={showMore}
            setLoadMoreSidebar={setLoadMoreSidebar}
            loadMoreSidebar={loadMoreSidebar}
            resetFilters={resetFilters}
            getCategories={getCategories}
            reset={reset}
            getData={getData}
            selectedFamilies={selectedFamilies}
            setSelectedFamilies={setSelectedFamilies}
            setSelectedCategories={setSelectedCategories}
            selectedCategories={selectedCategories}
            setSelectedLaboratories={setSelectedLaboratories}
            selectedLaboratories={selectedLaboratories}
          />
        </section>

        <main
          className="tw-main md:tw-col-span-5 tw-flex tw-flex-wrap tw-relative tw-px-8 tw-pb-8 tw-overflow-y-auto tw-relative tw-w-full tw-h-full"
          onWheel={(e) => {
            onScrollList(e)
          }}
        >
          <ProductGrid
            products={products}
            t={t}
            onDoubleClickProduct={onDoubleClickProduct}
            onProductFilterChange={onProductFilterChange}
            onRightClickProduct={openContextMenu}
            loadingMore={loadingMore}
            search={search}
            setSearch={setSearch}
            rolUser={rolUser}
          />
        </main>
      </div>

      {Boolean(openCreateModal) && (
        <ModalItem
          id={data?.id}
          onToggle={() => onCloseModal()}
          onCreateProduct={onCreateProduct}
          handleUploadImage={handleUploadImage}
          uploadedImage={uploadedImage}
          generateQrCode={generateQrCode}
          qrCode={qrCode}
          isRetailer={rolUser}
          setQrCode={setQrCode}
          setProducts={setProducts}
          products={products}
          donwloadImageFromUrl={donwloadImageFromUrl}
          downloadingQrImage={downloadingQrImage}
        />
      )}

      {openContextMenuSelection() && (
        <ContextMenu
          setContextMenu={setContextMenu}
          onDelete={onDeleteProduct}
          left={contextMenu?.left || 100}
          top={contextMenu?.top || 100}
          deleteDisabled={!contextMenu?.is_fedefarma}
          onEdit={onDoubleClickProduct}
        />
      )}
    </div>
  )
}

export default ProductManager
