import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs/Paragraphs'
import { useBtn } from './hooks/useBtn'
import { Container } from './styles'
import Spinner from '../Spinner/Spinner'

const Button = ({
  size,
  label,
  shortcut,
  rounded,
  loading,
  type,
  uppercase,
  iconLeft,
  iconRight,
  onClick,
  disabled,
  backgroundColor,
  mode,
  styles,
  textColor,
  form,
  labelLeft,
  customParams,
}) => {
  const { classFromMode } = useBtn(mode, rounded, uppercase, customParams)

  return (
    <Container
      form={form}
      backgroundColor={backgroundColor}
      textColor={textColor}
      size={size}
      styles={{ ...styles }}
      className={`Button ${classFromMode(disabled)}`}
      disabled={disabled || loading}
      onClick={onClick}
      type={type}
    >
      {loading ? (
        <Spinner />
      ) : (
        <div
          className={`tw-flex tw-w-full tw-items-center ${
            shortcut || iconRight ? 'tw-justify-between' : 'tw-justify-center'
          }`}
        >
          {iconLeft && <div className="tw-flex">{iconLeft}</div>}
          <div>
            <Paragraphs
              className={`label ${labelLeft && 'tw-leading-3 tw-text-left'}`}
              weight="bold"
              size="xs"
            >
              {label}
            </Paragraphs>
          </div>
          <div className="tw-ml-2 tw-opacity-70">
            <Paragraphs size="xxs">{shortcut}</Paragraphs>
          </div>
          {iconRight && <div className="tw-flex">{iconRight}</div>}
        </div>
      )}
    </Container>
  )
}
Button.propTypes = {
  form: PropTypes.string,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(['submit', 'button']),
  size: PropTypes.oneOf(['mini', 'small', 'medium', 'full']),
  mode: PropTypes.oneOf([
    'primary',
    'secondary',
    'transparent',
    'expansible',
    'custom',
  ]),
  label: PropTypes.string.isRequired,
  shortcut: PropTypes.string,
  icon: PropTypes.node,
  rounded: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  uppercase: PropTypes.bool,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  iconLeft: PropTypes.object,
  iconRight: PropTypes.object,
  styles: PropTypes.object,
  labelLeft: PropTypes.bool,
  customParams: PropTypes.object,
}

Button.defaultProps = {
  mode: 'primary',
  rounded: false,
  disabled: false,
  size: 'medium',
  onClick: null,
  icon: null,
  uppercase: false,
  backgroundColor: null,
  textColor: null,
  loading: false,
  type: 'button',
  form: null,
  labelLeft: false,
}

export default Button
