import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { columns } from '../../aggrid/columns'
import Aggrid from '../../../../infrastructure/components/Aggrid/Aggrid'
import { formatFilters } from '../../hooks/util'

const UserManagement = (props) => {
  const {
    userRol,
    setGridApi,
    getUsersList,
    handleEditOrCreateUser,
    setOpenConfirmDelete,
    onRowDoubleClicked,
    totalRows,
  } = props

  const onGridReady = async (params) => {
    setGridApi(params.api)
    const updateData = () => {
      const datasource = {
        async getRows(rowParams) {
          const { filterModel } = rowParams.request
          let formattedFilters = null

          if (Object.keys(filterModel).length > 0) {
            formattedFilters = formatFilters(filterModel)
          }

          let orderBy = null
          if (rowParams.request?.sortModel?.length > 0) {
            orderBy = rowParams.request.sortModel.map((sort) => {
              return {
                column: sort.colId,
                order: sort.sort.toUpperCase(),
              }
            })
          }

          let page = rowParams.request.startRow / 50 + 1
          await getUsersList(rowParams, page, 50, formattedFilters, orderBy)
        },
      }
      params.api.setServerSideDatasource(datasource)
    }

    updateData(params.api)
  }

  const actionContextMenu = (action, row) => {
    if (!row) return null

    const { node } = row
    if (node !== null) {
      const { data } = node

      if (!data?.id) {
        return null
      }

      switch (action) {
        case 'DELETE':
          return setOpenConfirmDelete(data)

        case 'EDIT':
          return handleEditOrCreateUser(data)

        default: {
          return null
        }
      }
    }
  }

  const getContextMenu = (row) => {
    const items = []
    if (userRol === 'ADMIN') {
      items.push({
        id: 2,
        name: 'Editar',
        action: () => actionContextMenu('EDIT', row),
      })
    }
    if (userRol !== 'ADMIN' && row?.node?.data?.organizacion?.id) {
      items.push({
        id: 2,
        name: 'Editar',
        action: () => actionContextMenu('EDIT', row),
      })
    }
    if (userRol === 'ADMIN') {
      items.push({
        id: 1,
        name: 'Eliminar',
        action: () => actionContextMenu('DELETE', row),
        cssClasses: ['tw-text-alert'],
      })
    }
    if (userRol !== 'ADMIN' && row?.node?.data?.organizacion?.id) {
      items.push({
        id: 1,
        name: 'Eliminar',
        action: () => actionContextMenu('DELETE', row),
        cssClasses: ['tw-text-alert'],
      })
    }

    return items
  }

  return (
    <Fragment>
      <Aggrid
        id="table-user-management"
        columns={columns.users}
        onGridReady={onGridReady}
        rowModelType={'serverSide'}
        type="scroll"
        onRowDoubleClicked={onRowDoubleClicked}
        getContextMenuItems={(row) => getContextMenu(row)}
        cacheBlockSize={totalRows}
        paginationAutoPageSize={false}
        serverSideInfiniteScroll={true}
      />
    </Fragment>
  )
}

UserManagement.propTypes = {
  setGridApi: PropTypes.func,
  userRol: PropTypes.string,
  handleEditOrCreateUser: PropTypes.func,
  handleDeleteUser: PropTypes.func,
  setOpenConfirmDelete: PropTypes.func,
  getUsersList: PropTypes.func,
  onRowDoubleClicked: PropTypes.func,
  totalRows: PropTypes.number,
}

UserManagement.defaultProps = {
  setGridApi: () => {},
  userRol: '',
  handleEditOrCreateUser: () => {},
  handleDeleteUser: () => {},
  getUsersList: () => {},
  onRowDoubleClicked: () => {},
  setOpenConfirmDelete: () => {},
  totalRows: 0,
}

export default UserManagement
