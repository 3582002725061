import { gql } from '@apollo/client'

export const ADMIN = gql`
  query admin($id: ID!) {
    admin(id: $id) {
      id
      nombre
      email
      perfiles {
        id
        perfil
      }
    }
  }
`

export const ME = gql`
  {
    me {
      id
      nombre
      email
      farmacloud_token
      organizacion {
        id
        nombre
      }
    }
  }
`
