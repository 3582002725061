/**
 * Crear respuesta de success segun backoffice o frontoffice
 * @author   ehernandez
 * @return   {{success: Boolean}}
 */
export const createResponseOk = (name, data, rolUser) => {
  if (name && data) {
    const func = `${name}${rolUser ? 'Backoffice' : ''}`
    return {
      success: true,
      response: data[func],
    }
  }
  return {
    success: true,
  }
}

export const createResponseFail = () => ({
  success: false,
})
