import React from 'react'
import PropTypes from 'prop-types'
import { useEffect } from 'react'

/**
 *
 * @param {*} val
 * @returns
 */
const selector = (val) => {
  if (val) return 'icon-ic_info_linea_ok tw-text-success tw-text-lg'
  return 'icon-ic_info_linea_ko tw-text-alert tw-text-lg'
}

/**
 * allow params for ag-grid
 * @param {GridApi} api
 * @param {Object} colDef default value {cellRenderer: 'agArrowGroup', cellStyle: {…}, width: 30}
 * @param {Column} column default value {instanceId: 39, moving: false, menuVisible: false, filterActive: false, eventService: EventService, …}
 * @param {ColumnApi} columnApi default value {columnModel: ColumnModel}
 * @param {object} data default value {fecha: '19/1/2022', pdf_link: '', type: 0, fechorped: null, linreg: 1, …} data of row
 * @param {void} getValue default value ()
 * @param {RowNode} node default value {rowIndex: 0, key: null, childrenMapped: {…}, displayed: true, rowTop: 0, …}
 * @param {Number} rowIndex index of row
 * @param {void} setValue default value ()
 * @param {object} text returns custom object
 * @returns
 */
const AgCustomBooleanRenderer = ({ rowIndex, value, text, node }) => {
  if (text)
    return (
      <div id={`ag-custom-boolean-renderer-${rowIndex}`}>
        {value ? text.true : text.false}
      </div>
    )
  return (
    <span
      id={`ag-custom-boolean-renderer-${rowIndex}`}
      className={`${selector(value)} tw-cursor-pointer`}
    />
  )
}

AgCustomBooleanRenderer.propTypes = {
  rowIndex: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.object,
  ]),
  text: PropTypes.object,
}

export default AgCustomBooleanRenderer
