import Paragraphs from '../commons/Paragraphs/Paragraphs'
import { useTranslation } from 'react-i18next'
import Logo from '../Logo/Logo'

const LoadingOverlay = () => {
  const { t } = useTranslation()
  return (
    <div className="tw-flex tw-flex-col tw-fixed tw-top-0 tw-left-0 tw-bg-white tw-bg-opacity-80 tw-h-full tw-w-full tw-z-10 tw-items-center tw-justify-center">
      <div
        className="tw-items-center tw-justify-center tw-flex tw-flex-col tw-text-center"
        style={{
          width: '100%',
          textAlign: 'center',
        }}
      >
        <Logo width={100} />
        <Paragraphs>{t('labels.Cargando')}...</Paragraphs>
      </div>
    </div>
  )
}

export default LoadingOverlay
