import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import logo from '../../assets/images/logo_maintenance.svg'
const Maintenance = () => {
  const { t } = useTranslation()

  useEffect(() => {
    checkServerStatus()
  }, [])

  const checkServerStatus = () => {
    // Check server status
    fetch('https://dev-api-planograma.sertecfarma.com/graphql').then(
      (response) => {
        if (response.status === 200) {
          window.location.replace('/')
        }
      }
    )
  }

  return (
    <div className="maintenance tw-h-full tw-w-full tw-bg-white tw-absolute tw-top-0 tw-left-0 tw-bottom-0 tw-right-0">
      <div className="maintenance__content tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full">
        <div className="maintenance__logo tw-mb-4">
          <img
            src={logo}
            alt="logo"
            style={{
              minWidth: '200px',
            }}
          />
        </div>
        <div className="maintenance__title tw-text-2xl tw-font-bold tw-mb-2">
          {t('titles.Estamos actualizando la App de Planograma')}
        </div>
        <div className="maintenance__description tw-text-sm tw-mb-4">
          {t(
            'titles.En unos minutos volveremos a activar la App, Disculpa las molestias.'
          )}
        </div>
      </div>
    </div>
  )
}

export default Maintenance
