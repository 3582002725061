import styled from 'styled-components'
// eslint-disable-next-line
import colors from '../../../infrastructure/styles/colors'

const hasBackground = ({ backgroundColor }) => {
  if (backgroundColor) return backgroundColor
  else return null
}

export const BoxFix = styled.div`
  min-width: 35px;
  background-color: #ccc;
`

const InputContent = styled.div`
  /* height: ${(props) => (props.rows === null ? '35px' : 'auto')}; */
  border-bottom: ${(props) => props.error && '2px solid red'};
  position: relative;
  background-color: ${(props) => hasBackground(props)};
  border-radius: 1px;
`

const Textarea = styled.textarea`
  text-align: ${(props) => props.alignRight && 'right'};
  font-weight: ${(props) => props.bold && 'bold'};
  color: ${(props) =>
    props.color === 'success'
      ? `${colors.success}`
      : props.color === 'error'
      ? `${colors.alert}`
      : props.color === 'warning'
      ? `${colors.warning}`
      : ''};
  padding: 10px;
  opacity: ${(props) => props.disabled && '1'};
`
const Input = styled.input`
  font-family: Atkinson Hyperlegible Regular;
  font-size: ${(props) => (props.size === 'large' ? '16px' : '12px')};
  text-align: ${(props) => props.alignRight && 'right'};
  font-weight: ${(props) => props.bold && 'bold'};
  color: ${(props) =>
    props.color === 'success'
      ? `${colors.success}`
      : props.color === 'error'
      ? `${colors.alert}`
      : props.color === 'warning'
      ? `${colors.warning}`
      : props.color === 'white'
      ? 'white'
      : ''};
  opacity: ${(props) => props.disabled && '1'};
  &::-webkit-calendar-picker-indicator {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    margin: 0;
    padding: 0;
  }
`

const Error = styled.div`
  font-size: 10px;
  height: 14px;
`
export { InputContent, Input, Error, Textarea }
