import axios from 'axios'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actionUserLogout } from '../../redux/actions/user'

/**
 * Hook for create fetch api rest
 *
 *
 * @author   ehernandez
 * @param    {String}   endpoint  endpoint to call
 * @param    {Boolean}  auth      variable for config url base auth or core
 */
export const useFetch = (endpoint, auth) => {
  const [loading, setLoading] = useState(false)
  const { token } = useSelector((state) => state.userReducer)
  const dispatch = useDispatch()

  /**
   * Custom fetch mutation for axios
   *
   *
   * @author   ehernandez
   * @param    {Object} data
   * @param    {String}   data.method   method api
   * @param    {String}   data.data     info to send to body
   * @param    {String}   data.addToURL
   */
  const fetch = async ({ method = 'post', data, addToURL = '' }) => {
    setLoading(true)
    const URL_BASE = process.env.REACT_APP_URL_ENVIRONMENT
    const config = {
      method: method,
      url: URL_BASE + endpoint + addToURL,
    }

    if (data) {
      config.data = data
    }

    if (token) {
      config.headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      }
    }

    return await axios(config)
      .then((response) => {
        setLoading(false)
        if (response.status !== 200) {
          return {
            success: false,
            error: response.data,
          }
        }
        return {
          success: true,
          data: response.data.data,
        }
      })
      .catch((err) => {
        const res = err?.response
        if (res?.status === 401 || res?.status === 500) {
          dispatch(actionUserLogout())
        }
        setLoading(false)
        return {
          success: false,
          errors: err?.response?.data?.errors,
        }
      })
  }

  return [fetch, { loading }]
}
