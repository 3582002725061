import store from './getStore'

/**
 * get state from redux
 * @author ehernandez
 * @returns {string}
 */
export const getToken = () => {
  return store.getState().userReducer.token
}
