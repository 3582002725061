/* eslint-disable no-bitwise */
import { v4 as uuidv4 } from 'uuid'

/**
 * Change values soportes
 * @author   ehernandez
 * @param    {Object} soporte
 * @param    {Object} soporte.anchura
 * @param    {Object} soporte.altura
 * @param    {String} value
 */
export const cambiarEspesorSoportes = (soporte, value) => {
  //llopez Revisar si hay artiuclos para saber el ancho max del borde
  let alturaArt = 0
  if (soporte.articulos && soporte.articulos.length > 0) {
    soporte.articulos.forEach((a) => {
      if (a.y + a.altura > alturaArt) {
        alturaArt = a.y + a.altura
      }
    })
  }
  const nuevaAltura = soporte.altura - (Number(value) - soporte.espesor_soporte)
  if (nuevaAltura >= 0 && nuevaAltura >= alturaArt) {
    soporte.altura -= Number(value) - soporte.espesor_soporte
    soporte.espesor_soporte = Number(value)
  }
  return soporte
}

/**
 * Change values soportes
 * @author   ehernandez
 * @param    {Object} defaultValues
 *    @param    {Object} defaultValues.x
 *    @param    {Object} defaultValues.anchura
 *    @param    {Object} defaultValues.espesorDivision
 * @param    {Object} estante
 *    @param    {Object} estante.altura
 *    @param    {Object} estante.divisiones
 * @param    {Object} divisionADividir
 *    @param    {Object} divisionADividir.anchura
 *    @param    {Object} divisionADividir.x
 */
export const calcularYAnadirNuevoDivisionConSoporte = (
  defaultValues,
  estante,
  divisionADividir
) => {
  const anchura =
    (divisionADividir.anchura - defaultValues.espesor_division) / 2
  const x =
    Number(divisionADividir.x) +
    Number(divisionADividir.espesor_division) +
    Number(anchura)
  return {
    id: uuidv4(),
    altura: estante.altura,
    espesor_division: defaultValues.espesor_division,
    nombre: '',
    x: Number(x),
    anchura,
    soportes: [
      {
        id: uuidv4(),
        altura: estante.altura - 2,
        modo: 'estante',
        anchura,
        espesor_soporte: 2,
        profundo: 40,
        alineacion: 'izquierda',
        x:
          estante.divisiones.length <= 1
            ? 0
            : divisionADividir.x + divisionADividir.anchura / 2,
        y: estante.altura,
        separacion_gancho_x: null,
        separacion_gancho_y: null,
        inicio_gancho_x: null,
        inicio_gancho_y: null,
      },
    ],
  }
}

export const calularAnchoSoporteYCompararConSuDivision = (
  soporte,
  division
) => {
  if (soporte.anchura >= division.anchura) {
    return division.anchura
  }
  return soporte.anchura
}

export const idOf = (i) =>
  // eslint-disable-next-line no-bitwise
  (i >= 26 ? idOf(((i / 26) >> 0) - 1) : '') +
  'abcdefghijklmnopqrstuvwxyz'[i % 26 >> 0]

/**
 * rename and order by x divisiones
 * @author   ehernandez
 * @param    {Object} divisiones
 */
export const orderDivisionesForX = (divisiones) => {
  divisiones.sort((a, b) => (a.x > b.x ? 1 : -1))
  return divisiones.filter((i, index) => {
    i.nombre = `(${idOf(index).toUpperCase()})`
    return i
  })
}

/**
 * rename and order by y soportes
 * @author   ehernandez
 * @param    {Object} divisiones
 */
export const orderDivisionesForY = (soportes) => {
  soportes.sort((a, b) => (a.y > b.y ? 1 : -1))
  return soportes.filter((i, index) => {
    i.nombre = `(${idOf(index).toUpperCase()})`
    return i
  })
}

/**
 * revisa si tenemos articulos en un soporte y los elimina
 * @author   ehernandez
 * @param    {String} key anchura / altura
 * @param    {Number} value
 * @param    {Object} articulos
 *    @param    {Number} articulos.x
 *    @param    {Number} articulos.altura
 *    @param    {Number} articulos.anchura
 */
export const actualzarYEliminarArticulosSoporte = (key, value, articulos) => {
  const keyPosition = key === 'altura' ? 'y' : 'x'
  return articulos.filter(
    (item) => item[keyPosition] + item[key] < Number(value)
  )
}

/**
 * Revisa si un soporte no es mayor que cero
 * @author   cquerecouto
 * @param    {Number} divisionLimit
 * @param    {Number} soporteSize
 */
const soporteLimits = (divisionLimit, soporteSize) => {
  if (divisionLimit <= soporteSize) return divisionLimit
  if (soporteSize < 0) return 0

  return Number(soporteSize)
}

/**
 * revisa si tenemos articulos en un soporte y los elimina
 * @author   ehernandez
 * @param    {Object} soporte
 *    @param    {Object} soporte.articulos
 *    @param    {Number} soporte.anchura
 * @param    {Number} anchura anchura de la division que contiene el soporte
 * @param    {Number} value
 */
export const cambiarAnchoDeSoporte = (soporte, anchura, value) => {
  soporte.anchura = soporteLimits(anchura, value)
  // soporte.articulos = soporte.articulos
  //   ? actualzarYEliminarArticulosSoporte(
  //       'anchura',
  //       soporte.anchura,
  //       soporte?.articulos,
  //     )
  //   : soporte.articulos
  return soporte
}

/**
 * Eliminar articulos del soporte masivamente
 * @author   ehernandez
 * @param    {Object} soporte
 *    @param    {Number} soporte.anchura
 *    @param    {Number} soporte.altura
 * @param    {Object} articulo
 *    @param    {Number} articulo.x
 *    @param    {Number} articulo.y
 */
export const eliminarMasivamenteArticulosSoporte = (soporte, articulos) => {
  const articulosEliminados = []

  const arreglo = articulos?.filter((articulo) => {
    if (
      articulo.x + articulo.anchura > soporte.anchura ||
      articulo.y + articulo.anchura > soporte.altura
    ) {
      articulosEliminados.push(articulo.id)
      return null
    }
    return articulo
  })

  return {
    articulos: arreglo,
    eliminados: articulosEliminados,
  }
}

/**
 * Rotar producto
 * @author   ehernandez
 * @param    {soporte} soporte
 *    @param    {Number} soporte.anchura
 *    @param    {Number} soporte.altura
 * @param    {Object} articulo
 *    @param    {Number} articulo.width
 *    @param    {Number} articulo.height
 *    @param    {Number} articulo.x
 *    @param    {Number} articulo.y
 * @param    {Number} rotate 0 / 90 / -90
 */
export const rotateProduct = (soporte, articulo, rotate) => {
  if (articulo.rotacion === rotate) return articulo

  if (
    (articulo.rotacion === -90 && rotate === 90) ||
    (articulo.rotacion === 90 && rotate === -90)
  )
    articulo.rotacion = 0
  else articulo.rotacion = rotate

  const { altura } = articulo
  const { anchura } = articulo

  articulo.altura = anchura
  articulo.anchura = altura

  return articulo
}

export const orderByY = (data) => data.sort((a, b) => (a.y > b.y ? 1 : -1))

export const orderByX = (data) => data.sort((a, b) => (a.x > b.x ? 1 : -1))

export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

/**
 * Resize images to desired width and height
 * @author 3rChuss
 * @param {File} file
 * @param {Number} maxWidth
 * @param {Number} maxHeight
 * @returns Promise
 */
export const resizeFile = (file, maxWidth, maxHeight) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (event) => {
      const img = new Image()
      img.src = event.target.result
      img.onload = () => {
        const elem = document.createElement('canvas')
        const scaleFactor = Math.min(
          maxWidth / img.width,
          maxHeight / img.height
        )
        elem.width = img.width * scaleFactor
        elem.height = img.height * scaleFactor
        const ctx = elem.getContext('2d')
        ctx.drawImage(img, 0, 0, elem.width, elem.height)
        ctx.canvas.toBlob(
          (blob) => {
            let file = new File([blob], 'image.jpeg', {
              type: 'image/jpeg',
              lastModified: Date.now(),
            })
            resolve(file)
          },
          'image/jpeg',
          1
        )
      }
      reader.onerror = (error) => console.log(error)
    }
  })
}

export const formatFilters = (filters) => {
  const filtersFormated = {}
  Object.keys(filters).forEach((key) => {
    if (filters[key].filter === 'true' || filters[key].filter === 'false') {
      filtersFormated[key] = filters[key].filter === 'true' ? true : false
    } else if (filters[key].type === 'equals' && filters[key].filter) {
      filtersFormated[key] = filters[key].filter
    } else if (filters[key].filterType === 'date') {
      filtersFormated['updated_at'] =
        filters[key].dateFrom || filters[key].dateTo
    } else {
      filtersFormated[key] = `%${filters[key].filter}%`
    }
  })

  return filtersFormated
}

/**
 * Checks if the row is selectable for an user, if the user is an admin, all rows are selectable
 * @param {RowNode} rowNode
 * @returns {Boolean}
 */
export const isRowSelectable = (rowNode, rolUser) => {
  if (rolUser) return true

  let blocked = rowNode?.data?.blocked ?? false
  let publico = rowNode?.data?.publico ?? false

  return !blocked && !publico
}
