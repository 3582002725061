import { useEffect } from 'react'
import {
  SET_TOKEN_SUCCESS,
  SET_USER_SUCCESS,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  SET_FILTERS_AGGRID,
  GET_FILTERS_AGGRID,
} from '../constants/user'

const initialState = {
  user: null,
  token: null,
  isLoading: false,
  isError: false,
  isAdmin: false,
  organizacion: null,
  filters : null,
  getFilters : false
}

/**
 * @author ehernandez
 * @param {object} state state of the application
 * @param {object} action action to be executed
 * @returns {object}
 */
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        user: action.user,
        token: action.token,
      }

    case SET_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        user: action.user,
      }

    case USER_LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
        isAdmin: false,
        organizacion: null,
      }

    case SET_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.token,
        user: action.user,
        isAdmin: action.isAdmin,
        organizacion: action.organizacion,
      }

      case SET_FILTERS_AGGRID :
        return {
          ...state,
          filters : action.filters
        }
      case GET_FILTERS_AGGRID :
        return {
          ...state,
          getFilters : true
        }
    default:
      return state
  }
}

export default userReducer
