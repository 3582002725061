import React from 'react'
import { LogoHeader } from '../../components/styles'
import Logo from '../../../../components/Logo/Logo'
import ConfirmationEmail from '../../components/ConfirmationEmail'

const ConfirmationEmailScreen = () => {
  return (
    <div className="login-screen">
      <LogoHeader className="login-screen-header tw-w-full tw-flex tw-items-center tw-justify-center tw-bg-gray-200">
        <div className="login-screen-header-logo tw-w-80">
          <Logo />
        </div>
      </LogoHeader>
      <div className="login-screen-content tw-flex tw-flex-col tw-items-center tw-justify-center">
        <ConfirmationEmail />
      </div>
    </div>
  )
}

export default ConfirmationEmailScreen
