import React from 'react'
import { useOutletContext } from 'react-router-dom'

import ProductManager from '../../modules/ProductManager/ProductManager'

const PIMProductos = () => {
  const { isAdmin } = useOutletContext()
  return <ProductManager rolUser={isAdmin} />
}

export default PIMProductos
