import { useMutation } from '@apollo/client'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import useBaseResponse from '../../../../infrastructure/hooks/useBaseResponse'
import useFetch from '../../../../infrastructure/hooks/useFetch'
import { actionSetTokenSuccess } from '../../../../infrastructure/redux/actions/user'
import {
  LOGIN_ADMIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  PHARMACY_LOGIN,
} from '../../graphql/mutations'

/**
 * @description Hook to get the login state
 * @author ehernandez
 * @returns {Object}
 */
export const useLogin = () => {
  const [loading, setLoading] = useState(false)
  const { responseFail, responseSuccess } = useBaseResponse()
  const dispatch = useDispatch()

  // Mutations
  const [handleLogin] = useMutation(LOGIN_ADMIN)

  const [handlePharmacyLogin] = useMutation(PHARMACY_LOGIN)

  const [handleForgotPassword] = useMutation(FORGOT_PASSWORD)

  const [handleResetPassword] = useMutation(RESET_PASSWORD)

  //Functions
  const [getTokenAuth] = useFetch('/sfera/autologin/')

  /**
   * @author ehernandez
   * @param {Object} user
   * @returns {Object}
   */

  const onLogin = useCallback(async (user, isAdmin = false) => {
    setLoading(true)
    const { data, errors } = await handleLogin({ variables: { ...user } })
    setLoading(false)
    if (data?.loginAdmin) {
      let { token, usuario } = data.loginAdmin
      if (usuario?.perfiles?.length > 0) {
        isAdmin = usuario.perfiles.find(
          (perfil) =>
            perfil.id === '2' || perfil.perfil.toLowerCase() === 'administrador'
        )
          ? 'ADMIN'
          : usuario.perfiles.find(
              (perfil) =>
                perfil.id === '3' ||
                perfil.perfil.toLowerCase() === 'planograma retail manager'
            )
          ? 'RM'
          : false
      } else isAdmin = false
      dispatch(actionSetTokenSuccess(token, usuario, isAdmin))
      return responseSuccess(data.loginAdmin)
    }
    return responseFail(errors)
  }, [])

  const onPharmacyLogin = useCallback(async (user) => {
    const { data, errors } = await handlePharmacyLogin({
      variables: { ...user },
    })
    if (data && data.login) {
      dispatch(
        actionSetTokenSuccess(
          data.login.token,
          data.login.usuario,
          false,
          data.login.usuario?.organizacion
        )
      )
      return responseSuccess(data.login)
    }
    return responseFail(errors)
  }, [])

  const onAutoLogin = async (tokenAccess) => {
    const { success, data, errors } = await getTokenAuth({
      method: 'GET',
      addToURL: tokenAccess,
    })
    if (success && data) {
      dispatch(
        actionSetTokenSuccess(data.token, data.user, false, data?.organizacion)
      )
      return responseSuccess(data)
    }
    return responseFail(errors)
  }

  const onForgotPassword = useCallback(async (email) => {
    setLoading(true)
    const { data, errors } = await handleForgotPassword({
      variables: { email },
    })
    setLoading(false)
    if (data?.forgotPassword) {
      return responseSuccess(data.forgotPassword)
    }
    return responseFail(errors)
  })

  const onResetPassword = useCallback(async (token, passwords) => {
    setLoading(true)
    const { data, errors } = await handleResetPassword({
      variables: {
        input: {
          token,
          password: passwords.password,
          password_confirmation: passwords.confirmPwd,
        },
      },
    })
    setLoading(false)
    if (data?.resetPassword) {
      return responseSuccess(data.resetPassword)
    }
    return responseFail(errors)
  })

  return {
    onLogin,
    onPharmacyLogin,
    loading,
    onAutoLogin,
    onForgotPassword,
    onResetPassword,
  }
}
