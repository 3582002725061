import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'

import Paragraphs from '../../../../components/commons/Paragraphs/Paragraphs'
import Modal from '../../../../components/commons/Modal'
import TextInput from '../../../../components/commons/TextInput/TextInput'
import ModalNav from '../ModalConfiguracion/components/ModalNav/ModalNav'
import { columns } from '../../aggrid/columns'
import { useTranslation } from 'react-i18next'
import Aggrid from '../../../../infrastructure/components/Aggrid/Aggrid'

const CreateGroupModal = ({
  getFarmaciasListing,
  toggle,
  edit,
  onAccept,
  formErrors,
}) => {
  const { t } = useTranslation()
  const inputRef = useRef(null)
  const gridRefFarmacias = useRef()
  const [gridParams, setGridParams] = useState(null)
  const [allPharmacies, setAllPharmacies] = useState(null)
  const [stopActions, setStopActions] = useState(false)
  const [listChecked, setListChecked] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(50)

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm({
    defaultValues: {
      ...edit,
    },
    shouldFocusError: true,
  })

  useEffect(() => {
    if (edit) {
      setListChecked(edit.organizations)
    }
  }, [edit])

  useEffect(() => {
    if (formErrors) {
      handleErrors(formErrors)
    }
  }, [formErrors])

  useEffect(() => {
    document
      .getElementById('modal-edit-groups')
      ?.classList.add('tw-overflow-y-auto')
  }, [toggle])

  // Handle submit
  const submitToggle = async (values) => {
    let organizations = []
    if (!allPharmacies) {
      gridParams?.api.forEachNode((node) => {
        if (node.data.selected) organizations.push(node.data.id)
      })
    } else {
      organizations = listChecked.map((item) => item.id)
    }
    let newValues = {
      ...values,
      organizations,
    }
    // if name is empty, set it to null
    if (!newValues.name) {
      return setError(
        'name',
        { type: 'focus', message: 'El nombre es requerido' },
        { shouldFocus: true }
      )
    }
    onAccept(newValues)
  }

  // Handle errors
  const handleErrors = (errors) => {
    if (errors.length > 0) {
      errors.forEach((error) => {
        Object.keys(error).forEach((key) => {
          setError(
            key,
            { type: 'focus', message: error[key] },
            { shouldFocus: true }
          )
        })
      })
    }
  }

  const getPharmacys = async (params, page, name, orderBy) => {
    getFarmaciasListing(perPage, page, name, orderBy)
      .then((response) => {
        if (response.data) {
          const { data, paginatorInfo } = response.data
          const { total, per_page } = paginatorInfo
          setTotalRows(total)
          setPerPage(per_page)
          let newData = data.map((item) => {
            let selected = false
            if (edit && edit.organizations.length > 0) {
              selected =
                edit.organizations.find((org) => org.id === item.id) || false
            }
            return {
              ...item,
              selected,
            }
          })
          params.success({
            rowData: newData,
            rowCount: total,
          })
        }
      })
      .catch((error) => {
        console.error(error)
        params.fail()
      })
  }

  const onGridReadyFarmacias = useCallback((params) => {
    params.api?.addEventListener('cellClicked', ({ data }) => {
      if (data) {
        data.selected = !data.selected
      }
      params.api.refreshCells({ force: true })
    })

    setGridParams(params)

    const updateData = () => {
      const datasource = {
        async getRows(rowParams) {
          const { filterModel } = rowParams.request
          let filteredName = null
          if (filterModel['nombre']) {
            filteredName = filterModel['nombre'].filter
          }
          let orderBy = null
          if (rowParams.request?.sortModel?.length > 0) {
            orderBy = rowParams.request.sortModel.map((sort) => {
              return {
                column: 'nombre',
                order: sort.sort.toUpperCase(),
              }
            })
          }

          let page = rowParams.request.startRow / 50 + 1
          await getPharmacys(rowParams, page, filteredName, orderBy)
        },
      }

      params.api.setServerSideDatasource(datasource)
    }

    updateData(params.api)
  }, [])

  function handleOnAccept(values) {
    clearErrors()
    handleSubmit(submitToggle)(values)
  }

  const handleChange = (e, params) => {
    if (e.target.checked) {
      params.api.forEachNode((node) => {
        node.data.selected = true
      })
      params.api.refreshCells({ force: true })
    } else {
      setAllPharmacies(null)
      params.api.forEachNode((node) => {
        node.data.selected = false
      })
      params.api.refreshCells({ force: true })
    }
  }

  const CheckboxSelection = (params) => {
    return (
      <div className="checkbox-selection tw-h-full tw-flex tw-items-center tw-cursor-pointer">
        <input
          type="checkbox"
          ref={inputRef}
          onChange={(e) => handleChange(e, params)}
          style={{
            width: '20px',
            height: '20px',
            marginRight: '10px',
            position: 'relative',
            clipPath: 'none',
          }}
        />
        <Paragraphs size="sm" className="tw-text-white">
          {params.displayName}
        </Paragraphs>
      </div>
    )
  }

  const onCellClicked = (params) => {
    const selectedRows = params.api.getSelectedRows()
    setStopActions(false)
    if (selectedRows[0].selected === false) {
      setCheckedAll(false)
    }
    params.api.forEachNode((node) => {
      if (
        selectedRows.map((org) => org.id).includes(node?.data?.id) &&
        !stopActions
      ) {
        if (params.node.data.selected) {
          node.data.selected = false
        }
        // set ...listChecked, and selectedRows[0] if not exist in listChecked
        if (!listChecked.map((org) => org.id).includes(selectedRows[0]?.id)) {
          node.setDataValue('pharmacys', true)
          setListChecked([...listChecked, selectedRows[0]])
        } else {
          setListChecked(
            listChecked.filter((org) => org.id !== selectedRows[0]?.id)
          )
          node.setDataValue('pharmacys', false)
        }
      }
    })
    params.api.refreshCells({ force: true })
  }

  return (
    <Modal
      onAccept={handleOnAccept}
      onCancel={toggle}
      id="modal-edit-groups"
      btnTextAccept={edit?.id ? t('actions.Guardar') : 'Crear'}
      maximizable={true}
      widthContentFull={false}
      verticalAlign={'middle'}
    >
      <div className="tw-bg-white tw-rounded tw-overflow-y-auto tw-overflow-x-hidden tw-p-4 ">
        <form className="tw-z-50 tw-w-full tw-bg-white tw-opacity-100">
          <section className="ModalNavSection tw-flex tw-flex-1">
            <ModalNav
              title={
                edit?.id ? t('titles.Editar grupo') : t('titles.Crear grupo')
              }
            />
          </section>
          <div className="tw-flex tw-flex-col tw-gap-1 tw-p-3">
            <section className="tw-flex-col tw-flex-1 tw-w-80">
              <div className="FirstRow tw-flex tw-flex-row">
                <article className="tw-p-1 tw-mr-2 tw-w-full tw-w-1/3">
                  <TextInput
                    id="name"
                    name="name"
                    error={errors?.name?.message}
                    label={t('Nombre')}
                    register={register('name', {
                      required: {
                        message: t('labels.Campo obligatorio'),
                      },
                    })}
                  />
                </article>
              </div>
            </section>

            <div className="tw-flex tw-flex-col tw-my-1 tw-p-1">
              <div className="tw-flex tw-justify-between">
                <div className="tw-h-60 tw-border tw-border-gray tw-w-full">
                  {getFarmaciasListing && (
                    <Aggrid
                      onCellClicked={onCellClicked}
                      reference={gridRefFarmacias}
                      id="user-modal-edit-create"
                      columns={columns.groupsPharmacys}
                      onGridReady={onGridReadyFarmacias}
                      height={'100%'}
                      cacheBlockSize={totalRows}
                      paginationAutoPageSize={false}
                      rowSelection="multiple"
                      customFrameworkComponents={{
                        checkboxSelection: CheckboxSelection,
                      }}
                      type="scroll"
                      rowModelType={'serverSide'}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  )
}

CreateGroupModal.propTypes = {
  edit: PropTypes.object,
  toggle: PropTypes.func.isRequired,
  getFarmaciasListing: PropTypes.func.isRequired,
  getUsersListing: PropTypes.func.isRequired,
  onAccept: PropTypes.func,
  formErrors: PropTypes.array,
}

export default CreateGroupModal
