import styled from 'styled-components'

export const TopModalCropImage = styled.div`
  position: absolute;
  width: 98%;
  height: 98%;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  z-index: 99999999999;
`
