import { useLazyQuery } from '@apollo/client'
import Paragraphs from '../../../../components/commons/Paragraphs/Paragraphs'
import { useEffect, useState, Suspense, lazy } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import PharmacyTitle from '../../../../components/Home/PharmacyTitle'
import LoadingOverlay from '../../../../components/LoadingOverlay/LoadingOverlay'
import useUser from '../../../../infrastructure/hooks/useUser'
import { GET_MY_PHARMACIES } from '../../graphQL/querys'
import usePlanogram from '../../hooks/usePlanograma'
import { Container } from './styles'

const WelcomeHomeModal = lazy(() =>
  import('../../../../components/Home/WelcomeModal')
)

const ResumeWizard = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const { user } = useUser()
  const { planogramaLinealBBDD, setSelectedPlanogram } = usePlanogram(
    user?.organization?.id,
    false
  )

  const [onHover, setOnHover] = useState(false)
  const [openModalWelcome, setOpenModalWelcome] = useState(false)

  /**
   * This functions gets the planograms of the user pharmacy
   */
  const [getMyPharmacyPlanograms] = useLazyQuery(GET_MY_PHARMACIES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setPlanogramas(data.getAllMyPlanogramas)
      if (data.getAllMyPlanogramas.length === 0 || !data.getAllMyPlanogramas) {
        setOpenModalWelcome(true)
      }
      setLoading(false)
    },
    onError: (error) => {
      setLoading(false)
      console.error(error)
    },
  })

  const [loading, setLoading] = useState(true)
  const [planogramas, setPlanogramas] = useState([])

  useEffect(() => {
    if (location.state?.planograms) {
      setPlanogramas(location.state?.planograms)
      setLoading(false)
    } else {
      getMyPharmacyPlanograms()
    }
  }, [location.state?.planograms])

  /**
   * This funcion is used to get the planogram data to by edited
   * @param {string} id
   * @returns
   */
  const getPlanogramToEdit = async (id) => {
    setLoading(true)
    const response = await planogramaLinealBBDD(id)
    const { success } = response
    setSelectedPlanogram(response?.data)
    setLoading(false)
    if (!success) {
      return null
    }
    localStorage.setItem('planogramaId', response?.data.id)
    navigate(`/planograma/creator/${response?.data.id}`)
  }

  const handleToggleModal = () => {
    setOpenModalWelcome(false)
    navigate('/home/create-wizard')
  }

  return (
    <Suspense fallback={<LoadingOverlay />}>
      <div className="xl:tw-p-8 tw-p-4 tw-relative tw-w-full tw-relative tw-overflow-y-auto tw-h-full">
        {openModalWelcome && (
          <WelcomeHomeModal
            onToggle={() => handleToggleModal()}
            message={t(
              'messages.En estos momentos no tienes ningún planograma creado. Puedes empezar aquí siguiendo paso a paso nuestra guía para crear planogramas basados en tus cifras de rentabilidad.'
            )}
            hideCheckBox
          />
        )}
        {Boolean(loading) && <LoadingOverlay />}
        <PharmacyTitle user={user} />

        <div className="tw-flex tw-flex-col tw-justify-evently tw-items-start tw-w-full">
          <div className="tw-grid tw-grid-cols-12 tw-w-full tw-mb-4">
            <div className="tw-col-span-2 xl:tw-col-span-5"></div>
            <div className="xl:tw-col-span-2 tw-col-span-2">
              <div className="tw-flex tw-flex-col tw-items-center tw-h-full tw-w-full">
                {t('labels.Peso de categoría')}
              </div>
            </div>
            <div className="xl:tw-col-span-2 tw-col-span-2">
              <div className="tw-flex tw-flex-col tw-items-center tw-h-full tw-w-full">
                {t('labels.Evolución')}
              </div>
            </div>
            <div className="tw-col-span-3">
              <div className="tw-flex tw-flex-col tw-items-center tw-h-full tw-w-full">
                {t('labels.Tu farmacia')}
              </div>
            </div>
          </div>
        </div>

        {/* table head */}
        <div className="tw-grid tw-grid-cols-12 xl:tw-gap-4 tw-gap-2 tw-border-b-2 tw-border-black tw-pb-2">
          {/* 1st column */}
          <div className="tw-col-span-2">
            <div className="tw-flex tw-flex-col tw-items-start tw-h-full tw-w-full">
              {t('labels.Categoría')}
            </div>
          </div>
          {/* 2nd column */}
          <div className="tw-col-span-3">
            <div className="tw-flex tw-flex-col tw-items-start tw-h-full tw-w-full">
              {t('labels.Nombre')}
            </div>
          </div>
          {/* 3rd column */}
          <div className="tw-col-span-1">
            <div className="tw-flex tw-flex-col tw-items-center tw-h-full tw-w-full">
              {t('labels.Farmacia')}
            </div>
          </div>
          {/* 4th column */}
          <div className="tw-col-span-1">
            <div className="tw-flex tw-flex-col tw-items-center tw-h-full tw-w-full">
              {t('labels.Mercado')}
            </div>
          </div>
          {/* 5th column */}
          <div className="tw-col-span-1">
            <div className="tw-flex tw-flex-col tw-items-center tw-h-full tw-w-full">
              {t('labels.Farmacia')}
            </div>
          </div>
          {/* 6th column */}
          <div className="tw-col-span-1">
            <div className="tw-flex tw-flex-col tw-items-center tw-h-full tw-w-full">
              {t('labels.Mercado')}
            </div>
          </div>
          {/* 7th column */}
          <div className="tw-col-span-3">
            {/* 6 columns */}
            <div className="tw-grid tw-grid-cols-6 tw-gap-4">
              {/* 1st column */}
              <div className="tw-col-span-1">
                <div className="tw-flex tw-flex-col tw-items-center tw-h-full tw-w-full">
                  {t('labels.Módulos')}
                </div>
              </div>
              {/* 2nd column */}
              <div className="tw-col-span-1">
                <div className="tw-flex tw-flex-col tw-items-center tw-h-full tw-w-full">
                  {t('labels.Baldas_')}
                </div>
              </div>
              {/* 3rd column */}
              <div className="tw-col-span-1">
                <div className="tw-flex tw-flex-col tw-items-center tw-h-full tw-w-full">
                  {t('labels.Ancho')}
                </div>
              </div>
              {/* 4th column */}
              <div className="tw-col-span-1">
                <div className="tw-flex tw-flex-col tw-items-center tw-h-full tw-w-full">
                  {t('labels.Alto')}
                </div>
              </div>
              {/* 5th column */}
              <div className="tw-col-span-1">
                <div className="tw-flex tw-flex-col tw-items-center tw-h-full tw-w-full">
                  {t('labels.Profundo')}
                </div>
              </div>
              <div className="tw-col-span-1"> </div>
            </div>
          </div>
        </div>

        {/* table body */}
        {planogramas.map((planograma) => (
          <Container
            className="tw-grid tw-grid-cols-12 xl:tw-gap-4 tw-gap-2 tw-border-b-2 tw-border-gray-300 tw-h-20 tw-items-center"
            key={planograma.id}
            onMouseEnter={() => {
              setOnHover(planograma.id)
            }}
            onMouseLeave={() => {
              setOnHover(false)
            }}
          >
            <div className="tw-col-span-2">
              <div className="tw-flex tw-flex-col tw-items-start tw-h-full tw-w-full">
                <Paragraphs size="xl">
                  {planograma.categories[0].nombre}
                </Paragraphs>
              </div>
            </div>
            <div className="tw-col-span-3">
              <div className="tw-flex tw-flex-col tw-items-start tw-h-full tw-w-full">
                {planograma.nombre}
              </div>
            </div>
            <div
              className="tw-col-span-2 tw-h-full tw-w-full"
              style={
                onHover === planograma.id
                  ? { backgroundColor: 'transparent' }
                  : { backgroundColor: '#ECECEC' }
              }
            >
              <div className="tw-flex tw-flex-row tw-h-full tw-w-full tw-flex tw-justify-around tw-items-center ">
                <Paragraphs size="xl" weight={'bold'}>
                  {planograma.weight_category_pharmacy || 0} %
                </Paragraphs>

                <Paragraphs
                  size="xl"
                  weight={'bold'}
                  color={
                    planograma.weight_category_pharmacy >= 0
                      ? '#009329'
                      : '#9A0000'
                  }
                >
                  {planograma.weight_category_market || 0} %
                </Paragraphs>
              </div>
            </div>
            <div
              className="tw-col-span-2 tw-h-full tw-w-full"
              style={
                onHover === planograma.id
                  ? { backgroundColor: 'transparent' }
                  : { backgroundColor: '#ECECEC' }
              }
            >
              <div className="tw-flex tw-flex-row tw-h-full tw-w-full tw-flex tw-justify-around tw-items-center ">
                <Paragraphs size="xl" weight={'bold'}>
                  {planograma.evolution_pharmacy || 0} %
                </Paragraphs>

                <Paragraphs size="xl" weight={'bold'}>
                  {planograma.evolution_market || 0} %
                </Paragraphs>
              </div>
            </div>
            <div className="tw-col-span-3">
              {/* dimensions */}
              <div className="tw-grid tw-grid-cols-6 tw-gap-4">
                {/* 1st column */}
                <div className="tw-col-span-1">
                  <div className="tw-flex tw-flex-col tw-items-center tw-h-full tw-w-full">
                    <Paragraphs size="xl" weight={'bold'}>
                      {planograma.divisiones.length}
                    </Paragraphs>
                  </div>
                </div>
                {/* 2nd column */}
                <div className="tw-col-span-1">
                  <div className="tw-flex tw-flex-col tw-items-center tw-h-full tw-w-full">
                    <Paragraphs size="xl" weight={'bold'}>
                      {planograma.baldas ||
                        planograma.divisiones[0].soportes.length}
                    </Paragraphs>
                  </div>
                </div>
                {/* 3rd column */}
                <div className="tw-col-span-1">
                  <div className="tw-flex tw-justify-center tw-items-center tw-h-full tw-w-full">
                    <Paragraphs size="xl" weight={'bold'}>
                      {planograma.anchura}
                    </Paragraphs>
                    <small className="tw-ml-1 tw-mt-1">cm</small>
                  </div>
                </div>
                {/* 4th column */}
                <div className="tw-col-span-1">
                  <div className="tw-flex tw-justify-center tw-items-center tw-h-full tw-w-full">
                    <Paragraphs size="xl" weight={'bold'}>
                      {planograma.altura}
                    </Paragraphs>
                    <small className="tw-ml-1 tw-mt-1">cm</small>
                  </div>
                </div>
                {/* 5th column */}
                <div className="tw-col-span-1">
                  <div className="tw-flex tw-justify-center tw-items-center tw-h-full tw-w-full">
                    <Paragraphs size="xl" weight={'bold'}>
                      {planograma.profundo}
                    </Paragraphs>
                    <small className="tw-ml-1 tw-mt-1">cm</small>
                  </div>
                </div>
                {/* 6th column */}
                <div className="tw-col-span-1">
                  <button
                    className={`tw-flex tw-justify-center tw-flex-col tw-items-center tw-h-full tw-w-full ${
                      loading ? 'tw-cursor-not-allowed tw-opacity-50' : ''
                    }`}
                    onClick={() => getPlanogramToEdit(planograma.id)}
                    disabled={loading}
                  >
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="32" height="32" fill="#7D185C" />
                      <path
                        d="M8 20.4588V24H11.5412L21.9853 13.5559L18.4441 10.0147L8 20.4588ZM24.7238 10.8174C25.0921 10.4491 25.0921 9.85419 24.7238 9.4859L22.5141 7.27621C22.1458 6.90793 21.5509 6.90793 21.1826 7.27621L19.4545 9.0043L22.9957 12.5455L24.7238 10.8174Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </Container>
        ))}
      </div>
    </Suspense>
  )
}

export default ResumeWizard
