import { useState, useEffect, useContext } from 'react'
import { useMutation } from '@apollo/client'
import {
  CREATE_PLANOGRAM,
  DELETE_PLANOGRAM,
  GET_PLANOGRAM_LIST,
  GET_PRODUCT_LIST,
  EDIT_SOPORTE,
  INSERTAR_ARTICULO_LINEAL,
  ACTUALIZAR_ARTICULO_LINEAL,
  MOVER_ARTICULO_LINEAL,
  ELIMINAR_ARTICULO_SOPORTE,
  PLANOGRAMA_BORRAR_ARTICULOS,
  PLANOGRAMA_ACTUALIZAR_DATOS_ARTICULO_BACKOFICE,
  PLANOGRAMA_CLONAR_LINEAL_RETAIL,
  PLANOGRAMA_LINEAL,
  PLANOGRAMA_ACTUALIZAR_LINEAL,
  PLANOGRAMA_BORRAR_LINEAL_DIVISION,
  PLANOGRAMA_CREAR_LINEAL_SOPORTE,
  PLANOGRAMA_BORRAR_LINEAL_SOPORTE,
  PLANOGRAMA_CLONAR_LINEAL_FARMACIA,
  PLANOGRAMA_BORRAR_ARTICULOS_LINEAL,
} from '../../graphQL'
import {
  PLANOGRAMA_ACTUALIZAR_SOPORTE_BACKOFFICE,
  PLANOGRAMA_BORRAR_ARTICULOS_LINEAL_BACKOFFICE,
  PLANOGRAMA_CATEGORIAS_LINEAL_BACKOFFICE,
  PLANOGRAMA_CREAR_LINEAL_BACKOFFICE,
  PLANOGRAMA_LINEALES_BACKOFFICE,
  PLANOGRAMA_CREAR_LINEAL_SOPORTE_BACKOFFICE,
  PLANOGRAMA_BORRAR_LINEAL_DIVISION_BACKOFFICE,
  PLANOGRAMA_ACTUALIZAR_LINEAL_BACKOFFICE,
  PLANOGRAMA_ARTICULOS_BACKOFFICE,
  ORGANIZACIONES,
  PLANOGRAMA_BORRAR_LINEAL_BACKOFFICE,
  PLANOGRAMA_INSERTAR_ARTICULO_SOPORTE_BACKOFFICE,
  PLANOGRAMA_BORRAR_ARTICULO_SOPORTE_BACKOFFICE,
  PLANOGRAMA_BORRAR_SOPORTE_BACKOFFICE,
  PLANOGRAMA_ACTUALIZAR_ARTICULO_SOPORTE_BACKOFFICE,
  PLANOGRAMA_MOVER_ARTICULO_SOPORTE_BACKOFFICE,
  PLANOGRAMA_CLONE_LINEAL_BACKOFFICE,
  UPDATE_FULL_PLANOGRAM,
  PLANOGRAMA_LINEAL_BACKOFFICE,
  UNBLOCK_PLANOGRAM,
  CATEGORIES_TREE,
  PLANOGRAMA_ACTUALIZAR_LINEAL_SOPORTE_BACKOFFICE,
} from '../../graphQL/admin'
import ContextExtra from '../../../../infrastructure/context/ContextExtra.js'

import { createResponseFail, createResponseOk } from './utils'

let timer = null

export const usePlanogram = (pharmacyId, rolUser) => {
  const [planograms, setPlanograms] = useState([])
  const [products, setProducts] = useState([])
  const [selectedPlanogram, setSelectedPlanogram] = useState(null)
  const [totalPlanograms, setTotalPlanograms] = useState(0)
  const [getPlanograms, { data: planogramsList }] = useMutation(
    GET_PLANOGRAM_LIST,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const [planogramaLinealesBackoffice, { data: planogramsListBackoffice }] =
    useMutation(PLANOGRAMA_LINEALES_BACKOFFICE, {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    })

  const [organizaciones] = useMutation(ORGANIZACIONES, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })
  const [eliminarArticuloSoporte] = useMutation(ELIMINAR_ARTICULO_SOPORTE, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })

  const [planogramaBorrarArticuloSoporteBackoffice] = useMutation(
    PLANOGRAMA_BORRAR_ARTICULO_SOPORTE_BACKOFFICE,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const [insertarArticuloLinea] = useMutation(INSERTAR_ARTICULO_LINEAL, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })

  const [planogramaInsertarArticuloSoporteBackoffice] = useMutation(
    PLANOGRAMA_INSERTAR_ARTICULO_SOPORTE_BACKOFFICE,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const [updateFullPlanogram] = useMutation(UPDATE_FULL_PLANOGRAM, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })
  const [moverArticuloLineal] = useMutation(MOVER_ARTICULO_LINEAL, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })
  const [planogramaMoverArticuloBackoffice] = useMutation(
    PLANOGRAMA_MOVER_ARTICULO_SOPORTE_BACKOFFICE,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const [actualizarArticuloLineal] = useMutation(ACTUALIZAR_ARTICULO_LINEAL, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })

  const [actualizarArticuloLinealBackoffice] = useMutation(
    PLANOGRAMA_ACTUALIZAR_ARTICULO_SOPORTE_BACKOFFICE,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const [getProducts, { loading: loadingProducts }] = useMutation(
    GET_PRODUCT_LIST,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
      onCompleted: (response) => {
        setProducts(response.planogramaArticulos)
      },
    }
  )

  const [
    planogramaArticulosBackoffice,
    { loading: loadingProductsBackoffice },
  ] = useMutation(PLANOGRAMA_ARTICULOS_BACKOFFICE, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: (response) => {
      setProducts(response.planogramaArticulosBackoffice)
    },
  })
  const [planogramaLineal] = useMutation(PLANOGRAMA_LINEAL, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })

  // Change to QUERY
  const [planogramaLinealBackoffice] = useMutation(
    PLANOGRAMA_LINEAL_BACKOFFICE,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const [createLinealPlanogram] = useMutation(CREATE_PLANOGRAM, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })

  const [planogramaCrearLinealBackoffice] = useMutation(
    PLANOGRAMA_CREAR_LINEAL_BACKOFFICE,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const [planogramaClonarLinealOrganizacion] = useMutation(
    PLANOGRAMA_CLONAR_LINEAL_FARMACIA,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const [planogramaClonarLinealRetailBackoffice] = useMutation(
    PLANOGRAMA_CLONAR_LINEAL_RETAIL,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const [planogramaBorrarArticulosLineal] = useMutation(
    PLANOGRAMA_BORRAR_ARTICULOS_LINEAL,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )
  const [planogramaBorrarArticulosLinealBackoffice] = useMutation(
    PLANOGRAMA_BORRAR_ARTICULOS_LINEAL_BACKOFFICE,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const [planogramaActualizarLineal] = useMutation(
    PLANOGRAMA_ACTUALIZAR_LINEAL,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )
  const [planogramaActualizarLinealBackoffice] = useMutation(
    PLANOGRAMA_ACTUALIZAR_LINEAL_BACKOFFICE,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const [updateSoporteBackOffice] = useMutation(
    PLANOGRAMA_ACTUALIZAR_SOPORTE_BACKOFFICE,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  //TODO: @3rChuss - Wait for the backend mutation to be implemented
  const [cloneLinealBackOffice] = useMutation(
    PLANOGRAMA_CLONE_LINEAL_BACKOFFICE,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const [deleteLinealPlanogram] = useMutation(DELETE_PLANOGRAM, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })

  const [planogramaBorrarLinealBackoffice] = useMutation(
    PLANOGRAMA_BORRAR_LINEAL_BACKOFFICE,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const [planogramaBorrarLinealDivision] = useMutation(
    PLANOGRAMA_BORRAR_LINEAL_DIVISION,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )
  const [planogramaBorrarLinealDivisionBackoffice] = useMutation(
    PLANOGRAMA_BORRAR_LINEAL_DIVISION_BACKOFFICE,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const [planogramaCrearLinealSoporte] = useMutation(
    PLANOGRAMA_CREAR_LINEAL_SOPORTE,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )
  const [planogramaCrearLinealSoporteBackoffice] = useMutation(
    PLANOGRAMA_CREAR_LINEAL_SOPORTE_BACKOFFICE,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const [planogramaBorrarLinealSoporte] = useMutation(
    PLANOGRAMA_BORRAR_LINEAL_SOPORTE,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const [planogramaBorrarLinealSoporteBackoffice] = useMutation(
    PLANOGRAMA_BORRAR_SOPORTE_BACKOFFICE,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const [editSoporte] = useMutation(EDIT_SOPORTE, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })

  const [planogramaActualizarSoporteBackoffice] = useMutation(
    PLANOGRAMA_ACTUALIZAR_SOPORTE_BACKOFFICE,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const [planogramaActualizarLinealSoporteBackoffice] = useMutation(
    PLANOGRAMA_ACTUALIZAR_LINEAL_SOPORTE_BACKOFFICE,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const [planogramaCategoriasLineal] = useMutation(CATEGORIES_TREE, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })

  const [planogramaCategoriasLinealBackoffice] = useMutation(
    PLANOGRAMA_CATEGORIAS_LINEAL_BACKOFFICE,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const [planogramaBorrarArticulos] = useMutation(PLANOGRAMA_BORRAR_ARTICULOS, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })

  const [planogramaActualizarDatosArticuloBackoffice] = useMutation(
    PLANOGRAMA_ACTUALIZAR_DATOS_ARTICULO_BACKOFICE,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  )

  const [unblockPlanogram] = useMutation(UNBLOCK_PLANOGRAM, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })

  const [getCategoriesTreeWithLevel] = useMutation(CATEGORIES_TREE, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })

  /**
   * Get categories tree by level optional
   * @param {number} level
   * @returns {Object} PlanogramaCategoriaArticulo
   */
  const getCategoriesTree = async (level) => {
    let data = null,
      errors = null
    if (level) {
      data = await getCategoriesTreeWithLevel({
        variables: {
          nivel: level,
        },
      })
    } else {
      data = await getCategoriesTreeWithLevel()
    }

    if (errors) {
      return {
        error: true,
        success: false,
      }
    }

    return createResponseOk('categoriesTree', data, rolUser)
  }

  /**
   * Obtener organizaciones desde BBDD
   * @author   ehernandez
   * @param    {Object}   planogram
   * @param    {Array}    organizaciones
   * @param    {Boolean}  todos asignar a todas las organizaciones
   */
  const crearPlanogramaLinealBBDD = async (values) => {
    const input = {
      ...values,
    }
    let response = null

    if (rolUser) {
      response = await planogramaCrearLinealBackoffice({
        variables: {
          input,
        },
      })
    } else {
      response = await createLinealPlanogram({
        variables: {
          input,
        },
      })
    }

    const { data, errors } = response
    if (errors) {
      return {
        error: true,
        success: false,
      }
    }

    await getPlanogramasLineales()
    return createResponseOk('planogramaLinealWizard', data, rolUser)
  }

  const getPlanogramasLineales = async (first, page, filters, orderBy) => {
    let response = null
    let variables = {
      first: first || 50,
      page: page ? page : 1,
    }

    if (filters && Object.keys(filters).length > 0) {
      variables = {
        first: first || 50,
        page: page ? page : 1,
        ...filters,
      }
    }

    if (orderBy) {
      variables = {
        ...variables,
        orderBy,
      }
    } else {
      variables = {
        ...variables,
        orderBy: [
          { column: 'destacado', order: 'DESC' },
          { column: 'created_at', order: 'DESC' },
        ],
      }
    }
    if (rolUser) {
      response = await planogramaLinealesBackoffice({ variables })
      if (response?.data) {
        setTotalPlanograms(
          response.data.planogramaLinealesBackoffice.paginatorInfo.total
        )
      }
    } else {
      response = await getPlanograms({ variables })
      if (response?.data) {
        setTotalPlanograms(response.data.planogramaLineales.data.length)
      }
    }

    const { data, errors } = response
    if (errors) {
      return {
        error: true,
        success: false,
      }
    }

    return createResponseOk('planogramaLineales', data, rolUser)
  }

  /**
   * Obtener organizaciones desde BBDD
   * @author   ehernandez
   * @param    {String}     id
   * @param    {Object}     input
   */
  const planogramaActualizarMedidasBBDD = async (id, input) => {
    let response = null

    input = {
      ...input,
      altura: input.altura ? parseFloat(input.altura) : null,
      anchura: input.anchura ? parseFloat(input.anchura) : null,
      profundo: input.profundo ? parseFloat(input.profundo) : null,
    }

    if (rolUser) {
      response = await planogramaActualizarLinealBackoffice({
        variables: { id, input },
      })
    } else {
      response = await planogramaActualizarLineal({
        variables: { id, input },
      })
    }
    const { errors, data } = response
    if (!errors) {
      return createResponseFail()
    }

    return createResponseOk('planogramaActualizarLineal', data, rolUser)
  }

  /**
   * Updates a Soporte
   * @param {string} id
   * @param {PlanogramaLinealInput} input
   */
  const planogramaActualizarSoporteBBDD = async (id, input) => {
    let response = null
    if (rolUser) {
      response = await updateSoporteBackOffice({
        variables: { id, input },
      })
    }
    const { errors, data } = response
    if (!errors) {
      return createResponseFail()
    }

    return createResponseOk('planogramaActualizarSoporteBBDD', data, rolUser)
  }

  /**
   * Creates a new Lineal
   * @param {string} id
   * @param {PlanogramaLienalInput} input
   */

  const planogramaCloneLinealBackOffice = async (id) => {
    let response = null

    response = await cloneLinealBackOffice({
      variables: { id },
    })
    const { errors, data } = response
    if (errors) {
      return createResponseFail()
    }
    return {
      success: true,
      data: data['clonarLinealDivisionBackoffice'],
    }
  }

  /**
   * Obtener organizaciones desde BBDD
   * @author   ehernandez
   * @param    {String}     id
   * @param    {Object}     input
   * @param    {Array}      organizaciones asignar a todas las organizaciones
   */
  const planogramaActualizarLinealBBDD = async (id, input, organizaciones) => {
    let response = null
    delete input.categoria_id
    if (rolUser) {
      response = await planogramaActualizarLinealBackoffice({
        variables: {
          id,
          input,
        },
      })
    } else {
      response = await planogramaActualizarLineal({
        variables: {
          id,
          input,
        },
      })
    }
    const { errors, data } = response
    if (errors) {
      return createResponseFail()
    }

    await getPlanogramasLineales()
    return createResponseOk('planogramaActualizarLineal', data, rolUser)
  }

  /**
   * Eliminar division
   * @author   ehernandez
   * @param    {String} id id division
   */
  const planogramaBorrarLinealDivisionBBDD = async (division) => {
    const variables = {
      id: division.id,
    }
    let response = null
    if (rolUser) {
      response = await planogramaBorrarLinealDivisionBackoffice({
        variables,
      })
    } else {
      variables.organizacion_id = pharmacyId
      response = await planogramaBorrarLinealDivision({
        variables,
      })
    }

    const { data, errors } = response
    if (errors) {
      return createResponseFail()
    }

    return createResponseOk('planogramaBorrarLinealDivision', data, rolUser)
  }

  /**
   * Eliminar planograma
   * @author   ehernandez
   * @param    {String} id
   * @return   {filtrarFarmaciasPaginado}
   *
   *
   *  @typedef {Object} filtrarFarmaciasPaginado
   *  @property {Array} data array de objectos con el listado
   *  @property {Object} paginatorInfo informacion acerca de la pagina en la paginacion
   */
  const eliminarPlanogramaLinealBBDD = async (id) => {
    let response = null

    if (rolUser) {
      response = await planogramaBorrarLinealBackoffice({
        variables: {
          id,
        },
      })
    } else {
      response = await deleteLinealPlanogram({
        variables: {
          id,
        },
      })
    }
    const { errors, data } = response
    await getPlanogramasLineales()
    if (!errors) {
      return { success: true }
    }
    return {
      success: false,
    }
  }

  /**
   * Crea un nuevo soporte
   * @author   ehernandez
   * @param    {String} soporte
   * @param       {String} soporte.id
   * @param     {Object} soporteADividir
   * @param     {String} soporteADividir.id
   */
  const planogramaCrearLinealSoporteBBDD = async (soporte, soporteDividir) => {
    let response = null

    if (rolUser) {
      response = await planogramaCrearLinealSoporteBackoffice({
        variables: {
          id: soporte.id,
          input: {
            soporte_id: soporteDividir.id,
            espesor_soporte: soporte.espesor_soporte,
            organizacion_id: pharmacyId,
          },
        },
      })
    } else {
      response = await planogramaCrearLinealSoporte({
        variables: {
          id: soporte.id,
          input: {
            soporte_id: soporteDividir.id,
            espesor_soporte: soporte.espesor_soporte,
            organizacion_id: pharmacyId,
          },
        },
      })
    }

    const { data, errors } = response
    if (errors) {
      return createResponseFail()
    }

    return createResponseOk('planogramaCrearLinealSoporte', data, rolUser)
  }

  const editSoportePlanogram = async (key, soporte) => {
    await clearTimeout(timer)
    timer = setTimeout(async () => {
      const item = {}

      if (rolUser) {
        await planogramaActualizarSoporteBackoffice({
          variables: {
            id: soporte.id,
            input: { ...item, organizacion_id: pharmacyId },
          },
        })
      } else {
        await editSoporte({
          variables: {
            id: soporte.id,
            input: { ...item, organizacion_id: pharmacyId },
          },
        })
      }
    }, 1000)
  }

  const cambiarModoGanchosBBDD = async (soporteId) => {
    const variables = {
      id: soporteId,
      input: {
        organizacion_id: pharmacyId,
        modo: 'gancho',
        inicio_gancho_x: 1,
        inicio_gancho_y: 1,
        separacion_gancho_x: 4,
        separacion_gancho_y: 4,
      },
    }

    if (rolUser) {
      await planogramaActualizarLinealSoporteBackoffice({
        variables,
      })
    } else {
      await editSoporte({
        variables,
      })
    }
  }

  const cambiarModoEstanteriaBBDD = async (soporteId) => {
    const variables = {
      id: soporteId,
      input: {
        organizacion_id: pharmacyId,
        modo: 'estante',
      },
    }
    if (rolUser) {
      await planogramaActualizarSoporteBackoffice({
        variables,
      })
    } else {
      await editSoporte({
        variables,
      })
    }
  }

  const actualizarArticuloSoporteBBDD = async (articulo, user) => {
    const variables = {
      id: articulo.id,
      input: {
        soporte_id: articulo.soporte.id,
        x: articulo.x,
        y: articulo.y,
        organizacion_id: pharmacyId,
        anchura: parseFloat(articulo.anchura),
        altura: parseFloat(articulo.altura),
        rotacion: articulo.rotacion,
      },
    }
    let response = null
    if (rolUser) {
      response = await actualizarArticuloLinealBackoffice({ variables })
    } else {
      response = await actualizarArticuloLineal({ variables })
    }
    const { errors } = response
    if (errors) {
      return createResponseFail()
    }
    return createResponseOk()
  }

  const insertarArticuloLineaBBDD = async (articulo, soporte, articuloId) => {
    if (articulo.type === 'extra') {
      articuloId = articulo.product.id
      articulo.image = articulo.url
      articulo.type = 'extra'
    }
    if (articulo.product) {
      articuloId = articulo.product.id
    }
    const variables = {
      id: articulo.id,
      input: {
        articulo_id: articuloId,
        soporte_id: soporte.id,
        x: articulo.x,
        y: articulo.y,
        organizacion_id: pharmacyId,
        anchura: articulo.anchura,
        altura: articulo.altura,
        cn: articulo.giff,
      },
    }
    if (rolUser) {
      delete variables.input.organizacion_id
      await planogramaInsertarArticuloSoporteBackoffice({
        variables,
      })
    } else {
      await insertarArticuloLinea({ variables })
    }
    // variables.input.organizacion_id = pharmacyId
  }

  const moverArticuloLinealBBDD = async (articulo, soporte) => {
    const variables = {
      id: articulo.id,
      input: {
        soporte_id: soporte.id,
        x: articulo.x < 0 ? 0 : articulo.x,
        y: articulo.y,
        organizacion_id: pharmacyId,
        anchura: articulo.anchura,
        altura: articulo.altura,
        rotacion: articulo.rotacion,
      },
    }
    if (rolUser) {
      const { success, error } = await planogramaMoverArticuloBackoffice({
        variables,
      })
      if (error) {
        return createResponseFail()
      }
      return createResponseOk(
        'planogramaMoverArticuloBackoffice',
        success,
        rolUser
      )
    } else {
      moverArticuloLineal({ variables })
    }
  }

  const getMoreProductsBBDD = async ({ paginatorInfo }, first, search) => {
    const currentPage = search && search !== '' ? 0 : paginatorInfo?.currentPage
    const hasMorePages =
      search && search !== '' ? true : paginatorInfo?.hasMorePages
    if (hasMorePages) {
      await getProducts({
        variables: {
          page: currentPage + 1,
          first: first || 20,
          busqueda: search || '',
        },
      })
    }
  }

  /**
   * Obtener productos
   * @author   ehernandez
   * @param    {Object} variables
   * @param       {Object} variables.first catidad de elementos a consultar
   * @param       {Object} variables.
   * @param       {Object} variables
   *
   * @return   {{data: Array | paginatorInfo: Object}}
   */
  const getProductsBBDD = async (variables) => {
    let response = null
    if (rolUser) {
      response = await planogramaArticulosBackoffice({
        variables,
      })
    } else {
      response = await getProducts({
        variables,
      })
    }
    const { data, errors } = response
    if (errors) {
      return {
        success: false,
      }
    }
    if (rolUser) {
      response = data.planogramaArticulosBackoffice
    } else {
      response = data.planogramaArticulos
    }

    return {
      success: true,
      response,
    }
  }

  /**
   * Obtener planograma seleccionado para mostrar en creator
   * @author   ehernandez
   * @param    {Number} id
   * @return   {{success: Boolean | response: Object}}
   */
  const planogramaLinealBBDD = async (id) => {
    let response = null

    if (rolUser) {
      response = await planogramaLinealBackoffice({ variables: { id } })
    } else {
      response = await planogramaLineal({ variables: { id } })
    }

    const { data, errors } = response

    if (
      errors &&
      errors.length > 0 &&
      errors[0].message ===
        'El Planograma actual está bloqueado por otro Usuario.'
    ) {
      let planogram = planograms.find((planogram) => planogram.id === id)
      return {
        blocked: true,
        planogramName: planogram.nombre,
        id: planogram.id,
      }
    }

    if (errors) {
      return createResponseFail()
    }

    const res = createResponseOk('planogramaLineal', data, rolUser)
    setSelectedPlanogram(res?.response)

    return {
      success: true,
      response,
      data: res.response,
    }
  }

  /**
   * Eliminar articulos masivamente
   * @author   ehernandez
   * @param    {String} articulos array de articulos a eliminar
   * @param     {String} idLineal uuid o id del lineal o estante
   */
  const planogramaBorrarArticulosLinealBBDD = async (articulos, idLineal) => {
    if (rolUser) {
      await planogramaBorrarArticulosLinealBackoffice({
        variables: {
          id: articulos || [],
          organizacion_id: pharmacyId,
          lineal_id: idLineal,
        },
      })
    } else {
      await planogramaBorrarArticulosLineal({
        variables: {
          id: articulos || [],
          organizacion_id: pharmacyId,
          lineal_id: idLineal,
        },
      })
    }
  }

  const onChangeModeView = async (eliminarProductos) => {
    const { id } = selectedPlanogram
    await planogramaLinealBBDD(id, eliminarProductos)
  }

  /**
   * Clona un planograma a raiz de otro
   * @author   ehernandez
   * @param    {String} id
   * @return   {{success: Boolean}}
   */
  const clonarPlanogramaBBDD = async (id) => {
    const variables = {
      id,
    }
    if (rolUser) {
      const { errors } = await planogramaClonarLinealRetailBackoffice({
        variables,
      })
      if (errors) return createResponseFail()
    } else {
      variables.organizacion_id = pharmacyId
      const { errors, data } = await planogramaClonarLinealOrganizacion({
        variables,
      })
      if (errors) return createResponseFail()
    }
    await getPlanogramasLineales()
    return createResponseOk()
  }

  /**
   * clona una plantilla de planograma
   * @author mromero
   * @param {String} id
   * @returns {id} id del nuevo planograma
   */
  const clonarPlantillaPlanogramaBBDD = async (id) => {
    const variables = {
      id,
    }
    variables.organizacion_id = pharmacyId
    const { errors, data } = await planogramaClonarLinealOrganizacion({
      variables,
    })
    if (errors) return createResponseFail()
    return data.planogramaClonarLinealOrganizacion.id
  }

  useEffect(() => {
    if (planogramsList || planogramsListBackoffice) {
      const { response } = createResponseOk(
        'planogramaLineales',
        planogramsList || planogramsListBackoffice,
        rolUser
      )
      if (response?.data) {
        let lastArray = JSON.stringify(planograms)
        let newArray = JSON.stringify(response.data)
        if (lastArray !== newArray) {
          setPlanograms(response.data)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planogramsList, planogramsListBackoffice])

  const eliminarArticuloSoporteBBDD = (articulo) => {
    const variables = {
      id: articulo.id,
    }
    if (rolUser) {
      planogramaBorrarArticuloSoporteBackoffice({ variables })
    } else {
      variables.organizacion_id = pharmacyId
      eliminarArticuloSoporte({ variables })
    }
  }

  /**
   * Updates full planogram
   * @param {id} id planogram Id
   * @param {Object} Object to update
   * @returns new planogram
   *
   */
  const updateFullPlanogramaLineal = async (linealId, planogram) => {
    let response = null

    const variables = {
      id: linealId,
      input: planogram,
    }

    response = await updateFullPlanogram({ variables })
    const { errors, data } = response
    if (errors) {
      return createResponseFail()
    }
    setSelectedPlanogram(data.updateFullPlanogramaLineal)
    return { success: true, data: data.updateFullPlanogramaLineal }
  }

  /**
   * Eliminamos un soporte
   * @author   ehernandez
   * @param    {Object} soporte
   * @param       {Object} soporte.id
   */
  const planogramaBorrarLinealSoporteBBDD = async (soporte) => {
    const variables = {
      id: soporte.id,
      organizacion_id: pharmacyId,
    }
    let response = null

    if (rolUser) {
      delete variables.organizacion_id
      response = await planogramaBorrarLinealSoporteBackoffice({ variables })
    } else {
      response = await planogramaBorrarLinealSoporte({ variables })
    }
    const { errors, data } = response
    if (errors) {
      return createResponseFail()
    }

    return createResponseOk('planogramaBorrarLinealSoporte', data, rolUser)
  }

  /**
   * Obtener listado de organizaciones
   * @author   ehernandez
   * @param    {Number} page
   * @param    {Number} first
   * @param    {Number} input
   * @return   {filtrarFarmaciasPaginado}
   *
   *
   *  @typedef {Object} filtrarFarmaciasPaginado
   *  @property {Array} data array de objectos con el listado
   *  @property {Object} paginatorInfo informacion acerca de la pagina en la paginacion
   */
  const getFarmaciasListing = async (first, page, name, orderBy) => {
    let variables = {
      first: first || 1000,
      page: page || 1,
    }
    if (name) {
      variables.nombre = `%${name}%`
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    const { data } = await organizaciones({
      variables,
    })
    if (data) {
      return {
        success: true,
        data: data.organizaciones,
      }
    }
    return {
      success: true,
      data: [],
      paginatorInfo: {
        currentPage: 1,
        hasMorePages: false,
      },
    }
  }

  /**
   * Obtener listado de organizaciones
   * @author   ehernandez
   * @param    {Array} ids
   * @return   {{success: Boolean}}

   */
  const planogramaBorrarArticulosBBDD = async (ids) => {
    const { errors } = await planogramaBorrarArticulos({
      variables: { id: ids },
    })
    if (errors) {
      return createResponseFail()
    }

    return createResponseOk()
  }

  /**
   * Obtener listado de organizaciones
   * @author   ehernandez
   * @param    {Object} variables
   * @param       {String} variables.id
   * @param       {Object} variables.input
   * @return   {{success: Boolean}}
   */
  const planogramaActualizarDatosArticuloBackofficeBBDD = async (variables) => {
    const { errors } = await planogramaActualizarDatosArticuloBackoffice({
      variables,
    })
    if (errors) {
      return {
        success: false,
      }
    }

    return {
      success: true,
    }
  }

  /**
   * Obtener categorias organizaciones
   * @author   ehernandez
   * @return   {{success: Boolean}}
   */
  const planogramaCategoriasLinealBBDD = async () => {
    if (rolUser) {
      const { data, errors } = await planogramaCategoriasLinealBackoffice()
      if (errors) {
        return createResponseFail()
      }
      return createResponseOk('planogramaCategoriasLineal', data, rolUser)
    }
    const { data, errors } = await planogramaCategoriasLineal()
    if (errors) {
      return createResponseFail()
    }
    return createResponseOk('planogramaCategoriasLineal', data, rolUser)
  }

  const obtenerPlanogramasDestacados = () => {
    function importAll(r) {
      let images = {}
      r.keys().map((item, index) => {
        images[item.replace('./', '')] = r(item)
      })
      return images
    }
    const images = importAll(
      require.context(
        '../../../../components/PlanogramasSlider/assets',
        false,
        /\.(png|jpe?g|svg)$/
      )
    )
    const planogramSlides = [
      {
        id: 1,
        planogramId: '38',
        title: 'corporal',
      },
      {
        id: 2,
        planogramId: '21',
        title: 'infantil',
      },
      {
        id: 3,
        planogramId: '31',
        title: 'bucal',
      },
      {
        id: 4,
        planogramId: '39',
        title: 'salud sexual',
      },
      {
        id: 5,
        planogramId: '43',
        title: 'botiquín',
      },
    ]
    return { planogramSlides, images }
  }

  const unblockPlanogramById = async (id) => {
    const { errors } = await unblockPlanogram({
      variables: { id },
    })
    if (errors) {
      return createResponseFail()
    }
    return createResponseOk()
  }

  return {
    totalPlanograms,
    crearPlanogramaLinealBBDD,
    eliminarPlanogramaLinealBBDD,
    planogramaCrearLinealSoporteBBDD,
    planogramaBorrarLinealSoporteBBDD,
    eliminarArticuloSoporteBBDD,
    selectedPlanogram,
    setSelectedPlanogram,
    planogramaLinealBBDD,
    editSoportePlanogram,
    planogramaActualizarLinealBBDD,
    products,
    setProducts,
    planograms,
    setPlanograms,
    getProducts,
    getPlanogramasLineales,
    planogramaBorrarLinealDivisionBBDD,
    insertarArticuloLineaBBDD,
    moverArticuloLinealBBDD,
    planogramaCategoriasLinealBBDD,
    onChangeModeView,
    clonarPlanogramaBBDD,
    cambiarModoGanchosBBDD,
    cambiarModoEstanteriaBBDD,
    actualizarArticuloSoporteBBDD,
    getMoreProductsBBDD,
    loadingProducts,
    getFarmaciasListing,
    getProductsBBDD,
    planogramaBorrarArticulosBBDD,
    planogramaActualizarDatosArticuloBackofficeBBDD,
    planogramaActualizarMedidasBBDD,
    planogramaBorrarArticulosLinealBBDD,
    obtenerPlanogramasDestacados,
    clonarPlantillaPlanogramaBBDD,
    planogramaActualizarSoporteBBDD,
    planogramaCloneLinealBackOffice,
    updateFullPlanogramaLineal,
    unblockPlanogramById,
    getCategoriesTree,
  }
}
