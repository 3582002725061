import React from 'react'
import { LogoHeader } from '../../components/styles'
import Logo from '../../../../components/Logo/Logo'
import CreatePasswordForm from '../../components/CreatePassword'

const CreatePasswordScreen = () => {
  return (
    <div className="newPassword-screen">
      <LogoHeader className="newPassword-screen-header tw-w-full tw-flex tw-items-center tw-justify-center tw-bg-gray-200">
        <div className="newPassword-screen-header-logo tw-w-80">
          <Logo />
        </div>
      </LogoHeader>
      <div className="newPassword-screen-content tw-flex tw-flex-col tw-items-center tw-justify-center">
        <CreatePasswordForm />
      </div>
    </div>
  )
}

export default CreatePasswordScreen
