import React, { useState } from 'react'

import Button from '../../../../components/commons/Button/Button'
import Paragraphs from '../../../../components/commons/Paragraphs/Paragraphs'
import TextInput from '../../../../components/commons/TextInput/TextInput'

import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useLogin } from '../../hooks/useLogin/useLogin'
import { validateEmail } from '../../../../infrastructure/utils/common'

const ForgotPasswordForm = () => {
  const [loading, setLoading] = useState(false)
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()
  const { t } = useTranslation()
  const { onForgotPassword } = useLogin()
  const navigate = useNavigate()

  /**
   * form submit for forgot password
   * @author   jesusabril
   * @param    {Object} data form input
   *     @param    {string} email email
   */
  const onSubmit = async (data) => {
    if (validateEmail(data.email)) {
      setLoading(true)
      let email = data.email.toLowerCase()
      await onForgotPassword(email)
      navigate('/email-confirmation-sent', { state: { email } })
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="tw-flex tw-flex-col tw-items-center tw-flex-1 tw-h-full"
    >
      <div className="tw-p-6">
        <div className="tw-p-4">
          <Paragraphs weight="bold" size="xl">
            {t('login.Recordar contraseña')}
          </Paragraphs>
        </div>
        <div>
          <TextInput
            type="text"
            id="email"
            label={t('login.Correo electronico')}
            register={register('email', {
              required: {
                value: true,
                message: 'Campo requerido',
              },
            })}
            error={errors['email']?.message}
          />
        </div>
      </div>
      <Button
        label={t('actions.Enviar')}
        uppercase
        type="submit"
        disabled={loading}
      />
    </form>
  )
}

export default ForgotPasswordForm
