import React, { useState, useEffect } from 'react'

import Button from '../../../../components/commons/Button/Button'
import Paragraphs from '../../../../components/commons/Paragraphs/Paragraphs'
import TextInput from '../../../../components/commons/TextInput/TextInput'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useLogin } from '../../hooks/useLogin/useLogin'
import { FieldOkMark } from './styles'

import greenTick from '../../../../infrastructure/assets/svg/green_tick.svg'
import { actionSetTokenSuccess } from '../../../../infrastructure/redux/actions/user'

const CreatePasswordForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const [showGreenTick, setShowGreenTick] = useState(false)
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState('')
  const [title, setTitle] = useState('')

  useEffect(() => {
    if (location.state && location.state.token) {
      setToken(location.state.token)
      setTitle(t('login.Revisa tu correo electronico'))
    } else {
      let params = new URLSearchParams(location.search)
      setToken(String(params.get('token')))
      setTitle(t('login.Crea tu contraseña'))
    }
  }, [])

  // Password validations
  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required('Campo obligatorio')
      .min(8, 'Minimo 8 caracteres')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.])(?=.{8,})/,
        'Revisa la contraseña'
      ),
    confirmPwd: Yup.string()
      .required('Campo obligatorio')
      .oneOf([Yup.ref('password')], 'Las contraseñas no coninciden'),
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(formSchema), mode: 'onTouched' })

  const { onResetPassword } = useLogin()

  /**
   * form submit for new password form
   * @author   jesusabril
   * @param    {Object} passwords element
   * @param    {String}   passwords.password password
   * @param    {String}   passwords.confirmPwd confirm password
   */
  const onSubmit = async (passwords) => {
    if (passwords) {
      setLoading(true)
      setShowGreenTick(true)
      const { data } = await onResetPassword(token, passwords)
      if (data) {
        const { token, usuario } = data
        if (token && usuario) {
          setLoading(false)
          dispatch(actionSetTokenSuccess(token, usuario, true))
          return navigate('/')
        }
      }
      return navigate('/login')
    }
  }

  if (token === '') {
    navigate('/login')
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="tw-flex tw-flex-col tw-items-center tw-flex-1 tw-h-full"
    >
      <div className="tw-p-6">
        <div className="tw-p-4">
          <Paragraphs weight="bold" size="2xl">
            {title}
          </Paragraphs>
        </div>
        <div className="tw-w-80 tw-text-center tw-mb-4">
          <Paragraphs>{t('login.Introduce nueva contraseña')}</Paragraphs>
        </div>
        <div>
          <div className="tw-relative">
            <TextInput
              name={'password'}
              type={'password'}
              id={'password'}
              label={'Introduce tu nueva contraseña'}
              register={register('password')}
              error={errors['password']?.message}
            />
            <FieldOkMark>
              {Boolean(
                errors && Object.keys(errors).length === 0 && showGreenTick
              ) && <img src={greenTick} alt="tick" className="tick-icon" />}
            </FieldOkMark>
          </div>
          <div className="tw-relative">
            <TextInput
              name={'confirmPwd'}
              type={'password'}
              id={'confirmPwd'}
              label={'Reescribe tu nueva contraseña'}
              register={register('confirmPwd')}
              error={errors['confirmPwd']?.message}
            />
            <FieldOkMark>
              {Boolean(
                errors && Object.keys(errors).length === 0 && showGreenTick
              ) && <img src={greenTick} alt="tick" className="tick-icon" />}
            </FieldOkMark>
          </div>
        </div>
      </div>
      <Button
        label={t('actions.Cambiar')}
        uppercase
        type="submit"
        disabled={loading}
      />
    </form>
  )
}

export default CreatePasswordForm
