import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs/Paragraphs'
import { useTranslation } from 'react-i18next'

const Label = ({
  label,
  size,
  className,
  required,
  color,
  truncate,
  weight,
}) => {
  const { t } = useTranslation()
  return (
    <Paragraphs
      weight={`${weight ? 'bold' : 'regular'}`}
      size={size}
      color={color}
      className={'Label tw-flex'}
    >
      <p className={`${truncate ? 'tw-truncate' : ''} ${className}`}>
        {t(`labels.${label}`)}
      </p>
      {required && <span className="tw-text-alert"> *</span>}
    </Paragraphs>
  )
}

Label.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  truncate: PropTypes.bool,
  translate: PropTypes.bool,
  weight: PropTypes.bool,
}

Label.defaultProps = {
  required: false,
  truncate: false,
  color: 'black',
  weight: true,
  size: 'xs',
  className: '',
}

export default Label
