export const useBtn = (mode, rounded, uppercase, customParams) => {
  /**
   * Ontienen las clases por defecto segun el modo
   * @author   ehernandez --> nombre del creador
   * @param    {boolean} disabled
   * @return   {string}
   */
  const classFromMode = (disabled) => {
    let classes =
      'tw-flex tw-items-center tw-justify-center tw-px-4 tw-py-3 tw-w-full '
    switch (mode) {
      case 'primary':
        classes += `tw-bg-primary ${
          !disabled && 'hover:tw-opacity-90'
        } tw-text-white`
        break

      case 'secondary':
        classes += 'tw-bg-secondary tw-opacity-10 tw-text-primary'
        break

      case 'custom':
        classes += `tw-bg-${customParams?.bg || 'primary'} ${
          customParams?.border
            ? `tw-border tw-border-${customParams.border}`
            : ''
        } ${
          !disabled &&
          `hover:tw-opacity-90 ${
            customParams?.border
              ? `hover:tw-bg-${customParams?.border} hover:tw-text-white`
              : ''
          }`
        } tw-text-${customParams?.text || 'white'}`
        // console.log(classes);
        break

      case 'aggridSeconday':
        classes += `tw-bg-secondary ${
          !disabled && 'hover:tw-opacity-90'
        } tw-text-white`
        break

      case 'aggridTransparentSecondary':
        classes += `tw-bg-transparent ${
          !disabled && 'hover:tw-bg-secondary hover:tw-text-white'
        } tw-text-secondary tw-border-2 tw-border-secondary`
        break

      case 'transparent':
        classes += `tw-bg-transparent ${
          !disabled && 'hover:tw-bg-primary hover:tw-text-white'
        } tw-text-primary tw-border-2 tw-border-primary`
        break

      case 'expansible':
        classes +=
          'tw-bg-transparent tw-text-primary tw-border-l tw-border-t tw-border-r tw-border-primary tw-text-secondary expansible'
        break

      case 'custom-expansible':
        classes += `tw-bg-transparent tw-text-${
          customParams?.text || 'primary'
        } tw-border-l tw-border-t tw-border-r tw-border-${
          customParams?.border || 'primary'
        } expansible`
        break

      default:
        break
    }

    if (rounded) {
      classes += ' tw-rounded-full'
    }

    if (disabled) {
      classes += ' tw-bg-opacity-50'
    }

    if (uppercase) {
      classes += ' tw-uppercase'
    }

    return classes
  }

  return { classFromMode }
}
