import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../styles'

const IcClose = ({ size, color }) => {
  return (
    <Icon
      size={size}
      color={color}
      className="material-icons IcClose"
    >
      close
    </Icon>
  )
}

IcClose.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string
}

export default IcClose
