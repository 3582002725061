import { boolean } from './boolean'
import { date } from './date'


export const columnTypes = {

  boolean,
  date,

}

