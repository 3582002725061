import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import Header from './components/Header'
import Footer from './components/Footer'
import { Rnd } from 'react-rnd'
import { useModal } from './hooks/useModal'
import { Content } from './style'

const Modal = ({
  btnDisabled,
  title,
  afterTitle,
  children,
  customWidth,
  id,
  draggrable,
  maximizable,
  fullScreen,
  closeModal,
  size,
  verticalAlign,
  onAccept,
  onCancel,
  btnTextAccept,
  btnTextCancel,
  footerAlign,
  customFooter,
  loading,
  bodyPadding,
  textRight,
  widthContentFull,
  idForm,
  type,
}) => {
  const reference = useRef(null)
  const {
    maximizeModal,
    minimized,
    positions,
    setInitialPosition,
    onDragStopped,
    whSized,
  } = useModal({ customWidth, reference, size })

  useEffect(() => {
    if (reference?.current) {
      setInitialPosition(reference.current.clientHeight, verticalAlign)
    }
  }, [reference])

  const content = (
    <div
      id={id}
      className="Modal tw-fixed tw-top-0 tw-w-screen tw-h-screen tw-top-0 tw-left-0 tw-bg-black tw-z-50 tw-bg-opacity-50"
      style={{ zIndex: 9999 }}
    >
      <Rnd
        onDragStop={(e, d) =>
          onDragStopped(e, d, reference.current.clientHeight)
        }
        className={`RND_${id} tw-w-full`}
        position={
          fullScreen && !maximizable
            ? {
                x: 0,
                y: 0,
              }
            : positions
        }
        style={{
          zIndex: 9999999,
        }}
        disableDragging={!draggrable}
        dragHandleClassName={`Modal-header-${id}`}
      >
        <Content
          ref={reference}
          className="Modal-content tw-full tw-bg-white"
          size={
            fullScreen && !maximizable
              ? {
                  width: '100vw',
                  height: '100vh',
                }
              : whSized
          }
        >
          {title !== '' && (
            <div className={`Modal-header-${id}`}>
              <Header
                textRight={textRight}
                title={title}
                customTitle={afterTitle}
                minimized={minimized}
                onMaximizeModal={maximizable ? maximizeModal : null}
                onCloseModal={closeModal ? onCancel : null}
              />
            </div>
          )}
          <div
            className={`Modal-body ${bodyPadding || 'tw-p-4'} ${
              widthContentFull ? 'tw-h-full' : ''
            }`}
          >
            {children}
          </div>
          {customFooter ? (
            customFooter()
          ) : (
            <div className="Modal-footer tw-p-4">
              <Footer
                align={footerAlign}
                onAccept={onAccept}
                onCancel={onCancel}
                btnTextAccept={btnTextAccept}
                btnTextCancel={btnTextCancel}
                btnDisabled={btnDisabled}
                loading={loading}
                idForm={idForm}
                type={type}
              />
            </div>
          )}
        </Content>
      </Rnd>
    </div>
  )
  if (!document.getElementById('modal-wrapper')) {
    return content
  }
  return ReactDOM.createPortal(
    content,
    document.getElementById('modal-wrapper')
  )
}

Modal.propTypes = {
  btnDisabled: PropTypes.bool,
  title: PropTypes.string,
  afterTitle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  customFooter: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  loading: PropTypes.bool,
  draggrable: PropTypes.bool,
  maximizable: PropTypes.bool,
  closeModal: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'custom']),
  verticalAlign: PropTypes.oneOf(['top', 'center', 'bottom']),
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func,
  btnTextCancel: PropTypes.string,
  btnTextAccept: PropTypes.string,
  footerAlign: PropTypes.oneOf(['center', 'between', 'end']),
  translate: PropTypes.bool,
  textRight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  widthContentFull: PropTypes.bool,
  fullScreen: PropTypes.bool,
  idForm: PropTypes.string,
  type: PropTypes.string,
  customWidth: PropTypes.string,
}

Modal.defaultProps = {
  title: '',
  customFooter: null,
  draggrable: true,
  maximizable: true,
  closeModal: true,
  size: 'xl',
  verticalAlign: 'center',
  loading: false,
  btnTextAccept: null,
  btnTextCancel: 'Cancelar',
  footerAlign: 'between',
  widthContentFull: false,
  fullScreen: false,
}

export default Modal
