import { useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actionSetUserSuccess } from '../../redux/actions/user'
import { userLogout } from '../../utils/logoutUser'
import { ADMIN, ME } from './graphql'

/**
 * @author ehernandez
 * @returns {object}
 */
export const useUser = () => {
  const { user, token, isAdmin } = useSelector((state) => state.userReducer)
  const [admin, { data: useAdmin }] = useLazyQuery(ADMIN)
  const [me, { data: profile }] = useLazyQuery(ME)
  const dispatch = useDispatch()
  /**
   * @author ehernandez
   * @returns {object}
   */
  const getUserInfo = async () => {
    if (isAdmin) {
      admin({
        variables: {
          id: user.id,
        },
      })
    } else me()
  }

  useEffect(() => {
    if (useAdmin?.admin) dispatch(actionSetUserSuccess(useAdmin.admin))
  }, [useAdmin])

  useEffect(() => {
    if (profile?.me) {
      dispatch(actionSetUserSuccess(profile.me))
    }
  }, [profile])

  /**
   * @author ehernandez
   */
  const logout = () => {
    userLogout()
  }

  return { user, getUserInfo, token, logout, isAdmin }
}
