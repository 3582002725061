import { createContext, useEffect, useState } from 'react'

const Context = createContext({})

export const DimensionsExtraProvider = ({ children }) => {
  const [showDimensions, setShowDimensions] = useState(false)
  const [productExtra, setProductExtra] = useState({})
  const [newProducts, setNewProducts] = useState([])
  const [edit, setEdit] = useState(false)
  const [modeSidebar, setModeSidebar] = useState(1)
  const [loading, setLoading] = useState(false)
  const [editFixed, setEditFixed] = useState(false)
  const [selectedProductStats, setSelectedProductStats] = useState(null)
  const [productToDelete, setProductToDelete] = useState(null)

  useEffect(() => {
    setEdit(false)
    setShowDimensions(false)
  }, [modeSidebar])

  return (
    <Context.Provider
      value={{
        showDimensions,
        productExtra,
        newProducts,
        edit,
        modeSidebar,
        loading,
        editFixed,
        productToDelete,
        setNewProducts,
        setProductExtra,
        setShowDimensions,
        setProductToDelete,
        setEdit,
        setModeSidebar,
        setLoading,
        setEditFixed,
        selectedProductStats,
        setSelectedProductStats
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Context
