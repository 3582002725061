import { gql, fromPromise } from '@apollo/client'
import client from '../apollo/client'
import { actionSetTokenSuccess } from '../redux/actions/user'
import store from './getStore'

const REFRESH_TOKEN = gql`
  mutation refreshToken {
    refreshToken {
      token
    }
  }
`

/**
 * @description Refresh token
 * @author 3rChuss
 * @returns {Promise<string>}
 */
export const refreshToken = () => {
  return fromPromise(
    client
      .mutate({
        mutation: REFRESH_TOKEN,
      })
      .then((response) => {
        const { token } = response.data.token
        store.dispatch(actionSetTokenSuccess(token))
        return token
      })
      .catch((error) => {
        return error
      })
  )
}
