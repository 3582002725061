import labels from './labels.json'
import errors from './errors.json'
import tables from './tables.json'
import actions from './actions.json'
import usuarios from './usuarios.json'
import entidades from './entidades.json'
import menus from './menus.json'
import messages from './messages.json'
import placeholders from './placeholders.json'
import windowsHeaders from './windowsHeaders.json'
import tpv from './tpv.json'
import products from './products.json'
import almacenes from './almacenes.json'
import interfaz from './interfaz.json'
import titles from './titles.json'
import planograma from '../../../../modules/Planograma/infrastructure/locales/es/index.json'
import login from '../../../../modules/Auth/locales/es/index.json'

export const es = {
  labels,
  errors,
  tables,
  actions,
  usuarios,
  entidades,
  menus,
  messages,
  placeholders,
  windowsHeaders,
  tpv,
  products,
  titles,
  almacenes,
  interfaz,
  planograma,
  login,
}
