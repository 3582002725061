import Paragraphs from '../../../../../../components/commons/Paragraphs/Paragraphs'
import { Identifier } from '../../styles'

export const ProductTable = ({
  imagen,
  nombre,
  ean,
  laboratorio,
  categoria,
  categoria_final,
  subcategoria,
  onDoubleClickProduct,
  altura,
  anchura,
  profundo,
  giff,
  id,
  laboratorio_id,
  categoria_id,
  categoria_final_id,
  is_fedefarma,
  is_my_pharmacy,
  onRightClickProduct,
  subcategoria_nombre,
  rolUser,
}) => {
  return (
    <div
      className={`tw-flex tw-justify-around tw-py-2 ${
        rolUser
          ? 'tw-cursor-pointer hover:tw-bg-gray-100'
          : is_my_pharmacy
          ? 'tw-cursor-pointer hover:tw-bg-gray-100'
          : 'tw-pointer-events-none'
      }`}
      onDoubleClick={() =>
        onDoubleClickProduct({
          imagen,
          nombre,
          ean,
          laboratorio,
          categoria,
          categoria_final,
          subcategoria,
          altura,
          anchura,
          profundo,
          giff,
          is_fedefarma,
          is_my_pharmacy,
          id,
          laboratorio_id,
          categoria_id,
          categoria_final_id,
        })
      }
      onContextMenu={(e) => {
        e.stopPropagation()
        e.preventDefault()
        onRightClickProduct(e, {
          imagen,
          nombre,
          ean,
          laboratorio,
          categoria,
          categoria_final,
          subcategoria,
          altura,
          anchura,
          profundo,
          giff,
          is_fedefarma,
          is_my_pharmacy,
          id,
          laboratorio_id,
          categoria_id,
          categoria_final_id,
        })
      }}
    >
      <div className="tw-text-left tw-w-24 tw-border-2 tw-h-24 tw-p-2 tw-relative tw-overflow-hidden tw-flex">
        <img
          alt={nombre}
          src={
            imagen
              ? imagen
              : require('../../../../../../infrastructure/assets/images/no-image.png')
                  .default
          }
          onError={(e) => {
            e.target.onerror = null
            e.target.src =
              require('../../../../../../infrastructure/assets/images/no-image.png').default
          }}
          className="tw-max-h-full tw-m-auto"
        />
        {!is_my_pharmacy ? (
          is_fedefarma ? (
            <Identifier>FF</Identifier>
          ) : (
            <Identifier color="#009A63">RF</Identifier>
          )
        ) : null}
      </div>
      <div
        className="tw-text-center tw-w-32 tw-px-2 tw-border-b-2 tw-flex tw-items-center tw-overflow-hidden tw-relative"
        style={{ minWidth: '7rem', maxWidth: '8rem' }}
      >
        <Paragraphs size="sm" className="tw-w-full tw-text-center">
          {giff}
        </Paragraphs>
      </div>
      <div
        className="tw-text-left tw-w-80 tw-border-b-2 tw-border-l-2 tw-pl-2 tw-flex tw-items-center tw-overflow-hidden"
        style={{ minWidth: '8rem' }}
      >
        {nombre}
      </div>
      <div
        className="tw-text-left tw-w-80 tw-border-b-2 tw-border-l-2 tw-pl-2 tw-flex tw-items-center tw-overflow-hidden"
        style={{ minWidth: '8rem' }}
      >
        <Paragraphs weight="bold" size="sm">
          {laboratorio}
        </Paragraphs>
      </div>
      <div
        className="tw-text-left tw-w-80 tw-border-b-2 tw-border-l-2 tw-pl-2 tw-flex tw-items-center tw-overflow-hidden"
        style={{ minWidth: '8rem' }}
      >
        {/* PD: CATEGORIA ES FAMILIA */}
        <Paragraphs size="sm">{categoria}</Paragraphs>
      </div>
      <div
        className="tw-text-left tw-w-80 tw-border-b-2 tw-border-l-2 tw-border-r-2 tw-pl-2 tw-flex tw-items-center tw-overflow-hidden"
        style={{ minWidth: '8rem' }}
      >
        {/* PD: SUBCATEGORIA ES SUBFAMILIA */}
        <Paragraphs size="sm">{subcategoria_nombre}</Paragraphs>
      </div>
    </div>
  )
}
