import { gql } from '@apollo/client'

/**
 *  ### USERS ###
 */

export const GET_USERS_LIST = gql`
  query (
    $page: Int
    $first: Int
    $filter: UsuarioAdminFilterInput
    $orderBy: [OrderByClause!]
  ) {
    listUsersAdmin(
      page: $page
      first: $first
      filter: $filter
      orderBy: $orderBy
    ) {
      paginatorInfo {
        total
        hasMorePages
      }
      data {
        id
        nombre
        apellidos
        email
        perfiles {
          perfil
        }
        organizaciones {
          id
          nombre
        }
        organizaciones_todas
        groups {
          id
          name
        }
      }
    }
  }
`

export const GET_IMAGES_CUSTOM_PLANOGRAMA = gql`
  query {
    planogramaProductImages {
      id
      url
      width
      height
      imageable {
        __typename
      }
      product {
        id
      }
    }
  }
`

/**
 * ### GROUPS ###
 */

export const GET_GROUPS_LIST = gql`
  query ($page: Int, $first: Int, $name: String, $orderBy: [OrderByClause!]) {
    groups(page: $page, first: $first, orderBy: $orderBy, name: $name) {
      paginatorInfo {
        total
        hasMorePages
      }
      data {
        id
        name
        user_admins {
          id
          nombre
          apellidos
          email
        }
        organizations {
          id
          identificador
          nombre
        }
        lineals {
          id
          nombre
        }
      }
    }
  }
`
export const GET_GROUP = gql`
  query ($id: ID!) {
    group(id: $id) {
      id
      name
      user_admins {
        id
        nombre
        apellidos
        perfiles {
          perfil
        }
      }
      organizations {
        id
        identificador
        nombre
      }
      lineals {
        id
        nombre
      }
    }
  }
`
export const GET_ARTICULO_BY_ID = gql`
  mutation ($id: ID!) {
    planogramaArticulo(id: $id) {
      id
      giff
      ean
      nombre
      altura
      anchura
      profundo
      is_my_pharmacy
      laboratorio {
        id
        nombre
      }
      categoria {
        id
        nombre
      }
      subcategoria {
        id
        nombre
      }
      categoria_final {
        id
        nombre
      }
      imagen
      product_image {
        url
      }
      extra {
        amount_pharmacy
        benefit_pharmacy
        fee_pharmacy
        stock_pharmacy
        share_market
        pharmacy_growth
        category_growth_level2
        category_growth_level3
      }
    }
  }
`

export const GET_NEXT_FREE_GIFF_CODE = gql`
  query {
    getNextFreeGiffCode {
      giff
    }
  }
`

/**
 * ### FARMACIAS ###
 */
export const GET_MY_PHARMACIES = gql`
  query {
    getAllMyPlanogramas {
      id
      nombre
      anchura
      altura
      profundo
      weight_category_pharmacy
      weight_category_market
      evolution_pharmacy
      evolution_market
      divisiones {
        id
        soportes {
          id
        }
      }
      organizacion {
        id
        nombre
      }
      categories {
        id
        nombre
      }
    }
  }
`
export const GET_PHARMACY_CONFIG = gql`
  query {
    pharmacyDimension {
      shelves
      modules
      shelf_width
      deep
      modules_height
    }
  }
`
