import styled from 'styled-components'

const List = styled.div`
  height: 85vh;
`

const DropDownBox = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => (props.showMore ? '280px' : '150px')};
  min-height: 150px;
  overflow-x: hidden;
  overflow-y: scroll;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const LoadingDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 3;
  color: #000;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Identifier = styled.span`
  font-size: 11px;
  background-color: ${(props) => (props.color ? props.color : '#7D185C')};
  width: 18px;
  height: 18px;
  position: absolute;
  color: #fff;
  top: 0px;
  left: 0px;
  text-align: center;
`

export { List, DropDownBox, LoadingDiv, Identifier }
