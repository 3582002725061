import React from 'react'
import PropTypes from 'prop-types'
import LogoIMG from '../../infrastructure/assets/img/logo.png'

const Logo = ({ width, imgClass }) => (
  <div>
    <img
      id="logo-planograma"
      alt="logo-planograma"
      src={LogoIMG}
      style={{ width }}
      className={imgClass}
    />
  </div>
)

Logo.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Logo.defaultProps = {
  width: 300,
}
export default Logo
