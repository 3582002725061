import React from 'react'
import PropTypes from 'prop-types'
import { Container } from './styles'

const Header = ({ children, mode }) => {
  const height = mode === 'small' ? 35 : 50
  return (
    <Container
      height={height}
      className="Header tw-border-b tw-border-gray-300 tw-bg-gray-50 tw-flex tw-items-center tw-justify-between tw-pr-4"
    >
      <div className="tw-flex-1">{children}</div>
    </Container>
  )
}

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  mode: PropTypes.oneOf(['full', 'small']),
}

export default Header
