import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Paragraphs from '../../../../components/commons/Paragraphs/Paragraphs'
import Modal from '../../../../components/commons/Modal'
import HeaderPlanogram from '../../modules/PlanogramManager/components/HeaderPlanogram'
import Logo from '../../components/Logo'
import usePlanogram from '../../hooks/usePlanograma'
import { useGroups } from '../../hooks/groupsManagement/useGroups'
import { useUserManagement } from '../../hooks/userManagement/useUserManagement'
import GroupManagement from '../../modules/GroupManagement/GroupManagement'
import CreateGroupModal from '../../components/GroupManagement/CreateGroupModal'
import { useMutation } from '@apollo/client'
import { GET_PLANOGRAM_LIST_PAGINATED } from '../../graphQL'

const GroupsManagement = () => {
  const { t } = useTranslation()
  const [gridApi, setGridApi] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [openModalDetails, setOpenModalDetails] = useState(null)
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false)
  const [formErrors, setFormErrors] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const organizacion = useSelector((state) => state?.userReducer?.organizacion)
  const isAdmin = useSelector((state) => state?.userReducer?.isAdmin)
  const userRol = isAdmin && isAdmin === 'ADMIN' ? 'ADMIN' : null

  const { getGroups, createGroup, deleteGroup, updateGroup } = useGroups()
  const { getUsersListing } = useUserManagement()

  const { getFarmaciasListing } = usePlanogram(organizacion?.id, userRol)
  const [getPlanograms] = useMutation(GET_PLANOGRAM_LIST_PAGINATED)

  const handleOpenModal = (group) => setOpenModalDetails(group)
  const onRowDoubleClicked = (row) => handleOpenModal(row.data)

  const handleEditOrCreateGroup = async (values) => {
    setIsLoading(true)
    let data = null
    const { id } = values

    if ('id' in values) {
      delete values.id
      delete values.perfiles
      const { success, errors } = await updateGroup(id, values)
      errors && setFormErrors([...formErrors, ...errors])
      data = success
    } else {
      const { success, errors } = await createGroup(values)
      errors && setFormErrors([...formErrors, ...errors])
      if (!errors) data = success
    }

    if (data) {
      setFormErrors([])
      gridApi?.refreshServerSideStore()
      setOpenModalDetails(false)
    }
    setIsLoading(false)
  }

  const getGroupsList = (
    params = null,
    page = 1,
    orderBy = null,
    name = null
  ) => {
    let variables = {
      page: page,
      first: 50,
    }
    if (orderBy) variables.orderBy = orderBy
    if (name) variables.name = name

    getGroups(variables)
      .then((res) => {
        const { data, paginatorInfo } = res

        if (data) {
          setIsLoading(false)
          setFormErrors([])
          setTotalRows(paginatorInfo.total)
          if (params) {
            params.success({
              rowData: data,
              rowCount: paginatorInfo.total,
            })
          }
        }
      })
      .catch((err) => {
        console.error(err)
        if (params) {
          params.fail()
        }
      })
  }

  const handleDeleteGroup = (group) => setOpenConfirmDelete(group)
  const tooggleConfirmDelete = () => setOpenConfirmDelete(false)

  const onDeleteGroup = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    if (openConfirmDelete) {
      const { success } = await deleteGroup(openConfirmDelete.id)
      if (success) {
        gridApi?.refreshServerSideStore()

        setOpenConfirmDelete(false)
      }
    }
    setIsLoading(false)
    return tooggleConfirmDelete()
  }

  return (
    <div className="estanterias tw-h-full tw-w-full">
      {isLoading && (
        <div className="tw-flex tw-flex-col tw-absolute tw-top-0 tw-left-0 tw-bg-white tw-bg-opacity-80 tw-h-full tw-w-full tw-z-10 tw-items-center tw-justify-center">
          <Logo width={100} />
          <Paragraphs>{t('labels.Cargando')}...</Paragraphs>
        </div>
      )}
      <HeaderPlanogram
        openCreateModal={handleOpenModal}
        title={t('titles.Gestión de grupos')}
      />
      <GroupManagement
        setGridApi={setGridApi}
        userRol={userRol}
        handleEditOrCreateGroup={handleOpenModal}
        handleDeleteGroup={handleDeleteGroup}
        setOpenConfirmDelete={setOpenConfirmDelete}
        onRowDoubleClicked={onRowDoubleClicked}
        getGroupsList={getGroupsList}
        totalRows={totalRows}
      />
      <div className="ContentGroup">
        {openModalDetails && (
          <CreateGroupModal
            edit={openModalDetails?.id ? openModalDetails : null}
            role={userRol}
            toggle={() => {
              setOpenModalDetails(null), gridApi?.refreshServerSideStore()
            }}
            id="planograma-configuracion"
            onAccept={handleEditOrCreateGroup}
            getFarmaciasListing={getFarmaciasListing}
            getUsersListing={getUsersListing}
            getPlanograms={getPlanograms}
            formErrors={formErrors}
          />
        )}
      </div>
      {openConfirmDelete?.id && (
        <Modal
          id="users-delete"
          onAccept={onDeleteGroup}
          onCancel={tooggleConfirmDelete}
          maximizable={false}
          btnTextAccept={'Eliminar'}
          size="sm"
        >
          <Paragraphs className="tw-text-alert tw-text-center" weight="bold">
            {t('usuarios.¿Está seguro que desea eliminar este usuario?')}
          </Paragraphs>
        </Modal>
      )}
    </div>
  )
}

GroupsManagement.propTypes = {}

export default GroupsManagement
