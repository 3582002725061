import { gql } from '@apollo/client'

export const CREATE_PLANOGRAM = gql`
  mutation ($input: PlanogramaLinealWizardInput) {
    planogramaLinealWizard(input: $input) {
      id
    }
  }
`

export const PLANOGRAMA_ACTUALIZAR_LINEAL = gql`
  mutation ($id: ID!, $input: PlanogramaLinealInput) {
    planogramaActualizarLineal(id: $id, input: $input) {
      id
      altura
      anchura
      estado
      categories {
        id
        nombre
        nivel
        nivel1 {
          id
          nombre
        }
        nivel2 {
          id
          nombre
        }
        nivel3 {
          id
          nombre
        }
        nivel4 {
          id
          nombre
        }
        subcategorias {
          id
          nombre
        }
      }
      destacado
      image {
        url
      }
      borde_derecho
      borde_inferior
      borde_izquierdo
      borde_superior
      divisiones {
        id
        x
        espesor_division
        anchura
        soportes {
          altura
          anchura
          x
          y
          modo
          espesor_soporte
          profundo
          alineacion
          id
          nombre
          articulos {
            id
            nombre
            imagen
            ean
            giff
            anchura
            altura
            soporte {
              id
            }
          }
        }
      }
    }
  }
`

export const DELETE_PLANOGRAM = gql`
  mutation ($id: ID!) {
    planogramaBorrarLineal(id: $id) {
      id
    }
  }
`

export const PLANOGRAMA_CREAR_LINEAL_SOPORTE = gql`
  mutation ($id: String!, $input: PlanogramaLinealSoporteInput) {
    planogramaCrearLinealSoporte(id: $id, input: $input) {
      id
    }
  }
`

export const PLANOGRAMA_BORRAR_LINEAL_SOPORTE = gql`
  mutation ($id: String!, $organizacion_id: ID!) {
    planogramaBorrarLinealSoporte(id: $id, organizacion_id: $organizacion_id) {
      id
    }
  }
`

export const PLANOGRAMA_BORRAR_ARTICULOS_LINEAL = gql`
  mutation ($id: [String!], $organizacion_id: ID, $lineal_id: ID) {
    planogramaBorrarArticulosLineal(
      id: $id
      organizacion_id: $organizacion_id
      lineal_id: $lineal_id
    ) {
      id
    }
  }
`

export const EDIT_SOPORTE = gql`
  mutation ($id: String!, $input: PlanogramaActualizarLinealSoporteInput) {
    planogramaActualizarLinealSoporte(id: $id, input: $input) {
      nombre
      id
      anchura
      altura
      profundo
      borde_derecho
      borde_superior
      borde_inferior
      borde_izquierdo
      divisiones {
        id
        nombre
        espesor_division
        anchura
        x
        soportes {
          id
          modo
          anchura
          altura
          espesor_soporte
          profundo
          x
          y
        }
      }
    }
  }
`

export const GET_PLANOGRAM_LIST = gql`
  mutation ($nombre: String, $updated_at: String) {
    planogramaLineales(
      first: 99999
      nombre: $nombre
      updated_at: $updated_at
      orderBy: [
        { column: "destacado", order: DESC }
        { column: "created_at", order: DESC }
      ]
    ) {
      data {
        nombre
        id
        anchura
        altura
        profundo
        categories {
          id
          nombre
          nivel
          nivel1 {
            id
            nombre
          }
          nivel2 {
            id
            nombre
          }
          nivel3 {
            id
            nombre
          }
          nivel4 {
            id
            nombre
          }
          subcategorias {
            id
            nombre
          }
        }
        destacado
        image {
          url
        }
        organizacion {
          id
          nombre
        }
        organizaciones {
          id
          nombre
        }
        estado
        blocked
        publico
        updated_at
        my_pharmacy
      }
    }
  }
`

export const GET_PLANOGRAM_LIST_PAGINATED = gql`
  mutation ($page: Int, $first: Int, $nombre: String) {
    planogramaLinealesBackoffice(
      page: $page
      first: $first
      nombre: $nombre
      orderBy: [
        { column: "destacado", order: DESC }
        { column: "created_at", order: DESC }
      ]
    ) {
      data {
        nombre
        id
        anchura
        altura
        profundo
        categories {
          id
          nombre
          nivel
          nivel1 {
            id
            nombre
          }
          nivel2 {
            id
            nombre
          }
          nivel3 {
            id
            nombre
          }
          nivel4 {
            id
            nombre
          }
          subcategorias {
            id
            nombre
          }
        }
        destacado
        image {
          url
        }
        organizacion {
          id
          nombre
        }
        organizaciones {
          id
          nombre
        }
        estado
        blocked
        publico
        updated_at
      }
    }
  }
`

export const GET_FILTERED_PRODUCTS = gql`
  mutation (
    $categorias_id: [ID!]
    $categoria_final_id: [ID]
    $subcategorias_id: [ID]
    $laboratories_id: [ID]
    $busqueda: String
    $withStock: Boolean
    $first: Int
    $page: Int
  ) {
    planogramaArticulos(
      categorias_id: $categorias_id
      categoria_final_id: $categoria_final_id
      subcategorias_id: $subcategorias_id
      laboratorios_id: $laboratories_id
      busqueda: $busqueda
      withStock: $withStock
      first: $first
      page: $page
    ) {
      data {
        id
        ean
        giff
        nombre
        anchura
        altura
        profundo
        rotacion
        laboratorio {
          id
        }
        categoria {
          id
          nombre
        }
        imagen
      }
      paginatorInfo {
        lastItem
        lastPage
        total
        count
        hasMorePages
        perPage
        firstItem
        currentPage
      }
    }
  }
`

export const GET_PRODUCT_LIST = gql`
  mutation ($page: Int, $first: Int, $busqueda: String) {
    planogramaArticulos(first: $first, page: $page, busqueda: $busqueda) {
      paginatorInfo {
        count
        currentPage
        hasMorePages
        total
      }
      data {
        id
        giff
        nombre
        anchura
        altura
        profundo
        ean
        imagen
        rotacion
        laboratorio {
          nombre
          id
        }
      }
    }
  }
`

export const PLANOGRAMA_LINEAL = gql`
  mutation ($id: ID!) {
    planogramaLineal(id: $id) {
      nombre
      id
      categories {
        id
        nombre
        nivel
        nivel1 {
          id
          nombre
        }
        nivel2 {
          id
          nombre
        }
        nivel3 {
          id
          nombre
        }
        nivel4 {
          id
          nombre
        }
        subcategorias {
          id
          nombre
        }
      }
      estado
      organizacion {
        id
      }
      destacado
      image {
        url
      }
      publico
      anchura
      altura
      profundo
      blocked
      borde_derecho
      borde_superior
      borde_inferior
      borde_izquierdo
      updated_at
      divisiones {
        id
        nombre
        espesor_division
        anchura
        x
        posicion
        soportes {
          id
          modo
          anchura
          altura
          alineacion
          espesor_soporte
          profundo
          x
          y
          separacion_gancho_x
          separacion_gancho_y
          inicio_gancho_x
          inicio_gancho_y
          articulos {
            id
            nombre
            imagen
            giff
            ean
            altura
            rotacion
            anchura
            soporte {
              id
            }
            laboratorio {
              nombre
            }
            profundo
            x
            y
            categoria {
              id
              nombre
            }
          }
        }
      }
    }
  }
`

export const PLANOGRAMA_CATEGORIAS_LINEAL = gql`
  mutation {
    planogramaCategoriasLineal {
      data {
        nombre
        id
      }
    }
  }
`

export const PLANOGRAMA_BORRAR_LINEAL_DIVISION = gql`
  mutation ($id: String!, $organizacion_id: ID) {
    planogramaBorrarLinealDivision(id: $id, organizacion_id: $organizacion_id) {
      nombre
      id
      categories {
        id
        nombre
        nivel
        nivel1 {
          id
          nombre
        }
        nivel2 {
          id
          nombre
        }
        nivel3 {
          id
          nombre
        }
        nivel4 {
          id
          nombre
        }
        subcategorias {
          id
          nombre
        }
      }
      estado
      blocked
      publico
      anchura
      profundo
      organizacion {
        id
      }
      altura
      profundo
      borde_derecho
      borde_superior
      borde_inferior
      borde_izquierdo
      updated_at
      divisiones {
        id
        nombre
        espesor_division
        anchura
        x
        posicion
        soportes {
          id
          modo
          anchura
          altura
          alineacion
          espesor_soporte
          profundo
          x
          y
          separacion_gancho_x
          separacion_gancho_y
          inicio_gancho_x
          inicio_gancho_y
          articulos {
            id
            nombre
            imagen
            giff
            ean
            altura
            rotacion
            anchura
            laboratorio {
              nombre
            }
            soporte {
              id
            }
            profundo
            x
            y
          }
        }
      }
    }
  }
`

export const DUPLUCAR_PLANOGRAMAS = gql`
  mutation ($id: ID!) {
    planogramaClonarLinealPublicoAPublico(id: $id) {
      id
    }
  }
`

export const INSERTAR_ARTICULO_LINEAL = gql`
  mutation ($id: String!, $input: PlanogramaLinealSoporteArticuloInput) {
    planogramaInsertarArticuloSoporte(id: $id, input: $input) {
      id
    }
  }
`

export const MOVER_ARTICULO_LINEAL = gql`
  mutation ($id: String!, $input: PlanogramaLinealSoporteArticuloInput) {
    planogramaMoverArticuloSoporte(id: $id, input: $input) {
      id
    }
  }
`

export const ACTUALIZAR_ARTICULO_LINEAL = gql`
  mutation ($id: String!, $input: PlanogramaLinealSoporteArticuloInput) {
    planogramaActualizarArticuloSoporte(id: $id, input: $input) {
      id
    }
  }
`

export const ELIMINAR_ARTICULO_SOPORTE = gql`
  mutation ($id: String!, $organizacion_id: ID!) {
    planogramaBorrarArticuloSoporte(
      id: $id
      organizacion_id: $organizacion_id
    ) {
      id
    }
  }
`

export const GET_PHARMACY = gql`
  mutation ($first: Int, $page: Int, $input: FiltrarFarmaciasInput) {
    filtrarFarmaciasPaginado(page: $page, first: $first, input: $input) {
      paginatorInfo {
        lastItem
        lastPage
        total
        count
        hasMorePages
        perPage
        firstItem
        currentPage
      }
      data {
        id
        nombre
        id_sertec
      }
    }
  }
`

export const PLANOGRAMA_BORRAR_ARTICULOS = gql`
  mutation ($id: [ID!]) {
    planogramaBorrarArticulos(id: $id)
  }
`

export const PLANOGRAMA_ACTUALIZAR_DATOS_ARTICULO_BACKOFICE = gql`
  mutation ($id: ID!, $input: PlanogramaArticuloInput) {
    planogramaActualizarDatosArticulo(id: $id, input: $input) {
      id
    }
  }
`

export const PLANOGRAMA_CLONAR_LINEAL_RETAIL = gql`
  mutation ($id: ID!) {
    planogramaClonarLinealRetailBackoffice(id: $id) {
      id
    }
  }
`

export const PLANOGRAMA_CLONAR_LINEAL_FARMACIA = gql`
  mutation ($id: ID!, $organizacion_id: ID) {
    planogramaClonarLinealOrganizacion(
      id: $id
      organizacion_id: $organizacion_id
    ) {
      id
    }
  }
`

export const PLANOGRAMA_ARTICULOS_FILTRO = gql`
  mutation ($filtro: [String!]!) {
    planogramaArticulosFiltro(filtro: $filtro, orden: "nombre") {
      tags {
        filtro
        cantidad
      }
      resultado {
        id
        giff
        nombre
        anchura
        altura
        profundo
        ean
        imagen
        rotacion
        laboratorio {
          nombre
          id
        }
        categoria {
          nombre
        }
      }
    }
  }
`

/**
 * @description Query para obtener los pesos de las categorias
 * @returns {Object} Objeto con los pesos de las categorias
 */
export const GET_CATEGORIES_DIST = gql`
  query {
    categoryDistribution {
      id
      name
      weight_category_pharmacy
      weight_category_market
      evolution_pharmacy
      evolution_market
    }
  }
`
export const CREATE_MASS_PLANOGRAM_BY_CATEGORY = gql`
  mutation ($organization_id: ID!, $input: [PlanogramaLinealMassInput]) {
    createMassPlanogramasByCategory(
      organization_id: $organization_id
      input: $input
    ) {
      id
      nombre
      anchura
      altura
      profundo
      weight_category_pharmacy
      weight_category_market
      evolution_pharmacy
      evolution_market
      categories {
        id
        nombre
      }
      divisiones {
        id
        soportes {
          id
        }
      }
    }
  }
`

export const DELETE_PLANOGRAMAS = gql`
  mutation ($ids: [ID!]) {
    deleteMassPlanogramas(ids: $ids) {
      id
    }
  }
`

export const MARK_AS_MY_PHARMACY = gql`
  mutation ($ids: [ID!]) {
    setPlanogramasToMyPharmacy(ids: $ids) {
      id
    }
  }
`
