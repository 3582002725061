import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  fromPromise,
  createHttpLink,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { getToken } from '../../utils/getToken'
import { refreshToken } from '../../utils/refreshToken'
import { userLogout } from '../../utils/logoutUser'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_URL_ENVIRONMENT_GRAPHQL,
})

const authLink = setContext((_, { headers }) => {
  const token = getToken()
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

/* 5004: token_caducado (renovable), 
5002: Usuario no autenticado, 
5005: token caducado no renovable, 
5006: token no válido */

const errorLink = onError(
  ({ networkError, operation, forward, graphQLErrors }) => {
    const err = { ...networkError }

    if (err?.response?.status === 503) {
      window.location.href = '/maintenance'
    }

    switch (err?.result?.extensions?.code) {
      case 5002:
        return fromPromise(
          refreshToken().catch((error) => {
            if (error === 5002) {
              userLogout()
            }
            return null
          })
        )
      case 5004:
        return fromPromise(
          refreshToken().catch((error) => {
            if (error === 5005 || error === 5006) {
              userLogout()
            }
            return null
          })
        )
          .filter((value) => Boolean(value))
          .flatMap((accessToken) => {
            const oldHeaders = operation.getContext().headers
            // modify the operation context with a new token
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `Bearer ${accessToken}`,
              },
            })
            // retry the request, returning the new observable
            return forward(operation)
          })
    }

    if (Array.isArray(graphQLErrors)) {
      graphQLErrors.forEach(({ extensions }) => {
        switch (extensions?.code) {
          case 5002: {
            userLogout()
          }
          case 5003: {
            userLogout()
          }
          case 5007: {
            userLogout()
          }
          case 5008: {
            userLogout()
          }
        }
      })
    }

    if (networkError) console.log(`[Network error]: ${networkError}`)
  }
)

export const client = new ApolloClient({
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
  connectToDevTools: true,
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
})

export default client
