import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useUserManagement } from '../../hooks/userManagement/useUserManagement'
import { useTranslation } from 'react-i18next'

import Paragraphs from '../../../../components/commons/Paragraphs/Paragraphs'
import Modal from '../../../../components/commons/Modal'
import HeaderPlanogram from '../../modules/PlanogramManager/components/HeaderPlanogram'
import UserManagement from '../../modules/UserManagement/UserManagement'
import Logo from '../../components/Logo'
import usePlanogram from '../../hooks/usePlanograma'
import UserModal from '../../components/UserManagement/Modals'
import { useGroups } from '../../hooks/groupsManagement/useGroups'

const UsersManagement = () => {
  const { t } = useTranslation()
  const [gridApi, setGridApi] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [totalRows, setTotalRows] = useState(0)
  const [totalRowsGroups, setTotalRowsGroups] = useState(0)
  const [openModalDetails, setOpenModalDetails] = useState(null)
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false)
  const [formErrors, setFormErrors] = useState([])
  const organizacion = useSelector((state) => state?.userReducer?.organizacion)
  const isAdmin = useSelector((state) => state?.userReducer?.isAdmin)
  const userRol = isAdmin && isAdmin === 'ADMIN' ? 'ADMIN' : null

  const { getUsersListing, createUserAdmin, deleteUserAdmin, editUserAdmin } =
    useUserManagement(organizacion?.id, userRol)
  const { getGroups } = useGroups()

  const { getFarmaciasListing } = usePlanogram(organizacion?.id, userRol)

  const handleOpenModal = (user) => setOpenModalDetails(user)

  const handleEditOrCreateUser = async (values) => {
    setIsLoading(true)
    let data = null
    const { id } = values

    if ('id' in values) {
      delete values.id
      delete values.perfiles
      const { success, errors } = await editUserAdmin(id, values)
      errors && setFormErrors([...formErrors, ...errors])
      data = success
    } else {
      const { success, errors } = await createUserAdmin(values)
      errors && setFormErrors([...formErrors, ...errors])
      if (!errors) data = success
    }

    if (data) {
      gridApi?.refreshServerSideStore()
      setFormErrors([])
      setOpenModalDetails(false)
    }
    setIsLoading(false)
  }

  const getUsersList = (
    params = null,
    page = 1,
    perPage = 50,
    formattedFilters = null,
    orderBy = null
  ) => {
    let variables = {
      page: page,
      first: perPage,
    }
    if (orderBy) variables.orderBy = orderBy
    if (formattedFilters) variables.filter = formattedFilters
    getUsersListing(variables).then((res) => {
      const { data, paginatorInfo } = res
      if (data) {
        let userListWithRol = data.map((user) => {
          return {
            ...user,
            perfiles: user.perfiles.map((rol) => rol.perfil),
          }
        })

        params &&
          params.success({
            rowData: userListWithRol,
            rowCount: paginatorInfo.total,
          })

        setTotalRows(paginatorInfo.total)
        setIsLoading(false)
        setFormErrors([])
      }
    })
  }

  const handleDeleteUser = (user) => setOpenConfirmDelete(user)
  const tooggleConfirmDelete = () => setOpenConfirmDelete(false)
  const onRowDoubleClicked = (row) => handleOpenModal(row.data)

  const onDeleteAdminUser = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    if (openConfirmDelete) {
      const { success } = await deleteUserAdmin(openConfirmDelete.id)
      if (success) {
        gridApi?.refreshServerSideStore()
        setOpenConfirmDelete(false)
      }
    }
    setIsLoading(false)
    return tooggleConfirmDelete()
  }

  const getGroupsList = (
    params = null,
    page = 1,
    orderBy = null,
    name = null
  ) => {
    let variables = {
      page: page,
      first: 50,
    }
    if (orderBy) variables.orderBy = orderBy
    if (name) variables.name = name

    getGroups(variables)
      .then((res) => {
        const { data, paginatorInfo } = res

        if (data) {
          setIsLoading(false)
          setFormErrors([])
          setTotalRowsGroups(paginatorInfo.total)
          let newData = data
          if (openModalDetails && openModalDetails.groups) {
            newData = data.map((group) => {
              return {
                ...group,
                selected: openModalDetails.groups.some(
                  (g) => g.id === group.id
                ),
              }
            })
          }
          if (params) {
            params.success({
              rowData: newData,
              rowCount: paginatorInfo.total,
            })
          }
        }
      })
      .catch((err) => {
        console.error(err)
        if (params) {
          params.fail()
        }
      })
  }

  return (
    <div className="estanterias tw-h-full tw-w-full">
      {isLoading && (
        <div className="tw-flex tw-flex-col tw-absolute tw-top-0 tw-left-0 tw-bg-white tw-bg-opacity-80 tw-h-full tw-w-full tw-z-10 tw-items-center tw-justify-center">
          <Logo width={100} />
          <Paragraphs>{t('labels.Cargando')}...</Paragraphs>
        </div>
      )}
      <HeaderPlanogram
        openCreateModal={handleOpenModal}
        title={t('titles.Gestión de usuarios')}
      />
      <UserManagement
        setGridApi={setGridApi}
        userRol={userRol}
        handleEditOrCreateUser={handleOpenModal}
        handleDeleteUser={handleDeleteUser}
        setOpenConfirmDelete={setOpenConfirmDelete}
        getUsersList={getUsersList}
        onRowDoubleClicked={onRowDoubleClicked}
        totalRows={totalRows}
      />
      <div className="ContentUsers">
        {openModalDetails && (
          <UserModal
            edit={openModalDetails?.id ? openModalDetails : null}
            role={userRol}
            toggle={() => {
              setOpenModalDetails(null), gridApi?.refreshServerSideStore()
            }}
            id="planograma-configuracion"
            onAccept={handleEditOrCreateUser}
            getFarmaciasListing={getFarmaciasListing}
            formErrors={formErrors}
            getGroupsList={getGroupsList}
            totalRowsGroups={totalRowsGroups}
          />
        )}
      </div>
      {openConfirmDelete?.id && (
        <Modal
          id="users-delete"
          onAccept={onDeleteAdminUser}
          onCancel={tooggleConfirmDelete}
          maximizable={false}
          btnTextAccept={'Eliminar'}
          size="sm"
        >
          <Paragraphs className="tw-text-alert tw-text-center" weight="bold">
            {t('usuarios.¿Está seguro que desea eliminar este usuario?')}
          </Paragraphs>
        </Modal>
      )}
    </div>
  )
}

UsersManagement.propTypes = {}

export default UsersManagement
