import React from 'react'
import PropTypes from 'prop-types'
import Label from '../Label'
import {
  ContainerSwitch,
  InputSwitch,
  LabelSwitch,
  ToggleSwitch,
} from './styles'

const SwitchInput = ({ label, name, id, checked, row, onChage }) => {
  return (
    <div className="switch-input" onClick={onChage}>
      <ContainerSwitch
        className={`container ${row && 'tw-flex tw-items-center'}`}
      >
        {label && <Label className="tw-mr-1" label={label} />}
        <ToggleSwitch className="toggle-switch">
          <InputSwitch
            type="checkbox"
            className="checkbox"
            checked={checked}
            readOnly
            name={name}
            id={id}
          />
          <LabelSwitch className="label" htmlFor={label}>
            <span className={'inner'} />
            <span className="switch" />
          </LabelSwitch>
        </ToggleSwitch>
      </ContainerSwitch>
    </div>
  )
}

SwitchInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  checked: PropTypes.bool,
  row: PropTypes.bool,
  onChage: PropTypes.func,
}

SwitchInput.defaultProps = {
  label: '',
  name: 'name-switch',
  id: 'id-switch',
  checked: false,
  row: false,
}

export default SwitchInput
