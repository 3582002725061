import { lazy, Suspense } from 'react'
import { useSelector } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor } from './infrastructure/redux/store'
import { Routes, BrowserRouter, Route } from 'react-router-dom'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
// COMPONENTS
import UsersManagement from './modules/Planograma/Pages/Users'
import ForgotPasswordScreen from './modules/Auth/pages/ForgotPassword'
import ConfirmationEmailScreen from './modules/Auth/pages/ConfirmationEmailScreen'
import ResetPasswordScreen from './modules/Auth/pages/ResetPasswordScreen'
import CreatePasswordScreen from './modules/Auth/pages/CreatePasswordScreen'
import PIMProductos from './modules/Planograma/Pages/PIMProductos/PIMProductos'
import Maintenance from './modules/Maintenance/maintenance'
import GroupsManagement from './modules/Planograma/Pages/Groups/GroupsManagement'
import ResumeWizard from './modules/Planograma/Pages/Home/ResumeWizard'
import PrivateRoutes from './infrastructure/routes/privateRoutes'
// LAZY COMPONENTS
const Home = lazy(() => import('./components/Home'))
const HomeCreateWizard = lazy(() =>
  import('./modules/Planograma/Pages/Home/HomeCreateWizard')
)
const HomeCategoryDist = lazy(() =>
  import('./modules/Planograma/Pages/Home/HomeCategoryDist')
)
const PlanogramManager = lazy(() =>
  import('./modules/Planograma/modules/PlanogramManager/pages')
)
const CreatorManager = lazy(() =>
  import('./modules/Planograma/components/CreatorManager/pages/CreatorManager')
)
const LoginScreen = lazy(() => import('./modules/Auth/pages/LoginScreen'))
const AuthScreen = lazy(() => import('./modules/Auth/pages/AuthScreen'))

// ROUTES
import PlanogramaRoutes from './infrastructure/routes/Planograma'
import PharmacyRoutes from './infrastructure/routes/Pharmacy'
import LoadingOverlay from './components/LoadingOverlay/LoadingOverlay'

const App = () => {
  const isAdmin = useSelector((state) => state?.userReducer?.isAdmin)
  const rolUser = isAdmin ? isAdmin : false

  return (
    <PersistGate persistor={persistor}>
      <Suspense fallback={<LoadingOverlay />}>
        <BrowserRouter>
          <Routes>
            {/* AUTH */}
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/auth/:token" element={<AuthScreen />} />
            <Route
              excat={true}
              path="/forgot-password"
              element={<ForgotPasswordScreen />}
            />
            <Route
              excat={true}
              path="/email-confirmation-sent"
              element={<ConfirmationEmailScreen />}
            />
            <Route
              path="/reset-password/"
              excat={false}
              element={<ResetPasswordScreen />}
            />
            <Route
              excat={true}
              path="/create-password/"
              element={<CreatePasswordScreen />}
            />

            {/* PLANOGRAMA */}
            <Route path="/" element={<PrivateRoutes />}>
              <Route path="planograma" element={<PlanogramaRoutes />}>
                <Route
                  index
                  path="planograma-manager"
                  element={<PlanogramManager />}
                />
                <Route path="creator/:id" element={<CreatorManager />} />
              </Route>
              {!rolUser && (
                <Route path="home" element={<PharmacyRoutes />}>
                  <Route index element={<Home />} />
                  <Route
                    path="categories-dist-wizard"
                    element={<HomeCategoryDist />}
                  />
                  <Route path="create-wizard" element={<HomeCreateWizard />} />
                  <Route path="resume-wizard" element={<ResumeWizard />} />
                </Route>
              )}

              {/* MY PHARMACY */}
              <Route path={'my-pharmacy'} element={<ResumeWizard />} />

              {/* SETTINGS */}
              <Route path="settings/users" element={<UsersManagement />} />
              <Route path="settings/groups" element={<GroupsManagement />} />

              {/* PRODUCTS */}
              <Route path={'products/list'} element={<PIMProductos />} />
            </Route>

            {/* Maintenance */}
            <Route path={'/maintenance'} element={<Maintenance />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </PersistGate>
  )
}

export default App
