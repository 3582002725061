import React from 'react'
import PropTypes from 'prop-types'

import Button from '../../../../../components/commons/Button/Button'
import Paragraphs from '../../../../../components/commons/Paragraphs/Paragraphs'
import Header from '../../Header'

const NavBar = ({ title = 'Productos', openCreateModal }) => (
  <div className="HeaderPlanogram">
    <Header>
      <div className="tw-flex tw-w-full tw-justify-between tw-items-center tw-pl-4">
        <Paragraphs size="xl" weight="bold">
          {title}
        </Paragraphs>
        <Button
          onClick={openCreateModal}
          label="Nuevo"
          size="small"
          uppercase
        />
      </div>
    </Header>
  </div>
)

NavBar.propTypes = {
  disabledDelete: PropTypes.bool,
  disabledImport: PropTypes.bool,
  search: PropTypes.string,
  onSearch: PropTypes.func,
  rolUser: PropTypes.string,
}

export default NavBar
