import React, { useEffect, useState } from 'react'
import 'react-dropdown-tree-select/dist/styles.css'
import { InputContainer } from '../../../../components/CreatorManager/components/Sidebar/components/ProductFilters/styles'

import Paragraphs from '../../../../../../components/commons/Paragraphs/Paragraphs'
import Button from '../../../../../../components/commons/Button/Button'

import './styles.css'
import { DropDownBox, LoadingDiv } from '../../styles'

export const SidebarFilters = ({
  t,
  setShowMore,
  sidebar,
  setFilters,
  filters,
  filterProducts,
  setLoadMoreSidebar,
  loadMoreSidebar,
  resetFilters,
  getCategories,
  getData,
  selectedCategories,
  setSelectedCategories,
  setSelectedLaboratories,
  selectedLaboratories,
  selectedFamilies,
  setSelectedFamilies,
}) => {
  const [categoriesData, setCategories] = useState([])
  const [inmuCategories, setInmuCategories] = useState([])
  const [familiesData, setFamilies] = useState([])
  const [inmuFamilies, setInmuFamilies] = useState([])
  const [laboratoriesData, setLaboratories] = useState([])
  const [inmuLaboratories, setInmuLaboratories] = useState([])
  const [showMoreFamilies, setShowMoreFamilies] = useState(false)
  const [showMoreLaboratories, setShowMoreLaboratories] = useState(false)
  const [showMoreCategories, setShowMoreCategories] = useState(false)

  useEffect(() => {
    if (sidebar) {
      const { categories, families, laboratories } = sidebar
      let newCats = categories.map((category) => {
        return {
          value: category.id,
          label: category.nombre,
          checked: false,
        }
      })
      setCategories(newCats)
      setInmuCategories(newCats)
      let newFams = families.map((family) => {
        return {
          value: family.id,
          label: family.nombre,
          checked: false,
        }
      })
      setFamilies(newFams)
      setInmuFamilies(newFams)
      let newLabs = laboratories.map((laboratory) => {
        return {
          value: laboratory.id,
          label: laboratory.nombre,
          checked: false,
        }
      })
      setLaboratories(newLabs)
      setInmuLaboratories(newLabs)
    }
  }, [sidebar])

  const onResetFilters = () => {
    setFilters({})
    if (document.getElementById('repofarma_check'))
      document.getElementById('repofarma_check').checked = false
    setCategories([])
    setInmuCategories([])
    setInmuFamilies([])
    setInmuLaboratories([])
    setFamilies([])
    setLaboratories([])
    setShowMore({})
    setShowMoreCategories(false)
    setShowMoreFamilies(false)
    setShowMoreLaboratories(false)
    setSelectedCategories([])
    setSelectedFamilies([])
    setSelectedLaboratories([])
    resetFilters()
  }

  const getSearchedCategories = async (level, search) => {
    return await getCategories({
      variables: { level, search: search ? `%${search}%` : undefined },
    })
  }

  const resetCategories = () => {
    setLoadMoreSidebar('categories')
    setShowMoreCategories(false)
    getData({
      variables: {
        showMore: 'subfamilies',
        numToShow: 5,
        laboratories_id:
          selectedLaboratories?.filter((lab) => lab.checked).length > 0
            ? selectedLaboratories
                ?.filter((lab) => lab.checked)
                .map((lab) => lab.value)
            : undefined,
        families_id:
          selectedFamilies?.filter((fam) => fam.checked).length > 0
            ? selectedFamilies
                ?.filter((fam) => fam.checked)
                .map((fam) => fam.value)
            : undefined,
      },
    }).then(({ data }) => {
      let planogramaArticulosSearch = null
      if (data) {
        planogramaArticulosSearch = data.planogramaArticulosSearch
      }
      if (planogramaArticulosSearch) {
        const { subfamilies } = planogramaArticulosSearch.sidebar
        let newCats = subfamilies.map((category) => {
          let cat = {
            value: category.id,
            label: category.nombre,
            checked: false,
          }

          return cat
        })
        setCategories(newCats)
        setInmuCategories(newCats)
        setLoadMoreSidebar(false)
      }
    })
  }

  // SUBFAMILIES
  const onLoadMoreCategories = () => {
    setLoadMoreSidebar('categories')
    setShowMoreCategories(true)
    if (selectedCategories) {
      getData({
        variables: {
          showMore: 'subfamilies',
          numToShow: 999,
          laboratories_id:
            selectedLaboratories?.filter((lab) => lab.checked).length > 0
              ? selectedLaboratories
                  ?.filter((lab) => lab.checked)
                  .map((lab) => lab.value)
              : undefined,
          families_id:
            selectedFamilies?.filter((fam) => fam.checked).length > 0
              ? selectedFamilies
                  ?.filter((fam) => fam.checked)
                  .map((fam) => fam.value)
              : undefined,
        },
      })
        .then(({ data }) => {
          let planogramaArticulosSearch = null
          if (data) {
            planogramaArticulosSearch = data.planogramaArticulosSearch
          }
          if (planogramaArticulosSearch) {
            const { subfamilies } = planogramaArticulosSearch.sidebar
            let newCats = subfamilies.map((category) => {
              let cat = {
                value: category.id,
                label: category.nombre,
                checked: false,
              }

              return cat
            })
            //filter repeated categories
            let temp = [...categoriesData, ...newCats]
            let filtered = temp.filter(
              (thing, index, self) =>
                index === self.findIndex((t) => t.value === thing.value)
            )
            setCategories(filtered)
          }
        })
        .finally(() => {
          setLoadMoreSidebar(false)
        })
    }
  }

  const onSelectCategory = (e, value) => {
    const { checked } = e.target
    let temp = categoriesData.map((category) => {
      if (category.value === value) {
        category.checked = checked
      }
      return category
    })
    setSelectedCategories(temp)
  }

  const onCategorySearch = (e) => {
    let catCopy2 = [...categoriesData]
    const { value } = e.target
    let searchedCats = catCopy2.filter((category) => {
      return category.label.toLowerCase().includes(value.toLowerCase())
    })
    if (searchedCats.length > 0) {
      setCategories(searchedCats)
    } else {
      getSearchedCategories(3, value).then((res) => {
        if (res.data) {
          const { categoriasPorNivel } = res.data
          if (categoriasPorNivel.length > 0) {
            let temp = categoriasPorNivel.map((category) => {
              return {
                value: category.id,
                label: category.nombre,
              }
            })
            setCategories(temp)
          }
        }
      })
    }
    if (value.length === 0) {
      setCategories(inmuCategories)
    }
  }

  useEffect(() => {
    if (!selectedCategories.length) return
    if (selectedCategories && selectedCategories.some((cat) => cat.checked)) {
      setLoadMoreSidebar(true)
      getData({
        variables: {
          showMore: 'laboratories',
          subfamilies_id: selectedCategories
            .filter((cat) => cat.checked)
            .map((cat) => cat.value),
          families_id:
            selectedFamilies?.filter((fam) => fam.checked).length > 0
              ? selectedFamilies
                  ?.filter((fam) => fam.checked)
                  .map((fam) => fam.value)
              : undefined,
          numToShow: 999,
        },
      })
        .then(({ data }) => {
          let planogramaArticulosSearch = null
          if (data) {
            planogramaArticulosSearch = data.planogramaArticulosSearch
          }
          if (planogramaArticulosSearch) {
            const { laboratories: labs, families: fams } =
              planogramaArticulosSearch.sidebar

            let newLabs = labs.map((lab) => {
              return {
                value: lab.id,
                label: lab.nombre,
                checked: false,
              }
            })
            let selectedLabs = selectedLaboratories.filter((lab) => lab.checked)
            let temp = newLabs.map((lab) => {
              let checked = false
              selectedLabs.forEach((selectedLab) => {
                if (selectedLab.value === lab.value) {
                  checked = true
                }
              })
              return {
                ...lab,
                checked,
              }
            })

            // sort temp by checked
            temp.length &&
              temp.sort((a, b) => {
                if (a.checked && !b.checked) {
                  return -1
                }
                if (!a.checked && b.checked) {
                  return 1
                }
                return 0
              })

            setLaboratories(temp)
            setInmuLaboratories(temp)
          }
        })
        .finally(() => {
          setLoadMoreSidebar(false)
        })
    } else if (!selectedCategories.some((cat) => cat.checked)) {
      if (selectedFamilies.every((cat) => !cat.checked)) {
        resetFamilies()
      } else {
        onLoadMoreFamilies()
        setShowMoreFamilies(false)
      }
      if (selectedCategories.every((cat) => !cat.checked)) {
        resetCategories()
        setShowMoreCategories(false)
        setShowMoreFamilies(false)
      }
      if (selectedLaboratories.every((cat) => !cat.checked)) {
        resetLaboratories()
      }
    }
  }, [selectedCategories])

  //FAMILIES
  const onLoadMoreFamilies = () => {
    setLoadMoreSidebar('families')
    setShowMoreFamilies(true)
    setShowMoreCategories(false)
    if (selectedFamilies) {
      getData({
        variables: {
          showMore: 'families',
          numToShow: 999,
          laboratories_id:
            selectedLaboratories?.filter((lab) => lab.checked).length > 0
              ? selectedLaboratories
                  ?.filter((lab) => lab.checked)
                  .map((lab) => lab.value)
              : undefined,
          subfamilies_id:
            selectedCategories?.filter((cat) => cat.checked).length > 0
              ? selectedCategories
                  ?.filter((cat) => cat.checked)
                  .map((cat) => cat.value)
              : undefined,
        },
      })
        .then(({ data }) => {
          let planogramaArticulosSearch = null
          if (data) {
            planogramaArticulosSearch = data.planogramaArticulosSearch
          }
          if (planogramaArticulosSearch) {
            const { families } = planogramaArticulosSearch.sidebar
            let newFamilies = families.map((fam) => {
              let cat = {
                value: fam.id,
                label: fam.nombre,
                checked: false,
              }

              return cat
            })
            let temp = newFamilies.map((fam) => {
              let checked = false
              selectedFamilies.forEach((selectedFam) => {
                if (selectedFam.value === fam.value && selectedFam.checked) {
                  checked = true
                }
              })
              return {
                ...fam,
                checked,
              }
            })

            // sort temp by checked
            temp.length &&
              temp.sort((a, b) => {
                if (a.checked && !b.checked) {
                  return -1
                }
                if (!a.checked && b.checked) {
                  return 1
                }
                return 0
              })

            setFamilies(temp)
            setInmuFamilies(temp)
          }
        })
        .finally(() => {
          setLoadMoreSidebar(false)
        })
    }
  }

  const onSelectFamily = (e, value) => {
    const { checked } = e.target
    let temp = familiesData.map((family) => {
      if (family.value === value) {
        family.checked = checked
      }
      return family
    })
    setSelectedFamilies(temp)
  }

  const onFamiliesSearch = (e) => {
    let familiesCopy = [...inmuFamilies]
    const { value } = e.target
    let searchedFams = familiesData.filter((family) => {
      return family.label.toLowerCase().includes(value.toLowerCase())
    })
    if (searchedFams.length > 0) {
      setFamilies(searchedFams)
    } else {
      getSearchedCategories(2, value).then((res) => {
        if (res.data) {
          const { categoriasPorNivel } = res.data
          if (categoriasPorNivel.length > 0) {
            let temp = categoriasPorNivel.map((category) => {
              return {
                value: category.id,
                label: category.nombre,
              }
            })
            setFamilies(temp)
            setInmuFamilies(temp)
          }
        }
      })
    }
    if (value.length === 0) {
      setFamilies(familiesCopy)
    }
  }

  const resetFamilies = () => {
    setLoadMoreSidebar('families')
    setShowMoreCategories(false)
    getData({
      variables: {
        showMore: 'families',
        numToShow: 5,
        laboratories_id:
          selectedLaboratories?.filter((lab) => lab.checked).length > 0
            ? selectedLaboratories
                ?.filter((lab) => lab.checked)
                .map((lab) => lab.value)
            : undefined,
        subfamilies_id:
          selectedCategories?.filter((cat) => cat.checked).length > 0
            ? selectedCategories
                ?.filter((cat) => cat.checked)
                .map((cat) => cat.value)
            : undefined,
      },
    }).then(({ data }) => {
      let planogramaArticulosSearch = null
      if (data) {
        planogramaArticulosSearch = data.planogramaArticulosSearch
      }
      if (planogramaArticulosSearch) {
        const { families } = planogramaArticulosSearch.sidebar
        let newCats = families.map((category) => {
          let cat = {
            value: category.id,
            label: category.nombre,
            checked: false,
          }

          return cat
        })
        setFamilies(newCats)
        setLoadMoreSidebar(false)
      }
    })
  }

  useEffect(() => {
    if (!selectedFamilies.length) return
    if (selectedFamilies && selectedFamilies.some((fam) => fam.checked)) {
      setLoadMoreSidebar(true)
      getData({
        variables: {
          showMore: 'laboratories',
          families_id: selectedFamilies
            .filter((fam) => fam.checked)
            .map((fam) => fam.value),
          subfamilies_id:
            selectedCategories?.filter((fam) => fam.checked).length > 0
              ? selectedCategories
                  ?.filter((fam) => fam.checked)
                  .map((fam) => fam.value)
              : undefined,
          numToShow: 999,
        },
      })
        .then(({ data }) => {
          let planogramaArticulosSearch = null
          if (data) {
            planogramaArticulosSearch = data.planogramaArticulosSearch
          }
          if (planogramaArticulosSearch) {
            const { laboratories: labs, subfamilies: cats } =
              planogramaArticulosSearch.sidebar
            let newLabs = labs.map((lab) => {
              let newLab = {
                value: lab.id,
                label: lab.nombre,
                checked: false,
              }
              return newLab
            })
            let selectedLabs = selectedLaboratories.filter((lab) => lab.checked)
            let tempLabs = newLabs.map((fam) => {
              let checked = false
              selectedLabs.forEach((selectedLab) => {
                if (selectedLab.value === fam.value) {
                  checked = true
                }
              })
              return {
                ...fam,
                checked,
              }
            })

            //add selectedLabs to tempLabs
            selectedLabs.forEach((selectedLab) => {
              let found = false
              tempLabs.forEach((tempLab) => {
                if (tempLab.value === selectedLab.value) {
                  found = true
                }
              })
              if (!found) {
                tempLabs.push(selectedLab)
              }
            })

            // sort temp by checked
            tempLabs.length &&
              tempLabs.sort((a, b) => {
                if (a.checked && !b.checked) {
                  return -1
                }
                if (!a.checked && b.checked) {
                  return 1
                }
                return 0
              })

            setLaboratories(tempLabs)
            setInmuLaboratories(tempLabs)

            let newCats = cats.map((cat) => {
              let newCat = {
                value: cat.id,
                label: cat.nombre,
                checked: false,
              }
              return newCat
            })
            let selectedCats = selectedCategories.filter((cat) => cat.checked)
            let tempCat = newCats.map((fam) => {
              let checked = false
              selectedCats.forEach((selectedCat) => {
                if (selectedCat.value === fam.value) {
                  checked = true
                }
              })
              return {
                ...fam,
                checked,
              }
            })

            //add selectedCats to tempCat
            selectedCats.forEach((selectedCat) => {
              let found = false
              tempCat.forEach((tempCat) => {
                if (tempCat.value === selectedCat.value) {
                  found = true
                }
              })
              if (!found) {
                tempCat.push(selectedCat)
              }
            })

            // sort temp by checked
            tempCat.length &&
              tempCat.sort((a, b) => {
                if (a.checked && !b.checked) {
                  return -1
                }
                if (!a.checked && b.checked) {
                  return 1
                }
                return 0
              })

            setCategories(tempCat)
            setInmuCategories(tempCat)
          }
        })
        .finally(() => {
          setLoadMoreSidebar(false)
        })
    } else if (!selectedFamilies.some((fam) => fam.checked)) {
      if (selectedCategories.every((cat) => !cat.checked)) {
        resetCategories()
      }
      if (selectedLaboratories.every((lab) => !lab.checked)) {
        resetLaboratories()
      }
    }
  }, [selectedFamilies])

  //LABORATORIES
  const onSelectLaboratories = (e, value) => {
    const { checked } = e.target
    let temp = laboratoriesData.map((laboratory) => {
      if (laboratory.value === value) {
        laboratory.checked = checked
      }
      return laboratory
    })
    setSelectedLaboratories(temp)
  }

  const onLoadMoreLaboratories = () => {
    setLoadMoreSidebar('laboratories')
    setShowMoreLaboratories(true)
    setShowMoreCategories(false)
    setShowMoreFamilies(false)
    if (selectedLaboratories) {
      getData({
        variables: {
          showMore: 'laboratories',
          numToShow: 999,
          subfamilies_id:
            selectedCategories?.filter((cat) => cat.checked).length > 0
              ? selectedCategories
                  ?.filter((cat) => cat.checked)
                  .map((cat) => cat.value)
              : undefined,
          families_id:
            selectedFamilies?.filter((fam) => fam.checked).length > 0
              ? selectedFamilies
                  ?.filter((fam) => fam.checked)
                  .map((fam) => fam.value)
              : undefined,
        },
      })
        .then(({ data }) => {
          let planogramaArticulosSearch = null
          if (data) {
            planogramaArticulosSearch = data.planogramaArticulosSearch
          }
          if (planogramaArticulosSearch) {
            const { laboratories } = planogramaArticulosSearch.sidebar
            let newCats = laboratories.map((laboratory) => {
              let cat = {
                value: laboratory.id,
                label: laboratory.nombre,
                checked: false,
              }

              return cat
            })
            setLaboratories([...selectedLaboratories, ...newCats])
          }
        })
        .finally(() => {
          setLoadMoreSidebar(false)
        })
    }
  }

  const onLaboratorySearch = (e) => {
    let laboratoriesCopy = [...inmuLaboratories]
    const { value } = e.target
    let searchedLabs = laboratoriesData.filter((laboratory) => {
      return laboratory.label.toLowerCase().includes(value.toLowerCase())
    })
    if (searchedLabs.length > 0) {
      setLaboratories(searchedLabs)
    } else {
      getSearchedCategories(1, value).then((res) => {
        if (res.data) {
          const { categoriasPorNivel } = res.data
          if (categoriasPorNivel.length > 0) {
            let temp = categoriasPorNivel.map((category) => {
              return {
                value: category.id,
                label: category.nombre,
              }
            })
            setLaboratories(temp)
          }
        }
      })
    }
    if (value.length === 0) {
      setLaboratories(laboratoriesCopy)
    }
  }

  const resetLaboratories = () => {
    setLoadMoreSidebar('laboratories')
    setShowMoreCategories(false)
    getData({
      variables: {
        showMore: 'laboratories',
        numToShow: 5,
        subfamilies_id:
          selectedCategories?.filter((cat) => cat.checked).length > 0
            ? selectedCategories
                ?.filter((cat) => cat.checked)
                .map((cat) => cat.value)
            : undefined,
        families_id:
          selectedFamilies?.filter((fam) => fam.checked).length > 0
            ? selectedFamilies
                ?.filter((fam) => fam.checked)
                .map((fam) => fam.value)
            : undefined,
      },
    }).then(({ data }) => {
      let planogramaArticulosSearch = null
      if (data) {
        planogramaArticulosSearch = data.planogramaArticulosSearch
      }
      if (planogramaArticulosSearch) {
        const { laboratories } = planogramaArticulosSearch.sidebar
        let newCats = laboratories.map((category) => {
          let cat = {
            value: category.id,
            label: category.nombre,
            checked: false,
          }

          return cat
        })
        setLaboratories(newCats)
        setLoadMoreSidebar(false)
      }
    })
  }

  useEffect(() => {
    if (!selectedLaboratories.length) return
    if (
      selectedLaboratories &&
      selectedLaboratories.some((lab) => lab.checked)
    ) {
      setLoadMoreSidebar(true)
      getData({
        variables: {
          showMore: 'families',
          laboratories_id: selectedLaboratories
            .filter((lab) => lab.checked)
            .map((lab) => lab.value),
          subfamilies_id:
            selectedCategories?.filter((fam) => fam.checked).length > 0
              ? selectedCategories
                  ?.filter((fam) => fam.checked)
                  .map((fam) => fam.value)
              : undefined,
          numToShow: 999,
        },
      })
        .then(({ data }) => {
          let planogramaArticulosSearch = null
          if (data) {
            planogramaArticulosSearch = data.planogramaArticulosSearch
          }
          if (planogramaArticulosSearch) {
            const { families: fams, subfamilies: cats } =
              planogramaArticulosSearch.sidebar
            let newFamilies = fams.map((fam) => {
              return {
                value: fam.id,
                label: fam.nombre,
                checked: false,
              }
            })
            let selectedFams = selectedFamilies.filter((fam) => fam.checked)
            let temp = newFamilies.map((fam) => {
              let checked = false
              selectedFams.forEach((selectedFam) => {
                if (selectedFam.value === fam.value) {
                  checked = true
                }
              })
              return {
                ...fam,
                checked,
              }
            })

            // add selectedFams to temp
            selectedFams.forEach((selectedFam) => {
              let found = false
              temp.forEach((tempFam) => {
                if (tempFam.value === selectedFam.value) {
                  found = true
                }
              })
              if (!found) {
                temp.push(selectedFam)
              }
            })

            //sort temp by checked
            temp.length &&
              temp.sort((a, b) => {
                if (a.checked && !b.checked) {
                  return -1
                }
                if (!a.checked && b.checked) {
                  return 1
                }
                return 0
              })

            setFamilies(temp)
            setInmuFamilies(temp)

            let newCats = cats.map((cat) => {
              let newCat = {
                value: cat.id,
                label: cat.nombre,
                checked: false,
              }
              return newCat
            })
            let selectedCats = selectedCategories.filter((cat) => cat.checked)
            let tempCat = newCats.map((fam) => {
              let checked = false
              selectedCats.forEach((selectedCat) => {
                if (selectedCat.value === fam.value) {
                  checked = true
                }
              })
              return {
                ...fam,
                checked,
              }
            })

            // add selectedCats to tempCat
            selectedCats.forEach((selectedCat) => {
              let found = false
              tempCat.forEach((tempCat) => {
                if (tempCat.value === selectedCat.value) {
                  found = true
                }
              })
              if (!found) {
                tempCat.push(selectedCat)
              }
            })
            //sort temp by checked
            tempCat.length &&
              tempCat.sort((a, b) => {
                if (a.checked && !b.checked) {
                  return -1
                }
                if (!a.checked && b.checked) {
                  return 1
                }
                return 0
              })

            setCategories(tempCat)
            setInmuCategories(tempCat)
          }
        })
        .finally(() => setLoadMoreSidebar(false))
    } else if (!selectedLaboratories.some((lab) => lab.checked)) {
      if (selectedFamilies.every((cat) => !cat.checked)) {
        resetFamilies()
      }
      if (selectedLaboratories.every((cat) => !cat.checked)) {
        resetLaboratories()
      }
      if (selectedCategories.every((cat) => !cat.checked)) {
        resetCategories()
      }
    }
  }, [selectedLaboratories])

  return (
    <div
      className="sidebar-filters"
      style={{ display: 'inline-block', width: '100%' }}
    >
      <InputContainer style={{ border: 'none' }}>
        <Paragraphs size="lg" weight="bold">
          {t('labels.Familia')}
        </Paragraphs>
        <DropDownBox
          className="sidebar-filters tw-bg-transparent tw-outline-none tw-flex-1 tw-min-w-100 tw-mt-2 tw-mb-2 tw-overflow-x-hidden tw-overflow-y-scroll"
          showMore={showMoreFamilies}
          style={familiesData?.length < 4 ? { height: 'auto' } : {}}
        >
          {loadMoreSidebar === 'families' || loadMoreSidebar ? (
            <LoadingDiv>
              <Paragraphs>{t('labels.Cargando')}...</Paragraphs>
            </LoadingDiv>
          ) : null}

          <input
            type="search"
            placeholder={t('placeholders.Buscar')}
            onChange={(e) => onFamiliesSearch(e)}
            className="tw-flex-1 tw-w-full tw-px-1 tw-pb-1"
          />
          {familiesData?.length > 0 ? (
            familiesData.map((family) => {
              return (
                <label className="tw-flex tw-mb-1" key={family.value}>
                  <input
                    type="checkbox"
                    value={family.label}
                    onChange={(e) => onSelectFamily(e, family.value)}
                    checked={family.checked}
                    style={{
                      minWidth: '19px',
                      minHeight: '19px',
                    }}
                  />
                  <Paragraphs size="sm" className="tw-ml-1">
                    {family.label}
                  </Paragraphs>
                </label>
              )
            })
          ) : (
            <Paragraphs>{t('placeholders.No hay resultados')}</Paragraphs>
          )}
        </DropDownBox>
        <div className="load-more">
          <button className="tw-block tw-ml-4 tw-italic tw-mb-4">
            {!showMoreFamilies ? (
              <p
                onClick={() => {
                  onLoadMoreFamilies()
                }}
              >
                {t('placeholders.Mostrar todos')}
              </p>
            ) : (
              <p
                onClick={() => {
                  setShowMoreFamilies(false)
                }}
              >
                {t('placeholders.Mostrar menos')}
              </p>
            )}
          </button>
        </div>

        <Paragraphs size="lg" weight="bold">
          {t('labels.subfamilia')}
        </Paragraphs>
        <DropDownBox
          className="sidebar-filters tw-bg-transparent tw-outline-none tw-flex-1 tw-min-w-100 tw-mt-2 tw-mb-2 tw-overflow-x-hidden tw-overflow-y-scroll"
          showMore={showMoreCategories}
          style={categoriesData?.length < 4 ? { height: 'auto' } : {}}
        >
          {loadMoreSidebar === 'categories' || loadMoreSidebar ? (
            <LoadingDiv>
              <Paragraphs>{t('labels.Cargando')}...</Paragraphs>
            </LoadingDiv>
          ) : null}
          <input
            type="search"
            placeholder={t('placeholders.Buscar')}
            onChange={(e) => onCategorySearch(e)}
            className="tw-flex-1 tw-w-full tw-px-1 tw-pb-1"
          />
          {categoriesData && categoriesData.length > 0
            ? categoriesData.map((category) => {
                return (
                  <label className="tw-flex tw-mb-1" key={category.value}>
                    <input
                      type="checkbox"
                      value={category.label}
                      onChange={(e) => onSelectCategory(e, category.value)}
                      checked={category.checked}
                      style={{
                        minWidth: '19px',
                        minHeight: '19px',
                      }}
                    />
                    <Paragraphs size="sm" className="tw-ml-1">
                      {category.label}
                    </Paragraphs>
                  </label>
                )
              })
            : null}
        </DropDownBox>
        <div className="load-more">
          <button className="tw-block tw-ml-4 tw-italic tw-mb-4">
            {!showMoreCategories ? (
              <p
                onClick={() => {
                  onLoadMoreCategories()
                }}
              >
                {t('placeholders.Mostrar todos')}
              </p>
            ) : (
              <p
                onClick={() => {
                  setShowMoreCategories(false)
                }}
              >
                {t('placeholders.Mostrar menos')}
              </p>
            )}
          </button>
        </div>

        <Paragraphs size="lg" weight="bold">
          {t('titles.Laboratorio')}
        </Paragraphs>
        <DropDownBox
          className="sidebar-filters tw-bg-transparent tw-outline-none tw-flex-1 tw-min-w-100 tw-mt-2 tw-mb-2 tw-overflow-x-hidden tw-overflow-y-scroll"
          showMore={showMoreLaboratories}
          style={laboratoriesData?.length < 4 ? { height: 'auto' } : {}}
        >
          {loadMoreSidebar === 'laboratories' || loadMoreSidebar ? (
            <LoadingDiv>
              <Paragraphs>{t('labels.Cargando')}...</Paragraphs>
            </LoadingDiv>
          ) : null}

          <input
            type="search"
            placeholder={t('placeholders.Buscar')}
            onChange={(e) => onLaboratorySearch(e)}
            className="tw-flex-1 tw-w-full tw-px-1 tw-pb-1"
          />
          {laboratoriesData && laboratoriesData.length > 0
            ? laboratoriesData.map((laboratory) => {
                return (
                  <label className="tw-flex tw-mb-1" key={laboratory.value}>
                    <input
                      type="checkbox"
                      value={laboratory.label}
                      onChange={(e) =>
                        onSelectLaboratories(e, laboratory.value)
                      }
                      checked={laboratory.checked}
                      style={{
                        minWidth: '19px',
                        minHeight: '19px',
                      }}
                    />
                    <Paragraphs size="sm" className="tw-ml-1">
                      {laboratory.label}
                    </Paragraphs>
                  </label>
                )
              })
            : null}
        </DropDownBox>
        <div className="load-more">
          <button className="tw-block tw-ml-4 tw-italic tw-mb-4">
            {!showMoreLaboratories ? (
              <p
                onClick={() => {
                  onLoadMoreLaboratories()
                }}
              >
                {t('placeholders.Mostrar todos')}
              </p>
            ) : (
              <p
                onClick={() => {
                  setShowMoreLaboratories(false)
                }}
              >
                {t('placeholders.Mostrar menos')}
              </p>
            )}
          </button>
        </div>

        <InputContainer
          className="tw-relative"
          style={{
            marginBottom: '2rem',
          }}
        >
          <Paragraphs size="lg" weight="bold">
            Filtros
          </Paragraphs>
          <div className="sidebar-filters tw-bg-transparent tw-outline-none tw-flex-1 tw-min-w-100 tw-mt-2">
            <div className="tw-flex">
              <input
                type="checkbox"
                name="is_fedefarma"
                id="fedefarma_check"
                onClick={(e) => {
                  if (e.target.checked) {
                    document.getElementById('repofarma_check').checked = false
                    document.getElementById(
                      'is_my_pharmacy_check'
                    ).checked = false
                  }
                  setFilters({
                    ...filters,
                    is_fedefarma: e.target.checked,
                    is_my_pharmacy: false,
                  })
                }}
              />
              <label
                htmlFor="is_fedefarma"
                className="tw-ml-2 tw-cursor-pointer"
              >
                {t('labels.Fedefarma')}
              </label>
            </div>
            <div className="tw-flex">
              <input
                type="checkbox"
                name="is_repofarma"
                id="repofarma_check"
                onClick={(e) => {
                  if (e.target.checked) {
                    setFilters({
                      ...filters,
                      is_fedefarma: false,
                      is_my_pharmacy: false,
                    })
                    document.getElementById('fedefarma_check').checked = false
                    document.getElementById(
                      'is_my_pharmacy_check'
                    ).checked = false
                  } else {
                    delete filters.is_fedefarma
                  }
                }}
              />
              <label
                htmlFor="is_repofarma"
                className="tw-ml-2 tw-cursor-pointer"
              >
                {t('labels.Repofarma')}
              </label>
            </div>
            <div className="tw-flex">
              <input
                type="checkbox"
                name="is_my_pharmacy"
                id="is_my_pharmacy_check"
                onClick={(e) => {
                  if (e.target.checked) {
                    document.getElementById('fedefarma_check').checked = false
                    document.getElementById('repofarma_check').checked = false
                    delete filters.is_fedefarma
                  }
                  setFilters({
                    ...filters,
                    is_my_pharmacy: e.target.checked,
                  })
                }}
              />
              <label
                htmlFor="is_my_pharmacy"
                className="tw-ml-2 tw-cursor-pointer"
              >
                {t('labels.Mi farmacia')}
              </label>
            </div>
          </div>
        </InputContainer>

        <InputContainer className="tw-relative">
          <Button
            label={'Resetear filtros'}
            size="full"
            className="tw-m-auto"
            onClick={() => onResetFilters()}
          />
        </InputContainer>

        <InputContainer
          className="tw-relative"
          style={{
            marginBottom: '2rem',
          }}
        >
          <Button
            label={'Aplicar'}
            size="full"
            className="tw-m-auto"
            onClick={() => filterProducts()}
          />
        </InputContainer>
      </InputContainer>
    </div>
  )
}
