import { useState } from 'react'
import useWindowSize from '../../../../modules/Planograma/hooks/useWindowsSize'

export const useModal = ({ customWidth, size }) => {
  const { height, width } = useWindowSize()

  const sizes = {
    sm: 300,
    md: width > 550 ? 550 : width - 200,
    lg: width > 800 ? 800 : width - 200,
    xl: width > 1140 ? 1340 : width - 200,
    custom: width > customWidth ? customWidth : width - 200,
  }

  const [minimized, setMinimized] = useState(false)
  const [whSized, setWhSized] = useState({
    width: sizes[size] + 'px',
    height: 'auto',
  })
  const [positions, setPositions] = useState({
    x: 0,
    y: 0,
  })

  const [lastPositions, setlastPositions] = useState({
    x: 0,
    y: 0,
  })

  /**
   * Setea la posicion inicial del modal
   * @author   ehernandez
   * @param    {Number} modalHeight
   * @param    {String} verticalAlign top - center - bottom
   */

  const setInitialPosition = (modalHeight, verticalAlign) => {
    let y = 0
    const x = width / 2 - sizes[size] / 2

    switch (verticalAlign) {
      case 'center':
        y = height / 2 - modalHeight / 2
        break
      case 'top':
        y = 10
        break
      default:
        y = 100
        break
    }

    setPositions({ x, y })
  }

  const maximizeModal = () => {
    if (!minimized) {
      setWhSized({
        width: '100vw',
        height: '100vh',
      })
      setPositions({
        x: 0,
        y: 0,
      })
      setlastPositions({
        x: positions.x,
        y: positions.y,
      })
    } else {
      setWhSized({
        width: sizes[size] + 'px',
        height: 'auto',
      })
      setPositions({
        x: lastPositions.x,
        y: lastPositions.y,
      })
    }
    setMinimized(!minimized)
  }
  const onSetSizes = () => {
    setWhSized({
      width: sizes[size] + 'px',
      height: 'auto',
    })
  }

  /**
   * Funcion que se ejecuta para guardar las nuevas posiciones al terminar de arrastrar
   * @author   ehernandez
   * @param    {Object} evt Evento del dom
   * @param    {Object} d
   * @param    {Number} d.x
   * @param    {Number} d.y
   * @param    {Number} modalHeight
   */
  const onDragStopped = (evt, d, modalHeight) => {
    let x = d.x < 0 ? 0 : d.x
    let y = d.y < 0 ? 0 : d.y
    if (x > 0 && d.x + sizes[size] > width) {
      x = width - sizes[size]
    }

    if (y > 0 && d.y + modalHeight > height) y = height - modalHeight

    setPositions({ x, y })
  }

  return {
    maximizeModal,
    minimized,
    positions,
    setInitialPosition,
    onDragStopped,
    whSized,
    onSetSizes,
  }
}
