import styled from 'styled-components'

const sizes = {
  mini: {
    height: '30px',
    with: '100px',
    full: '100%',
  },
  small: {
    height: '44px',
    with: '100px',
  },
  medium: {
    height: '44px',
    with: '200px',
  },
  full: {
    height: '44px',
    with: '100%',
  },
}

export const Container = styled.button`
  max-width: ${(props) => sizes[props.size].with};
  height: ${(props) => sizes[props.size].height};
  outline: none !important;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  &.expansible:hover .label {
    text-decoration: underline;
  }
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`
