import React from 'react'

import Button from '../../../../components/commons/Button/Button'
import Paragraphs from '../../../../components/commons/Paragraphs/Paragraphs'

import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

const ResetPassword = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const goToResetPassword = () => {
    navigate('/create-password', {
      state: { token: location.search.split('=')[1] },
    })
  }

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-flex-1 tw-h-full">
      <div className="tw-p-6">
        <div className="tw-p-4 tw-text-center">
          <Paragraphs weight="bold" size="2xl">
            {t('login.Restaurar tu contraseña')}
          </Paragraphs>
        </div>
        <div className="tw-w-96 tw-text-center">
          <Paragraphs>{t('login.Cambiar contraseña')}</Paragraphs>
        </div>
      </div>
      <Button
        label={t('actions.Cambiar')}
        uppercase
        onClick={goToResetPassword}
      />
    </div>
  )
}

export default ResetPassword
