import IcMinimize from './IcMinimize'
import IcSearch from './IcSearch'
import IcClose from './IcClose'
import IcPdf from './IcPdf'
import IcRotateLeft from './IcRotateLeft'
import IcRotateRight from './IcRotateRight'
import IcEdit from './IcEdit'
import IcDelete from './IcDelete'
import IcCloseBold from './IcCloseBold'

const Icons = {
  IcMinimize,
  IcSearch,
  IcClose,
  IcPdf,
  IcRotateLeft,
  IcRotateRight,
  IcEdit,
  IcDelete,
  IcCloseBold,
}

export default Icons
