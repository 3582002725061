import { format } from 'date-fns'

/**
 * @author ehernandez
 * @description Columna customizada para mostrar fechas, por defecto muestra un formato dd/MM/yyyy HH:mm:ss, pero se puede personalizar
 * si especificamos el parámetro format un formato especifico este se usara para mostrar la fecha con formato custom
 */

export const date = {
  filter: 'agDateColumnFilter',
  cellEditor: 'agCellDate',
  // /**
  //  * @author ehernandez
  //  * @description Formatea la fecha para que se pueda ordenar de mayor a menor o viceversa
  //  */
  cellRenderer: (params) => {
    const { value, colDef } = params
    if (!value) {
      return ''
    }

    const date = new Date(value)
    if (date > 0) {
      const formatted =
        colDef?.cellRendererParams?.format || 'dd/MM/yyyy HH:mm:ss'
      return value && `<div>${format(date, formatted)}</div>`
    }
    return ''
  },
  /**
   * @author ehernandez
   * @description Formatea la fecha para que se pueda ordenar de mayor a menor o viceversa
   */
  filterParams: {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
      const dateAsString = cellValue
      if (dateAsString == null) return -1
      const dateParts = dateAsString.split('-')
      const day = Number(dateParts[2])
      const month = Number(dateParts[1]) - 1
      const year = Number(dateParts[0])
      const cellDate = new Date(year, month, day)

      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1
      }
    },
    browserDatePicker: true,
    minValidYear: 2000
  }
}
