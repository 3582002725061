const farmacias = [
  {
    field: 'id',
    headerName: 'Id',
    width: 100,
    suppressMovable: true,
  },
  {
    field: 'nombre',
    headerName: 'Nombre',
    flex: 1,
    colId: 'nombre',
    type: 'text',
    suppressMovable: true,
    type: 'text',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    sortable: true,
  },
]

const planogramas = [
  {
    colId: 'nombre',
    field: 'nombre',
    headerName: 'Nombre',
    width: 400,
    type: 'text',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    sortable: true,
    cellStyle: { cursor: 'pointer' },
    cellRenderer: 'agLabel',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    showDisabledCheckboxes: true,
  },
  {
    colId: 'tipo',
    field: 'is_my_phamracy',
    headerName: 'Tipo',
    width: 100,
    type: 'text',
    cellRenderer: 'agBadge',
  },
  {
    colId: 'blocked',
    field: 'blocked',
    headerName: 'Bloqueado',
    width: 120,
    type: 'text',
    sortable: true,
    floatingFilter: false,
    cellRenderer: 'lockIcon',
  },
  {
    colId: 'destacado',
    field: 'destacado',
    headerName: 'Destacado',
    cellRenderer: 'featuredIcon',
    floatingFilter: false,
    width: 120,
    type: 'boolean',
    sortable: true,
    filterParams: {
      values: ['true', 'false'],
    },
  },
  {
    colId: 'estado',
    field: 'estado',
    headerName: 'Estado',
    width: 120,
    type: 'boolean',
    sortable: true,
    cellRenderer: (params) => {
      const { value } = params
      return value === 'finalizado' ? 'Finalizado' : 'Borrador'
    },
    filterParams: {
      values: ['finalizado', 'borrador'],
    },
    filter: true,
    floatingFilter: true,
    cellStyle: { cursor: 'pointer' },
    admin: true,
  },
  {
    colId: 'organizacion',
    field: 'organizacion.nombre',
    headerName: 'Farmacia',
    width: 200,
    type: 'text',
    filter: 'agTextColumnFilter',
    cellStyle: { cursor: 'pointer' },
  },
  {
    colId: 'publico',
    field: 'publico',
    headerName: 'Publico',
    width: 110,
    type: 'boolean',
    sortable: true,
    floatingFilter: true,
    filterParams: {
      values: ['true', 'false'],
    },
    filter: true,
    cellStyle: { cursor: 'pointer' },
    admin: true,
  },
  {
    colId: 'col-updated',
    field: 'updated_at',
    type: 'date',
    floatingFilter: true,
    headerName: 'Última modificación',
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: (dateFromFilter, cellValue) => {
        cellValue = cellValue.split(' ')[0]
        if (cellValue === null) {
          return 0
        }
        const dateParts = cellValue.split('-')
        const day = Number(dateParts[2])
        const month = Number(dateParts[1]) - 1
        const year = Number(dateParts[0])
        const cellDate = new Date(year, month, day)
        if (cellDate < dateFromFilter) {
          return -1
        } else if (cellDate > dateFromFilter) {
          return 1
        }
        return 0
      },
    },
    cellStyle: { cursor: 'pointer' },
  },
  {
    colId: 'id',
    field: 'id',
    headerName: 'Acciones',
    cellRenderer: 'agActions',
    type: 'rightAligned',
    pinned: 'right',
  },
]

const users = [
  {
    colId: 'nombre',
    field: 'nombre',
    headerName: 'Nombre',
    width: 300,
    floatingFilter: true,
    sortable: true,
    filter: 'agTextColumnFilter',
    cellStyle: { cursor: 'pointer' },
  },
  {
    colId: 'apellidos',
    field: 'apellidos',
    headerName: 'Apellidos',
    width: 300,
    type: 'text',
    floatingFilter: true,
    sortable: true,
    filter: 'agTextColumnFilter',
    cellStyle: { cursor: 'pointer' },
  },
  {
    colId: 'email',
    field: 'email',
    headerName: 'Correo electrónico',
    width: 300,
    type: 'text',
    floatingFilter: true,
    sortable: true,
    filter: 'agTextColumnFilter',
    cellStyle: { cursor: 'pointer' },
  },
  {
    colId: 'perfil',
    field: 'perfiles',
    headerName: 'Perfiles',
    width: 300,
    type: 'text',
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    cellStyle: { cursor: 'pointer' },
    cellRenderer: (params) => {
      return Array.isArray(params.value)
        ? params.value.map((p) => (p.perfil ? p.perfil : p)).join(', ')
        : params.value
    },
  },
]
const usersPharmacys = [
  {
    field: 'nombre',
    headerName: 'Nombre',
    colId: 'nombre',
    type: 'text',
    floatingFilter: false,
  },
  {
    field: 'farmacias',
    headerName: 'Asignar farmacia',
    colId: 'id',
    floatingFilter: false,
    checkboxSelection: true,
    cellClass: 'cellClass',
    rowDrag: false,
    headerClass: 'headerClass',
  },
]

const groups = [
  {
    colId: 'name',
    field: 'name',
    headerName: 'Nombre',
    width: 400,
    type: 'text',
    floatingFilter: true,
    sortable: true,
    filter: 'agTextColumnFilter',
    cellStyle: { cursor: 'pointer' },
  },
  {
    field: 'group_pharmacys',
    headerName: 'Grupos de Farmacias',
  },
]

const groupsPharmacys = [
  {
    field: 'nombre',
    headerName: 'Nombre',
    colId: 'nombre',
    type: 'text',
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    width: 600,
    cellClass: 'tw-cursor-pointer',
  },
  {
    field: 'selected',
    headerName: 'Farmacias asignadas',
    colId: 'pharmacys',
    type: 'boolean',
    floatingFilter: false,
    sortable: true,
    cellStyle: { zIndex: -1 },
    headerComponent: 'checkboxSelection',
  },
]

const groupsUsers = [
  {
    colId: 'nombre',
    field: 'nombre',
    headerName: 'Nombre',
    width: 120,
    type: 'text',
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    cellStyle: { cursor: 'pointer' },
  },
  {
    colId: 'apellidos',
    field: 'apellidos',
    headerName: 'Apellidos',
    width: 120,
    type: 'text',
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    cellStyle: { cursor: 'pointer' },
  },
  {
    colId: 'email',
    field: 'email',
    headerName: 'Correo electrónico',
    width: 250,
    type: 'text',
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    cellStyle: { cursor: 'pointer' },
  },
  {
    colId: 'perfil',
    field: 'perfiles',
    headerName: 'Perfiles',
    width: 250,
    type: 'text',
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    cellStyle: { cursor: 'pointer' },
    cellRenderer: (params) => {
      return Array.isArray(params.value)
        ? params.value.map((p) => (p.perfil ? p.perfil : p)).join(', ')
        : params.value
    },
  },
  {
    field: 'selected',
    headerName: 'Usuarios asignados',
    colId: 'users',
    type: 'boolean',
    floatingFilter: false,
    sortable: true,
    cellStyle: { zIndex: -1 },
    headerComponent: 'checkboxSelection',
  },
]

const groupsLineals = [
  {
    colId: 'nombre',
    field: 'nombre',
    headerName: 'Nombre',
    width: 450,
    type: 'text',
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    cellStyle: { cursor: 'pointer' },
  },
  {
    colId: 'featured',
    field: 'destacado',
    headerName: 'Destacado',
    cellRenderer: (params) => {
      return params.value ? 'Si' : ''
    },
    floatingFilter: false,
    width: 80,
    type: 'text',
  },
  {
    colId: 'estado',
    field: 'estado',
    headerName: 'Estado',
    width: 120,
    type: 'text',
    floatingFilter: false,
    filter: 'agTextColumnFilter',
    cellStyle: { cursor: 'pointer' },
    admin: true,
  },
  {
    colId: 'farmacia',
    field: 'organizacion.nombre',
    headerName: 'Farmacia',
    width: 200,
    type: 'text',
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    cellStyle: { cursor: 'pointer' },
  },
  {
    colId: 'publico',
    field: 'publico',
    headerName: 'Publico',
    width: 110,
    type: ['boolean'],
    sortable: true,
    floatingFilter: false,
    cellStyle: { cursor: 'pointer' },
    admin: true,
  },
  {
    field: 'selected',
    headerName: 'Planogramas asignados',
    colId: 'lineals',
    type: 'boolean',
    floatingFilter: false,
    sortable: true,
    cellStyle: { zIndex: -1 },
    headerComponent: 'checkboxSelection',
  },
]

const usersGroup = [
  {
    colId: 'name',
    field: 'name',
    headerName: 'Nombre',
    width: 400,
    type: 'text',
    floatingFilter: true,
    sortable: true,
    filter: 'agTextColumnFilter',
    cellStyle: { cursor: 'pointer' },
  },
  {
    field: 'selected',
    headerName: 'Grupos asignados',
    colId: 'lineals',
    type: 'boolean',
    cellStyle: { zIndex: -1 },
    headerComponent: 'checkboxSelection',
  },
]

export const columns = {
  farmacias,
  planogramas,
  users,
  usersPharmacys,
  usersGroup,
  groupsPharmacys,
  groupsUsers,
  groupsLineals,
  groups,
}
