import React from 'react'
import Button from '../../../Button/Button'
import PropTypes from 'prop-types'

const Footer = ({
  btnDisabled,
  onAccept,
  onCancel,
  btnTextCancel,
  btnTextAccept,
  align,
  type,
  loading,
  idForm,
}) => (
  <div className={`tw-flex tw-items-center tw-justify-${align}`}>
    <div
      className="tw-w-44 tw-cursor-pointer"
      style={!onCancel ? { visibility: 'hidden' } : {}}
    >
      <Button
        label={btnTextCancel}
        uppercase
        mode="custom"
        customParams={{
          bg: 'transparent',
          border: 'primary',
          text: 'primary',
        }}
        onClick={onCancel}
      />
    </div>
    <div className="tw-w-44 tw-cursor-pointer">
      <Button
        label={btnTextAccept}
        uppercase
        type={type}
        mode="custom"
        customParams={{
          bg: 'primary',
        }}
        onClick={onAccept}
        disabled={loading || btnDisabled}
        form={idForm}
      />
    </div>
  </div>
)

Footer.propTypes = {
  btnDisabled: PropTypes.bool,
  btnTextCancel: PropTypes.string,
  btnTextAccept: PropTypes.string,
  type: PropTypes.oneOf(['submit', 'button']),
  align: PropTypes.oneOf(['center', 'between', 'end']),
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  idForm: PropTypes.string,
}

Footer.defaultProps = {
  btnTextCancel: 'Cancelar',
  btnTextAccept: 'Aceptar',
  type: 'button',
  align: 'between',
  onAccept: null,
  onCancel: null,
  loading: false,
}

export default Footer
